import { Collapse, Grid, collapseClasses } from "@mui/material";
import { type ReactElement } from "react";
import { SearchInput, SearchResults, SearchCategories } from "components/Search/";
import TitleImage from "assets/titre-collectivites-minified.svg";
import { useSearch } from "providers";

function MainSearch(): ReactElement {
  const { searchString } = useSearch();

  return (
    <Grid container>
      <Collapse
        in={searchString.length <= 1}
        sx={{ [`&.${collapseClasses.root}`]: { width: "100%" } }}>
        <Grid container item xs={12} justifyContent="center">
          <Grid container item xs={11} sm={8} md={4} sx={{ m: 5 }}>
            <img src={TitleImage} alt="Collectivités" width="100%" height="100%" />
          </Grid>
        </Grid>
      </Collapse>
      <Grid container item xs={12} justifyContent="center">
        <Grid container item xs={12} md={6} justifyContent="center" spacing={2}>
          <Grid
            container
            item
            xs={12}
            sx={{ position: "sticky", zIndex: 2, top: "56px", background: "white", pb: 1 }}>
            <SearchCategories item md={4} sx={{ width: "70px" }} />
            <SearchInput item xs sm md={8} autoFocus />
          </Grid>
          <SearchResults item xs={12} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MainSearch;
