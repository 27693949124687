import type { ReactNode } from "react";
import ordreAssoc from "constants/fonctions_oracle.json";
import type { Mandat, Personne } from "models";
import { arrayUtil } from "@sdeapps/react-core";
import Fonctions, { FonctionNames } from "constants/Fonctions";

function getLibelleFonction(mandat: Mandat): ReactNode {
  const sexe = mandat.sexePersonne ?? "M";
  if (mandat.idFonction === Fonctions.ADJOINT_MAIRE && mandat.libelleFonction != null) {
    const AdjointAuMaireRegex = /(\d+)(.+)(?:\sadjoint)/i;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, num, sup] = AdjointAuMaireRegex.exec(mandat.libelleFonction) ?? [0, 0, 0];
    return (
      <span>
        {num}
        <sup>{sup}</sup> adjoint{sexe !== "M" && "e"} au Maire
      </span>
    );
  }

  const fonctionName = FonctionNames[mandat.idFonction as Fonctions];
  return fonctionName?.[sexe] ?? mandat.libelleFonction ?? "";
}

/**
 * Permet de trier des Personnes par ordre alphabétique de leur nom prénom
 * @param a
 * @param b
 * @returns
 */
function sortByName(a: Personne | undefined, b: Personne | undefined): number {
  return `${a?.nom}${a?.prenom}`.localeCompare(`${b?.nom}${b?.prenom}`);
}

/**
 * Récupère l'ordre d'un mandat
 * @param mandat
 * @returns
 */
function getOrder(mandat: Mandat | undefined): number {
  if (mandat == null) return 99999;
  const ordre = ordreAssoc.find((v) => v.ID === mandat.idFonction)?.ORDRE_AFF ?? "99999";
  return Number.parseInt(ordre, 10);
}

/**
 * Filtre ne retournant que les mandats SDEA
 * @param m
 * @returns
 */
function filterMandatsSdea(m: Mandat): boolean {
  return ["TRR", "SDEA", "CGEO"].includes(m.typeMandat);
}

function filterActiveMandatsSdea(m: Mandat): boolean {
  return filterMandatsSdea(m) && !m.estTermine;
}

function filterHistoricMandatsSdea(m: Mandat): boolean {
  return filterMandatsSdea(m) && m.estTermine;
}

/**
 * Filtre ne retournant que les mandats institutionnels
 * @param m
 * @returns
 */
function filterMandatsInstitutionnels(m: Mandat): boolean {
  return !["TRR", "SDEA", "CGEO"].includes(m.typeMandat);
}

function filterActiveMandatsInstitutionnels(m: Mandat): boolean {
  return !m.estTermine && filterMandatsInstitutionnels(m);
}

function filterHistoricMandatsInstitutionnels(m: Mandat): boolean {
  return m.estTermine && filterMandatsInstitutionnels(m);
}

/**
 * Fonction de sort qui permet de ranger des élus en fonction de l'importance
 * de leur plus haut mandat insitutionnel
 * @param elu1
 * @param elu2
 * @returns
 */
function sortElusByInstitutionnelMandatImportance(elu1: Personne, elu2: Personne): number {
  return (
    getOrder(getHigherInstitutionnelMandat(elu1)) - getOrder(getHigherInstitutionnelMandat(elu2))
  );
}

/**
 * Fonction de sort qui permet de ranger des élus en fonction de l'importance
 * de leur plus haut mandat SDEA
 * @param elu1
 * @param elu2
 * @returns
 */
function sortElusBySdeaMandatImportance(elu1: Personne, elu2: Personne): number {
  return getOrder(getHigherSdeaMandat(elu1)) - getOrder(getHigherSdeaMandat(elu2));
}

/**
 * Fonction de sort qui permet de ranger des élus en fonction de l'importance
 * de leur plus haut mandat
 * @param elu1
 * @param elu2
 * @returns
 */
function sortElusByMandatImportance(elu1: Personne, elu2: Personne): number {
  return getOrder(getHigherMandat(elu1)) - getOrder(getHigherMandat(elu2));
}

/**
 * Fonction de sort qui permet de ranger des mandats en fonction de leur importance
 * @param elu1
 * @param elu2
 * @returns
 */
function sortMandatsByImportance(a: Mandat, b: Mandat): number {
  return getOrder(a) - getOrder(b);
}

/**
 * Renvoie le mandat avec la plus haute importance d'un élu
 * @param elu
 * @returns
 */
function getHigherMandat(elu: Personne): Mandat | undefined {
  if (elu.mandats == null) return undefined;
  const sdeaMandats = [...elu.mandats];
  sdeaMandats?.sort(sortMandatsByImportance);
  return sdeaMandats?.[0];
}

/**
 * Renvoie le mandat SDEA avec la plus haute importance d'un élu
 * @param elu
 * @returns
 */
function getHigherSdeaMandat(elu: Personne): Mandat | undefined {
  if (elu.mandats == null) return undefined;
  const sdeaMandats = [...elu.mandats].filter((m) =>
    ["TRR", "SDEA", "CGEO"].includes(m.typeMandat)
  );
  sdeaMandats?.sort(sortMandatsByImportance);
  return sdeaMandats?.[0];
}

/**
 * Renvoie le mandat insitutionnel avec la plus haute importance d'un élu
 * @param elu
 * @returns
 */
function getHigherInstitutionnelMandat(elu: Personne): Mandat | undefined {
  if (elu.mandats == null) return undefined;
  const institutionnelsMandats = [...elu.mandats].filter(
    (m) => !["TRR", "SDEA", "CGEO"].includes(m.typeMandat)
  );
  institutionnelsMandats?.sort(sortMandatsByImportance);
  return institutionnelsMandats.at(0);
}

/**
 * **Filtre** une liste d'élus et leurs mandats.
 * @param elus une liste de Personnes
 * @param mandatsFilter prédicat de filtre de Mandats
 * @returns Une liste de `Personne` **filtrée**, triée par nom et par
 * importance du plus haut mandat, et dont les mandats sont eux-même **filtrés**
 * et triés par ordre d'importance
 */
function filterAndBoilElusMandatsDown(
  elus: Array<Personne>,
  mandatsFilter: (m: Mandat) => boolean
): Array<Personne> {
  const _elusWithBoiledDownMandats: Array<Personne> = [];
  elus.forEach((elu) => {
    const mandatsFiltres = elu.mandats?.filter(mandatsFilter);
    if (mandatsFiltres != null && mandatsFiltres.length > 0) {
      mandatsFiltres.sort(sortMandatsByImportance);
      _elusWithBoiledDownMandats.push({
        ...elu,
        mandats: mandatsFiltres,
      } satisfies Personne);
    }
  });
  _elusWithBoiledDownMandats.sort(sortByName).sort(sortElusByMandatImportance);

  return _elusWithBoiledDownMandats;
}

function getActiveElusAndMandats(elus: Array<Personne>): Array<Personne> {
  return filterAndBoilElusMandatsDown(elus, (m) => !m.estTermine);
}

function getHistoricElusAndMandats(elus: Array<Personne>): Array<Personne> {
  return filterAndBoilElusMandatsDown(elus, (m) => m.estTermine);
}

/**
 * **Filtre** les mandats d'un élu.
 * Si aucun des mandats de l'élu ne correspond au filtre, renvoie `undefined`.
 * @param elu une Personne
 * @param mandatsFilter prédicat de filtre des Mandats
 * @returns une `Personne` dont les mandats sont **filtrés** et triés par
 * ordre d'importance, ou `undefined`
 */
function boilEluMandatsDown(
  elu: Personne | undefined,
  mandatsFilter: (m: Mandat) => boolean
): Personne | undefined {
  const mandatsFiltres = elu?.mandats?.filter(mandatsFilter);

  if (mandatsFiltres == null || elu == null) return undefined;

  mandatsFiltres?.sort(sortMandatsByImportance);

  const newElu: Personne = {
    ...elu,
    mandats: mandatsFiltres,
  };

  return newElu;
}

/**
 * Prend un tableau contenant des doublons de Personnes avec des Mandats différents et range les mandats
 * dans les Personnes correspondantes
 * @param input le tableau de Personne avec des doublons
 * @returns le tableau de Personne dédupliquées et avec les bons mandats
 */
function fuseMandatsIntoPersonnes(input: Array<Personne>): Array<Personne> {
  const _elus = arrayUtil.groupBy<Personne>(input, (p) => p.id);

  const finalElus: Array<Personne> = [];

  for (const eluId in _elus) {
    const r = _elus[eluId][0];
    const mandats: Array<Mandat> = [];
    _elus[eluId].forEach((e) =>
      e.mandats?.forEach((m) => {
        if (
          !mandats.some(
            // TODO bricolage dégueu à degager dès qu'on a un vrai endpoint
            // pour choper les elus/mandats SDEA
            (_m) => JSON.stringify(_m) === JSON.stringify(m)
          )
        ) {
          mandats.push(m);
        }
      })
    );
    if (mandats.length > 0) {
      r.mandats = mandats;
      finalElus.push(r);
    }
  }

  return finalElus;
}

export const elusUtil = {
  sortByName,
  getOrder,
  getHigherMandat,
  getHigherSdeaMandat,
  getHigherInstitutionnelMandat,
  sortElusByInstitutionnelMandatImportance,
  sortElusBySdeaMandatImportance,
  sortElusByMandatImportance,
  sortMandatsByImportance,
  filterMandatsSdea,
  filterActiveMandatsSdea,
  filterHistoricMandatsSdea,
  filterMandatsInstitutionnels,
  filterActiveMandatsInstitutionnels,
  filterHistoricMandatsInstitutionnels,
  filterAndBoilElusMandatsDown,
  getActiveElusAndMandats,
  getHistoricElusAndMandats,
  boilEluMandatsDown,
  fuseMandatsIntoPersonnes,
  getLibelleFonction,
};
