import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import ElusInformations from "components/Informations/Elus/ElusInformations";
import Fonctions from "constants/Fonctions";
import type { Epci, Mandat, Personne } from "models";
import { elusService } from "services";
import { elusUtil } from "utils";
import { useErrorHandler } from "utils/errorHandling";

interface EpciElusInformationsProps {
  epci: Epci;
}

function filterPresidentAndVicePresidents(elu: Personne): boolean {
  return (
    elu.mandats?.some(
      (m: Mandat) =>
        m.idFonction === Fonctions.PRESIDENT_EPCI || m.idFonction === Fonctions.VICE_PRESIDENT_EPCI
    ) ?? false
  );
}

function EpciElusInformations({ epci }: Readonly<EpciElusInformationsProps>): ReactNode {
  const [elus, setElus] = useState<Array<Personne>>([]);

  const { catchErrors, isLoading, error } = useErrorHandler();

  useEffect(() => {
    async function getElus(): Promise<void> {
      setElus([]);
      const _elus = await elusService.getPersonnesByEpci(epci.id);
      const _conseillersCommunautaires = elusUtil.getActiveElusAndMandats(
        _elus.filter(filterPresidentAndVicePresidents)
      );
      _conseillersCommunautaires
        .sort(elusUtil.sortByName)
        .sort(elusUtil.sortElusByInstitutionnelMandatImportance);
      setElus(_conseillersCommunautaires);
    }

    void catchErrors(getElus);
  }, [catchErrors, epci]);

  return <ElusInformations elus={elus} isLoading={isLoading} error={error != null} />;
}

export default EpciElusInformations;
