import type { ReactElement } from "react";

const nbsp = "\u00A0"; // non-breakable space

interface ContenuProps {
  count: number;
}

function ReaffectationEPCI({ count }: Readonly<ContenuProps>): ReactElement {
  return count === 1 ? (
    <>Voulez-vous réaffecter le transfert sélectionné à l'EPCI sélectionné{nbsp}?</>
  ) : (
    <>
      Voulez-vous réaffecter les <b>{count}</b> transferts sélectionnés à l'EPCI sélectionné
      {nbsp}?
    </>
  );
}

function IndirectDirect({ count }: Readonly<ContenuProps>): ReactElement {
  return count === 1 ? (
    <>Voulez-vous réaffecter le transfert sélectionné en affectation directe{nbsp}?</>
  ) : (
    <>
      Voulez-vous réaffecter les <b>{count}</b> transferts sélectionnés en affectation directe{nbsp}
      ?
    </>
  );
}

function RetraitTransfert({ count }: Readonly<ContenuProps>): ReactElement {
  return count === 1 ? (
    <>Voulez-vous retirer le transfert sélectionné{nbsp}?</>
  ) : (
    <>
      Voulez-vous retirer les <b>{count}</b> transferts sélectionnés{nbsp}?
    </>
  );
}

function SuppressionTransfert({ count }: Readonly<ContenuProps>): ReactElement {
  return count === 1 ? (
    <>
      Êtes-vous sûr de vouloir supprimer le transfert sélectionné{nbsp}?
      <br />
      <br />
      Ce transfert sera supprimé <b>définitivement</b>, aucune trace de son existence ne sera
      sauvegardée.
      <br />
      <br />
      Cette action n'est à utiliser <b>qu'en cas d'erreur de saisie</b> du transfert et n'est{" "}
      <b>PAS</b> une clôture ou un retrait de transferts. Dans ce cas, merci de bien vouloir
      sélectionner à la place "Retrait de transfert".
    </>
  ) : (
    <>
      Êtes-vous sûr de vouloir supprimer les transferts sélectionnés{nbsp}?
      <br />
      <br />
      Ces transferts seront supprimés <b>définitivement</b>, aucune trace de leur existence ne sera
      sauvegardée.
      <br />
      <br />
      Cette action n'est à utiliser <b>qu'en cas d'erreur de saisie</b> du transfert et n'est{" "}
      <b>PAS</b> une clôture ou un retrait de transferts. Dans ce cas, merci de bien vouloir
      sélectionner à la place "Retrait de transfert".
    </>
  );
}

const TransfertEditionDialogMessages = {
  ReaffectationEPCI,
  DirectIndirect: ReaffectationEPCI,
  IndirectDirect,
  RetraitTransfert,
  SuppressionTransfert,
};

export default TransfertEditionDialogMessages;
