import type { ReactNode } from "react";
import { Grid } from "@mui/material";
import type { Personne } from "models";
import ElusInformationsSkeleton from "./ElusInformationsSkeleton";
import InformationsError from "components/Errors/InformationsError";
import EluLink from "components/Links/EluLink";
import EluInformationsFonction from "./EluInformationsFonction";

interface ElusInformationsProps {
  elus: Array<Personne>;
  isLoading?: boolean;
  error?: boolean;
}

function ElusInformations({
  elus,
  isLoading = false,
  error = false,
}: Readonly<ElusInformationsProps>): ReactNode {
  if (isLoading) {
    return <ElusInformationsSkeleton />;
  }

  if (error) {
    return <InformationsError />;
  }

  return (
    <Grid item container xs={12} alignContent="flex-start" spacing={2}>
      {elus.map((p) => {
        return (
          <Grid item container xs={12} key={p.id}>
            <Grid item xs={12}>
              <EluLink elu={p} fontWeight="600" />
            </Grid>

            {p.mandats?.map((mandat) => {
              if (mandat?.libelleFonction == null) {
                return null;
              }
              return (
                <EluInformationsFonction
                  key={`${mandat?.libelleFonction}${mandat?.idPersonne}`}
                  mandat={mandat}
                />
              );
            })}
          </Grid>
        );
      })}
    </Grid>
  );
}

export default ElusInformations;
