import { Tab, Typography } from "@mui/material";
import type { TabProps } from "@mui/material";
import type { CompetenceAssociation } from "constants/CompetenceMap";
import type { ReactNode } from "react";

interface CompetenceTabProps extends TabProps {
  competenceAssociation: CompetenceAssociation;
}

function CompetenceTab({
  competenceAssociation,
  ...props
}: Readonly<CompetenceTabProps>): ReactNode {
  return (
    <Tab
      {...props}
      iconPosition="start"
      label={
        <Typography color="inherit">
          <competenceAssociation.icon fontSize="small" sx={{ mr: 1, verticalAlign: "middle" }} />
          <Typography
            component="span"
            color="inherit"
            sx={{ display: { xs: "none", md: "inline" } }}
            fontWeight="500">
            {competenceAssociation.label}
          </Typography>
          <Typography
            component="span"
            color="inherit"
            sx={{ display: { xs: "inline", md: "none" } }}
            fontWeight="500">
            {competenceAssociation.smallLabel}
          </Typography>
        </Typography>
      }
    />
  );
}

export default CompetenceTab;
