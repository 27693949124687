import { type ReactNode } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

interface HasCompetenceIconProps {
  checked: boolean;
}

function HasCompetenceIcon({ checked }: Readonly<HasCompetenceIconProps>): ReactNode {
  if (checked) {
    return <CheckIcon color="success" />;
  } else {
    return <CloseIcon color="error" />;
  }
}

export default HasCompetenceIcon;
