import { useEffect, useState, useCallback } from "react";
import type { ReactNode } from "react";
import { Link, Typography } from "@mui/material";
import type { LinkProps } from "@mui/material";
import { routesConfig } from "config/app-config";
import type { Perimetre } from "models";
import { perimetresService } from "services";
import TypographySkeleton from "./TypographySkeleton";
import { withComponentErrorBoundary, useErrorHandler } from "utils/errorHandling";
import InlineErrorChip from "components/Errors/InlineErrorChip";

type PerimetreLinkData1 = {
  perimetre: Perimetre;
  id?: never;
  libelle?: never;
  noLink?: boolean;
};

type PerimetreLinkData2 = {
  perimetre?: never;
  id: string;
  libelle?: string;
  noLink?: boolean;
};

type PerimetreLinkProps = LinkProps & (PerimetreLinkData1 | PerimetreLinkData2);

function PerimetreLink({
  perimetre,
  id,
  libelle,
  noLink = false,
  ...linkProps
}: Readonly<PerimetreLinkProps>): ReactNode {
  const [libellePerimetre, setLibellePerimetre] = useState("");
  const [link, setLink] = useState("");

  const { catchErrors, isLoading, setIsLoading } = useErrorHandler({
    default: () => {
      setLibelleAndLink(libelle ?? "Périmètre inconnu", id ?? "");
    },
  });

  const setLibelleAndLink = useCallback(
    (_libelle: string, _id: string): void => {
      setLibellePerimetre(_libelle);
      setLink(routesConfig.perimetre.getParameterPath(_id));
      setIsLoading(false);
    },
    [setIsLoading]
  );

  useEffect(() => {
    async function getPerimetre(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const p = await perimetresService.getById(id!);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setLibelleAndLink(p.libelle, id!);
    }

    if (perimetre != null) {
      setLibelleAndLink(perimetre.libelle, perimetre.id);
    } else if (id != null && libelle != null) {
      setLibelleAndLink(libelle, id);
    } else {
      void catchErrors(getPerimetre);
    }
  }, [perimetre, libelle, id, catchErrors, setLibelleAndLink]);

  if (isLoading) {
    return <TypographySkeleton sx={{ maxWidth: 300 }} />;
  }

  if (noLink) {
    return (
      <Typography component="span" color="primary" fontWeight={500}>
        {libellePerimetre}
      </Typography>
    );
  } else {
    return (
      <Link href={link} {...linkProps}>
        {libellePerimetre}
      </Link>
    );
  }
}

const PerimetreLinkWithErrorBoundary = withComponentErrorBoundary(PerimetreLink, {
  override: {
    fallback: (_) => <InlineErrorChip text="Perimètre inconnu" />,
  },
});

export default PerimetreLinkWithErrorBoundary;
