import { Endpoints } from "constants/endpoints";
import type { Populations } from "models";
import { collectivitesApiClient } from "./apiClients";

/**
 * Récupère les populations d'un EPCI
 * @param siren le siren de l'EPCI
 * @returns les populations de l'EPCI
 */
async function getByEpci(siren: string): Promise<Populations> {
  const url = `/${Endpoints.EPCIS}/${siren}/${Endpoints.POPULATION}`;
  return collectivitesApiClient.get(url);
}

/**
 * Récupère les populations d'un Perimetre
 * @param id l'id du Perimetre
 * @returns les populations du périmètre
 */
async function getByPerimetre(id: string): Promise<Populations> {
  const url = `/${Endpoints.PERIMETRES}/${id}/${Endpoints.POPULATION}`;
  return collectivitesApiClient.get(url);
}

export const populationsService = {
  getByEpci,
  getByPerimetre,
};
