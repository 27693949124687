import DisplayDatesContext, { type DisplayDatesContextData } from "contexts/DisplayDatesContext";
import { type PropsWithChildren, type ReactElement, useMemo, useState } from "react";

export function DisplayDatesProvider({ children }: Readonly<PropsWithChildren>): ReactElement {
  const [isDisplayingDates, setIsDisplayingDates] = useState(false);

  const data: DisplayDatesContextData = useMemo(() => {
    return {
      isDisplayingDates,
      setIsDisplayingDates,
    };
  }, [isDisplayingDates, setIsDisplayingDates]);

  return <DisplayDatesContext.Provider value={data}>{children}</DisplayDatesContext.Provider>;
}
