import { useState, useEffect, Fragment } from "react";
import type { ReactNode } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  tableBodyClasses,
  tableCellClasses,
  tableContainerClasses,
  tableRowClasses,
} from "@mui/material";
import type { Transfert } from "models";
import { grey } from "@mui/material/colors";
import { porteesUtil } from "utils";
import EauAssGepuPorteesAccordion from "./EauAssGepuPorteesAccordion";
import { EauContrats, GepuContrats, AssContrats } from "constants/EauAssContrats";
import type { EauAssGepuContrat } from "constants/EauAssContrats";
import Competence from "constants/Competence";
import HasCompetenceIcon from "./HasCompetenceIcon";

function getCompetenceContrats(competence: Competence): Array<EauAssGepuContrat> {
  switch (competence) {
    case Competence.EAU:
      return EauContrats;
    case Competence.ASS:
      return AssContrats;
    default:
      return GepuContrats;
  }
}

interface EauAssGepuSousCompetencesAccordionDetailsProps {
  transferts: Array<Transfert>;
}

function EauAssGepuSousCompetencesAccordionDetails({
  transferts,
}: Readonly<EauAssGepuSousCompetencesAccordionDetailsProps>): ReactNode {
  const [competence, setCompetence] = useState<Competence>(
    porteesUtil.getCompetence(transferts[0])
  );
  const [contrats, setContrats] = useState<Array<EauAssGepuContrat>>([]);

  useEffect(() => {
    const _competence = porteesUtil.getCompetence(transferts[0]);
    setCompetence(_competence);
    setContrats(getCompetenceContrats(_competence));
  }, [transferts]);

  return (
    <TableContainer
      sx={{
        [`&.${tableContainerClasses.root} .${tableBodyClasses.root} .${tableRowClasses.root}:last-child .${tableCellClasses.root}`]:
          { border: "none" },
      }}>
      <Table size="small">
        <TableBody>
          {contrats.map((sousCompetence) => {
            const hasSousCompetence = transferts.some(
              (transfert) => porteesUtil.getSousCompetenceId(transfert) === sousCompetence.code
            );
            return (
              <Fragment key={sousCompetence.code}>
                <TableRow>
                  <TableCell width="40px" sx={{ verticalAlign: "baseline", paddingRight: 0 }}>
                    <HasCompetenceIcon checked={hasSousCompetence} />
                  </TableCell>

                  <TableCell>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography color={hasSousCompetence ? undefined : grey[400]}>
                          {porteesUtil.getSousCompetenceLabel(
                            `${competence}-${sousCompetence.code}-0`
                          )}
                        </Typography>
                      </Grid>
                      {sousCompetence.portees != null && (
                        <Grid item xs={12}>
                          <EauAssGepuPorteesAccordion
                            transferts={transferts}
                            porteeIds={sousCompetence.portees}
                            porteeAdditionnellesIds={sousCompetence.porteesAdditionnelles}
                            sousCompetenceId={sousCompetence.code}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </TableCell>
                </TableRow>
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default EauAssGepuSousCompetencesAccordionDetails;
