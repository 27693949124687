import { Fragment } from "react";
import type { ReactNode } from "react";
import { Typography } from "@mui/material";
import EpciLink from "components/Links/EpciLink";
import CommuneLink from "components/Links/CommuneLink";
import type { Commune } from "models";

interface NewPerimetrePorteesAccordionSummaryProps {
  communes: Array<Commune>;
  idEpci?: string;
}

function PerimetrePorteesAccordionSummary({
  communes,
  idEpci,
}: Readonly<NewPerimetrePorteesAccordionSummaryProps>): ReactNode {
  return (
    <Typography sx={{ ml: 2 }}>
      {communes.map((commune, i, a) => (
        <Fragment key={commune.id}>
          <CommuneLink commune={commune} fontWeight={600} />
          {i !== a.length - 1 && ", "}
        </Fragment>
      ))}
      <br />
      Adhésion{" "}
      {idEpci != null ? (
        <>
          via <EpciLink id={idEpci} />
        </>
      ) : (
        " directe"
      )}
    </Typography>
  );
}

export default PerimetrePorteesAccordionSummary;
