import DisplayDatesContext, { type DisplayDatesContextData } from "contexts/DisplayDatesContext";
import { useContext } from "react";

export function useDisplayDates(): DisplayDatesContextData {
  const context = useContext(DisplayDatesContext);

  if (context == null) {
    throw new Error("useDisplayDates must be used within a DisplayDatesProvider");
  }

  return context;
}
