import type { ReactNode } from "react";
import { Typography } from "@mui/material";
import ListSkeleton from "components/ListSkeleton";
import type { Adresse } from "models";

interface AdresseSpanProps {
  adresse: Adresse | null | undefined;
  nom?: string;
  isLoading?: boolean;
  error?: boolean;
}

function AdresseSpan({
  adresse,
  nom,
  isLoading = false,
  error = false,
}: Readonly<AdresseSpanProps>): ReactNode {
  if (error) {
    return <Typography>Impossible de charger l'adresse</Typography>;
  }

  if (isLoading) {
    return <ListSkeleton lines={3} />;
  }

  if (adresse?.numeroVoie == null || adresse.numeroVoie === "") {
    return <Typography component="span">Adresse non renseignée</Typography>;
  }

  return (
    <Typography component="span">
      {nom}
      {nom != null && <br />}
      {adresse.numeroVoie}{" "}
      {adresse.serviceDistribution != null &&
        adresse.serviceDistribution !== "" &&
        `- ${adresse.serviceDistribution}`}
      <br />
      <span style={{ textTransform: "uppercase" }}>
        {adresse.codePostal} {adresse.commune}
      </span>
    </Typography>
  );
}

export default AdresseSpan;
