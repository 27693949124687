import { useMemo } from "react";
import type { ReactNode } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { dateUtil } from "@sdeapps/react-core";
import LoadingButton from "components/LoadingButton";
import ToastMessages from "constants/ToastMessages";
import type { Mandat } from "models";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { mandatsService } from "services";
import { timeUtil } from "utils";
import { useErrorHandler } from "utils/errorHandling";

interface DeleteMandatDialogProps {
  open: boolean;
  handleClose: () => void;
  mandatSdea: Mandat;
}

function getFormattedString(s: string | undefined): ReactNode {
  return s != null ? (
    <>
      {s}
      <br />
    </>
  ) : null;
}

function DeleteMandatDialog({
  open,
  handleClose,
  mandatSdea,
}: Readonly<DeleteMandatDialogProps>): ReactNode {
  const navigate = useNavigate();
  const mandatDescription: ReactNode = useMemo(
    () => (
      <>
        <strong>{mandatSdea.libelleFonction}</strong>
        <br />
        {getFormattedString(mandatSdea.libelleTerritoire)}
        {getFormattedString(mandatSdea.libellePerimetre)}
        {getFormattedString(mandatSdea.libelleCollectivite)}
        {mandatSdea.dateDebutMandat != null
          ? ` à partir du ${dateUtil.format(mandatSdea.dateDebutMandat, "dd/MM/yyyy")}`
          : ""}
        {mandatSdea.dateFinMandat != null
          ? ` jusqu'au ${dateUtil.format(mandatSdea.dateFinMandat, "dd/MM/yyyy")}`
          : ""}
      </>
    ),
    [mandatSdea]
  );

  const { catchErrors, isLoading: isSending } = useErrorHandler({
    dontThrow: true,
    defaultIsLoading: false,
    default: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR_RETRY,
      });
    },
  });

  function clotureMandat(): void {
    async function sendClotureMandat(): Promise<void> {
      await mandatsService.deleteMandat(mandatSdea.id);

      enqueueSnackbar({
        variant: "success",
        message: ToastMessages.DELETED_MANDAT,
      });

      await timeUtil.sleep(1000);
      navigate(0);
    }

    void catchErrors(sendClotureMandat);
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Confirmation requise</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Êtes-vous sûr de vouloir supprimer le mandat suivant ?
          <br />
          <br />
          {mandatDescription}
          <br />
          <br />
          Ce mandat sera supprimé <strong>définitivement</strong>, aucune trace de son existence ne
          sera sauvegardée.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error">
          Annuler
        </Button>
        <LoadingButton onClick={clotureMandat} variant="contained" loading={isSending}>
          Confirmer
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteMandatDialog;
