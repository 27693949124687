import type { ReactNode } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import type { GridProps } from "@mui/material";
import type { SvgIconComponent } from "@mui/icons-material";
import { Authorization } from "@sdeapps/react-core";
import type { ApplicationRole } from "@sdeapps/react-core";
import ContextualMenu from "./Template/ContextualMenu";
import { useContextualMenu } from "hooks";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface InformationBoxContextualMenuProps {
  actions: ReactNode;
  handleClose: VoidFunction;
  handleOpen: (event: React.MouseEvent<HTMLElement>) => void;
  isOpen: boolean;
  menuAnchor: HTMLElement | null;
  closeOnClick: boolean;
}

function InformationBoxContextualMenu({
  actions,
  handleClose,
  handleOpen,
  isOpen,
  menuAnchor,
  closeOnClick,
}: Readonly<InformationBoxContextualMenuProps>): ReactNode {
  return (
    <Grid item container xs="auto" sx={{ mr: -2 }}>
      <IconButton color="inherit" onClick={handleOpen} sx={{ my: -1 }}>
        <MoreVertIcon />
      </IconButton>
      <ContextualMenu
        open={isOpen}
        anchorEl={menuAnchor}
        onClose={handleClose}
        closeOnClick={closeOnClick}>
        {actions}
      </ContextualMenu>
    </Grid>
  );
}

interface InformationBoxProps extends GridProps {
  hasBackground?: boolean;
  icon?: SvgIconComponent;
  articleTitle?: ReactNode;
  subTitle?: ReactNode;
  actions?: ReactNode;
  actionsRoles?: ApplicationRole | Array<ApplicationRole>;
  closeOnClick?: boolean;
}

function InformationBox({
  hasBackground = false,
  icon: SvgIcon,
  articleTitle: title,
  subTitle,
  actions,
  actionsRoles,
  children,
  closeOnClick = true,
}: Readonly<InformationBoxProps>): ReactNode {
  const { isOpen, handleOpen, handleClose, menuAnchor } = useContextualMenu();

  return (
    // MAGIE PAS TOUCHER
    <Grid item container xs={12} spacing={2} direction="column">
      <Grid item container alignItems="center">
        <Grid
          item
          container
          alignItems="center"
          sx={{
            borderRadius: 1,
            backgroundColor: "primary.main",
            color: "white",
            px: 2,
            py: 1,
          }}>
          <Grid item container xs alignItems="center" spacing={1}>
            {SvgIcon != null && (
              <Grid item container xs="auto">
                <SvgIcon fontSize="small" />
              </Grid>
            )}
            <Typography component={Grid} item container xs="auto" color="inherit" variant="h5">
              {title}
            </Typography>
          </Grid>
          <Typography
            component={Grid}
            item
            container
            xs="auto"
            alignItems="center"
            color="inherit"
            fontWeight="600">
            {subTitle}
          </Typography>
          {actions != null &&
            (actionsRoles != null ? (
              <Authorization roles={actionsRoles}>
                <InformationBoxContextualMenu
                  actions={actions}
                  handleClose={handleClose}
                  handleOpen={handleOpen}
                  isOpen={isOpen}
                  menuAnchor={menuAnchor}
                  closeOnClick={closeOnClick}
                />
              </Authorization>
            ) : (
              <InformationBoxContextualMenu
                actions={actions}
                handleClose={handleClose}
                handleOpen={handleOpen}
                isOpen={isOpen}
                menuAnchor={menuAnchor}
                closeOnClick={closeOnClick}
              />
            ))}
        </Grid>
      </Grid>
      <Grid item container flexGrow={1}>
        <Grid
          item
          container
          sx={{
            backgroundColor: hasBackground ? "#f6fafc" : "initial",
            borderRadius: 1,
          }}>
          <Grid item container sx={{ p: 2 }} spacing={2}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InformationBox;
