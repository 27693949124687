import { useState, useEffect } from "react";
import type { ReactNode } from "react";
import { TableCell, TableRow, Typography } from "@mui/material";
import type { Transfert } from "models";
import { grey } from "@mui/material/colors";
import type Competence from "constants/Competence";
import type { EauAssGepuContrat } from "constants/EauAssContrats";
import DatesRows from "../DatesRows";
import { porteesUtil } from "utils";
import HasCompetenceIcon from "./HasCompetenceIcon";

interface EauAssGepuSousCompetencesAccordionDetailsWithDatesProps {
  transferts: Array<Transfert>;
  sousCompetence: EauAssGepuContrat;
  competence: Competence;
}

function EauAssGepuSousCompetencesAccordionDetailsRowWithDates({
  transferts: originalTransferts,
  sousCompetence,
  competence,
}: Readonly<EauAssGepuSousCompetencesAccordionDetailsWithDatesProps>): ReactNode {
  const [hasSousCompetence, setHasSousCompetence] = useState(false);
  const [sousCompetenceTransferts, setSousCompetenceTransferts] = useState<Array<Transfert>>([]);

  useEffect(() => {
    const _transferts = [...originalTransferts].filter(
      (transfert) => transfert.code === `${competence}-${sousCompetence.code}-0`
    );
    setSousCompetenceTransferts(_transferts);
    setHasSousCompetence(originalTransferts.length > 0);
  }, [competence, originalTransferts, sousCompetence]);

  if (sousCompetenceTransferts.length > 0)
    return (
      <>
        {sousCompetenceTransferts.map((transfert) => (
          <TableRow hover key={transfert.id}>
            <TableCell sx={{ paddingRight: 0 }}>
              <HasCompetenceIcon checked={hasSousCompetence} />
            </TableCell>
            <TableCell>
              <Typography color={hasSousCompetence ? undefined : grey[400]}>
                {porteesUtil.getSousCompetenceLabel(`${competence}-${sousCompetence.code}-0`)}
              </Typography>
            </TableCell>
            <DatesRows dates={[transfert?.dateDeliberation, transfert?.datePremierTransfert]} />
          </TableRow>
        ))}
      </>
    );

  return (
    <TableRow hover>
      <TableCell sx={{ paddingRight: 0 }}>
        <HasCompetenceIcon checked={hasSousCompetence} />
      </TableCell>
      <TableCell colSpan={3}>
        <Typography color={hasSousCompetence ? undefined : grey[400]}>
          {porteesUtil.getSousCompetenceLabel(`${competence}-${sousCompetence.code}-0`)}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

export default EauAssGepuSousCompetencesAccordionDetailsRowWithDates;
