import { Endpoints } from "constants/endpoints";
import type {
  CommuneWithTransferts,
  TransfertCreateData,
  Transfert,
  TransfertChangeEpciData,
  RetraitTransfertData,
  SuppressionTransfertsData,
} from "models";
import { collectivitesApiClient } from "./apiClients";

/**
 * Récupère les transferts d'une commune en fonction de son code INSEE
 * @param idCommune code INSEE de la commune
 * @returns Array<Transfert>
 */
async function getByCommune(idCommune: string): Promise<Array<Transfert>> {
  const url = `/${Endpoints.COMMUNES}/${idCommune}/${Endpoints.TRANSFERTS}`;
  return collectivitesApiClient.get(url);
}

/**
 * Récupère les transferts d'un périmètre en fonction de son id
 * @param idPerimetre l'id du périmètre
 * @returns Array<Transfert>
 */
async function getByPerimetre(idPerimetre: string): Promise<Array<Transfert>> {
  const url = `/${Endpoints.PERIMETRES}/${idPerimetre}/${Endpoints.TRANSFERTS}`;
  return collectivitesApiClient.get(url);
}

/**
 * Récupère les transferts d'un EPCI en fonction de son id
 * @param idPerimetre l'id de l'EPCI
 * @returns Array<Transfert>
 */
async function getByEpci(idPerimetre: string): Promise<Array<Transfert>> {
  const url = `/${Endpoints.EPCIS}/${idPerimetre}/${Endpoints.TRANSFERTS}`;
  return collectivitesApiClient.get(url);
}

/**
 * Créée des nouveaux transferts pour une commune
 * @param idCommune le code Insee de la commune
 * @param payload les données des transferts à créer
 * @returns la commune avec ses transferts
 */
async function createCommuneTransferts(
  idCommune: string,
  payload: Array<TransfertCreateData>
): Promise<CommuneWithTransferts> {
  const url = `/${Endpoints.COMMUNES}/${idCommune}/${Endpoints.TRANSFERTS}`;

  return collectivitesApiClient.post(url, payload);
}

/**
 * Change l'EPCI d'une liste de transferts
 * @param payload les données définissant les changements à opérer
 * @returns void
 */
async function changeEpci(payload: TransfertChangeEpciData): Promise<void> {
  const url = `/${Endpoints.TRANSFERTS}/${Endpoints.TRANSFERTS_AFFECTATION_EPCI}`;
  return collectivitesApiClient.post(url, payload);
}

/**
 * Retrait d'une liste de transferts des affectations
 * @param payload les données définissant les changements à opérer
 * @returns void
 */
async function retraitTransferts(payload: RetraitTransfertData): Promise<void> {
  const url = `/${Endpoints.TRANSFERTS}/${Endpoints.TRANSFERTS_RETRAIT}`;
  return collectivitesApiClient.post(url, payload);
}

/**
 * Supprime une liste de transferts
 * @param payload les données définissant les changements à opérer
 * @returns void
 */
async function suppressionTransferts(payload: SuppressionTransfertsData): Promise<void> {
  const url = `/${Endpoints.TRANSFERTS}/${Endpoints.TRANSFERTS_SUPPRESSION}`;
  return collectivitesApiClient.post(url, payload);
}

export const transfertsService = {
  getByCommune,
  getByPerimetre,
  getByEpci,
  createCommuneTransferts,
  changeEpci,
  retraitTransferts,
  suppressionTransferts,
};
