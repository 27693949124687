import type { ReactNode } from "react";
import { Grid } from "@mui/material";
import type { Personne } from "models";
import EluLink from "components/Links/EluLink";

interface ElusListProps {
  elus: Array<Personne>;
}

function ElusList({ elus }: Readonly<ElusListProps>): ReactNode {
  return (
    <Grid item container xs={12} spacing={1} alignContent="flex-start">
      {elus.map((elu) => {
        return (
          <Grid item xs={12} key={elu.id}>
            <EluLink elu={elu} />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default ElusList;
