import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { useErrorHandler, withPageErrorBoundary, SdeappsError } from "utils/errorHandling";
import { Checkbox, Container, FormControlLabel, Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import InformationBox from "components/InformationBox";
import PageTitle from "components/PageTitle";
import { PerimetreIcon } from "icons";
import LoadingScreen from "components/Template/LoadingScreen";
import LoadingButton from "components/LoadingButton";
import { useChefLieuxEtCommunesAssocieesDeleguees } from "./hooks/useChefLieuxEtCommunesAssocieesDeleguees";
import { useForm } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import type {
  ChefLieuWithCADsPerimetreAffectation,
  AssociationPerimetreCommunesAssocieesDelegueesDto,
  CommuneAssocieeDelegueeWithPerimetreAffectation,
  Affectation,
  Perimetre,
} from "models";
import { enqueueSnackbar } from "notistack";
import ToastMessages from "constants/ToastMessages";
import { routesConfig } from "config/app-config";
import { networkService, transfertsService, perimetresService } from "services";

const PAGE_SUBTITLE = "Affecter les communes fusionnées";

function areSetEquivalent(setA: Set<string>, setB: Set<string>): boolean {
  if (setA.size !== setB.size) {
    return false;
  } else {
    const keyJoinCharacter = "##";
    const setAKeysAsString = Array.from(setA.keys())
      .sort((a, b) => a.localeCompare(b))
      .join(keyJoinCharacter);
    const setBKeysAsString = Array.from(setB.keys())
      .sort((a, b) => a.localeCompare(b))
      .join(keyJoinCharacter);
    return setAKeysAsString === setBKeysAsString;
  }
}

async function waitForTransfertViewReplication(
  idPerimetre: string,
  expectedAffectationIds: Set<string>
): Promise<void> {
  const transferts = await transfertsService.getByPerimetre(idPerimetre);
  const actualAffectationIds = transferts
    .filter(
      (transfert) =>
        transfert.affectationsCommunesAssocieesDeleguees != null &&
        transfert.affectationsCommunesAssocieesDeleguees.length > 0
    )
    .reduce((acc, transfert) => {
      if (transfert.affectationsCommunesAssocieesDeleguees != null) {
        for (const affectation of transfert.affectationsCommunesAssocieesDeleguees) {
          acc.add(affectation);
        }
      }
      return acc;
    }, new Set<string>());

  if (!areSetEquivalent(actualAffectationIds, expectedAffectationIds)) {
    throw new SdeappsError(
      `Les affectations au périmètre ${idPerimetre} des communes associées déléguées n'a pas encore été répliquée`
    );
  }
}

function mapCadsToAffectations(
  cads: Array<CommuneAssocieeDelegueeWithPerimetreAffectation>
): Array<Affectation> {
  return cads.map(({ id: idCommune, isAffectee }) => ({
    idCommune,
    isAffectee,
  }));
}

function createPayloadsFromCommuneAffectables(
  idPerimetre: string,
  communesAffectables: Array<ChefLieuWithCADsPerimetreAffectation>
): Array<AssociationPerimetreCommunesAssocieesDelegueesDto> {
  return communesAffectables.map<AssociationPerimetreCommunesAssocieesDelegueesDto>(
    ({ id: idChefLieu, cads }) => ({
      idPerimetre,
      idChefLieu,
      affectationsCommunesAssocieesDeleguees: mapCadsToAffectations(cads),
    })
  );
}

function extractIdsFromPayloads(
  payloads: Array<AssociationPerimetreCommunesAssocieesDelegueesDto>
): Set<string> {
  const expectedAffectationIds = payloads
    .flatMap((payload) =>
      payload.affectationsCommunesAssocieesDeleguees
        .filter((acad) => acad.isAffectee)
        .map((acad) => acad.idCommune)
    )
    .reduce((acc, id) => {
      acc.add(id);
      return acc;
    }, new Set<string>());
  return expectedAffectationIds;
}

type AffectationPerimetreCommuneAssocieeDelegueeForm = {
  communesAffectables: Array<ChefLieuWithCADsPerimetreAffectation>;
};

function PerimetreCommunesAssocieesDelegues(): ReactNode {
  const navigate = useNavigate();
  const { id: idPerimetre } = useParams();
  const [perimetre, setPerimetre] = useState<Perimetre>();
  const { chefLieuxEtCADs, isLoadingCADs } = useChefLieuxEtCommunesAssocieesDeleguees(idPerimetre);
  const { catchErrors: catchErrorsPerimetre, isLoading: isLoadingPerimetre } = useErrorHandler();
  const { catchErrors: catchAffectationsErrors, isLoading: isAffectationsLoading } =
    useErrorHandler({
      dontThrow: true,
      defaultIsLoading: false,
      default: () => {
        enqueueSnackbar({
          variant: "error",
          message: ToastMessages.ERROR_RETRY,
        });
      },
    });

  const { handleSubmit, setValue, register } =
    useForm<AffectationPerimetreCommuneAssocieeDelegueeForm>({
      shouldFocusError: false,
    });

  useEffect(() => {
    async function getPerimetre(): Promise<void> {
      if (idPerimetre != null) {
        const _perimetre = await perimetresService.getById(idPerimetre);
        document.title = `${PAGE_SUBTITLE} de ${_perimetre.libelle}`;
        setPerimetre(_perimetre);
      }
    }
    void catchErrorsPerimetre(getPerimetre);
  }, [catchErrorsPerimetre, idPerimetre]);

  useEffect(() => {
    setValue("communesAffectables", chefLieuxEtCADs ?? []);
  }, [chefLieuxEtCADs, setValue]);

  const sendData: SubmitHandler<AffectationPerimetreCommuneAssocieeDelegueeForm> = async function (
    data: AffectationPerimetreCommuneAssocieeDelegueeForm
  ): Promise<void> {
    async function postAffectation(): Promise<void> {
      if (idPerimetre != null) {
        const payloads = createPayloadsFromCommuneAffectables(
          idPerimetre,
          data.communesAffectables
        );

        await Promise.all(
          payloads.map(perimetresService.associerPerimetreCommunesAssocieesDeleguees)
        );

        await networkService.waitForReplication(
          async () => {
            const expectedAffectationIds = extractIdsFromPayloads(payloads);
            await waitForTransfertViewReplication(idPerimetre, expectedAffectationIds);
          },
          10,
          () => {
            navigate(routesConfig.perimetre.getParameterPath(idPerimetre));
          },
          () => {
            enqueueSnackbar({
              variant: "warning",
              message: ToastMessages.LONG_REPLICATION,
            });
          }
        );
      }
    }

    void catchAffectationsErrors(postAffectation);
  };

  if (isLoadingPerimetre || perimetre == null || isLoadingCADs) {
    return <LoadingScreen />;
  }

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        <PageTitle
          title={perimetre.libelle}
          icon={<PerimetreIcon />}
          subtitle={`Périmètre : ${PAGE_SUBTITLE}`}
          competences={perimetre.competence}
        />
        <InformationBox articleTitle={PAGE_SUBTITLE}>
          {chefLieuxEtCADs.length === 0 ? (
            <Grid item xs={12}>
              <Typography>
                Ce périmètre n'a aucune commune fusionnée (déléguée ou associée) à affecter
              </Typography>
            </Grid>
          ) : (
            <Grid
              item
              container
              xs={12}
              spacing={2}
              component="form"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onSubmit={handleSubmit(sendData)}>
              {chefLieuxEtCADs.map((c, idxChefLieux) => (
                <Grid key={c.id} item xs={12} sm={6} md={4}>
                  <Typography variant="h6">{c.libelle}</Typography>
                  <Grid container item xs={12}>
                    {c.cads.map((cad, idxCad) => (
                      <Grid key={cad.id} item xs={12}>
                        <FormControlLabel
                          control={<Checkbox defaultChecked={cad.isAffectee} />}
                          {...register(
                            `communesAffectables.${idxChefLieux}.cads.${idxCad}.isAffectee`
                          )}
                          label={cad.libelle}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
              <Grid item xs={12} justifyContent="flex-end" container>
                <LoadingButton loading={isAffectationsLoading}>Enregistrer</LoadingButton>
              </Grid>
            </Grid>
          )}
        </InformationBox>
      </Grid>
    </Container>
  );
}

const PerimetreCommunesAssocieesDeleguesWithErrorBoundary = withPageErrorBoundary(
  PerimetreCommunesAssocieesDelegues
);

export default PerimetreCommunesAssocieesDeleguesWithErrorBoundary;
