import type { ReactNode } from "react";
import { Typography } from "@mui/material";
import type { TypographyProps } from "@mui/material";
import type Competence from "constants/Competence";
import competenceMap from "constants/CompetenceMap";

interface CompetenceChipProps extends TypographyProps {
  competence: Competence;
}

function CompetenceChip({
  competence,
  variant = "body2",
  sx,
  ...typographyProps
}: Readonly<CompetenceChipProps>): ReactNode {
  const competenceAssociation = competenceMap.get(competence);

  if (competenceAssociation == null) {
    return null;
  }

  return (
    <Typography
      variant={variant}
      {...typographyProps}
      color={`${competenceAssociation.color}.dark`}
      sx={{
        backgroundColor: `${competenceAssociation.color}.light`,
        paddingX: 1,
        paddingY: 0.5,
        fontWeight: 600,
        borderRadius: 1,
        display: "inline-block",
        ...sx,
      }}>
      {competenceAssociation.smallLabel}
    </Typography>
  );
}

export default CompetenceChip;
