import { useEffect, useMemo, useState } from "react";
import type { Transfert } from "models";
import { perimetresUtil } from "utils";

/**
 * TODO updater cette doc
 * Groupe les Communes pour l'affichage des sousCompétencs et Portées en fonction
 * du choix de considérer ou non les dates.
 *
 * utilise **`perimetresUtil.getGroupedPerimetreCommunes`** et **`perimetresUtil.getGroupedPerimetreCommunesWithDistinctSousCompetences`**
 * @param perimetre le périmètre dont il faut grouper les communes
 * @param displayDates un boolean indiquant si il faut considérer ou non les dates dans le regroupement
 * @returns un tableau de tableau de Communes
 *
 *  Sous-Compétences rangées par :
 *  [Groupe de Communes ayant les mêmes type de transfert, bassins versants, sousCompétences, portées,
 *    (si displayDates : date de délibération et date de début)
 *    [Commune
 *       [Sous-Compétences]]]
 */
export function usePerimetreTransfertsGrouping(
  transferts: Array<Transfert>,
  displayDates?: boolean
): Array<Array<Array<Transfert>>> {
  const [groupedCommunes, setGroupedCommunes] = useState<Array<Array<Array<Transfert>>>>([]);
  const [groupedCommunesWithDates, setGroupedCommunesWithDates] = useState<
    Array<Array<Array<Transfert>>>
  >([]);

  useEffect(() => {
    setGroupedCommunesWithDates(perimetresUtil.getGroupedPerimetreCommunesWithDates(transferts));
    setGroupedCommunes(perimetresUtil.getGroupedPerimetreCommunes(transferts));
  }, [transferts]);

  const memoizedCurrentGroupedCommunes = useMemo(
    () => (displayDates ?? false ? groupedCommunesWithDates : groupedCommunes),
    [displayDates, groupedCommunes, groupedCommunesWithDates]
  );

  return memoizedCurrentGroupedCommunes;
}
