export type EtatPrefectureType = "ETAT-SIMPLIFIE" | "ETAT-COMPLET";

export const typeEtatsNomFichier = new Map<EtatPrefectureType, string>([
  ["ETAT-COMPLET", "etats_prefecture_complets.docx"],
  ["ETAT-SIMPLIFIE", "etats_prefecture_simplifies.docx"],
]);

export type EtatPrefectureTypeLibelleListe = Array<{ type: EtatPrefectureType; libelle: string }>;
export const etatPrefectureCommunesIsolees: EtatPrefectureTypeLibelleListe = [
  { type: "ETAT-COMPLET", libelle: "États préfecture complets" },
  {
    type: "ETAT-SIMPLIFIE",
    libelle: "États préfecture simplifiés",
  },
];
export const etatPrefectureCollectivites: EtatPrefectureTypeLibelleListe = [];
export const etatPrefectureConseilGeneral: EtatPrefectureTypeLibelleListe = [];
