import { useEffect, useState, useCallback } from "react";
import type { ReactNode } from "react";
import { Grid, Typography } from "@mui/material";
import type { Territoire, Personne, Mandat } from "models";
import Fonctions from "constants/Fonctions";
import { elusUtil } from "utils";
import ElusListSkeleton from "components/Informations/Elus/ElusListSkeleton";
import EluLink from "components/Links/EluLink";

interface TerritoireInformationsGeneralesProps {
  territoire: Territoire;
  elus: Array<Personne>;
  isLoading?: boolean;
}

function TerritoireInformationsGenerales({
  territoire,
  elus,
  isLoading = false,
}: Readonly<TerritoireInformationsGeneralesProps>): ReactNode {
  const [importantElus, setImportantElus] = useState<Array<Personne>>([]);

  const filtreMandats = useCallback(
    (m: Mandat) => {
      return (
        (m.idFonction === Fonctions.PRESIDENT_TERRITOIRE ||
          m.idFonction === Fonctions.SUPPLEANT_PRESIDENT_TERRITOIRE ||
          m.idFonction === Fonctions.PRESIDENT_COMMISSION_MARCHES ||
          m.idFonction === Fonctions.SUPPLEANT_PRESIDENT_COMMISSION_MARCHES) &&
        m.idTerritoire === territoire.id
      );
    },
    [territoire]
  );

  useEffect(() => {
    const importantElusTerritoire = elusUtil.filterAndBoilElusMandatsDown(
      elus.filter((a: Personne) => a.mandats?.some(filtreMandats) ?? false),
      filtreMandats
    );
    importantElusTerritoire.sort(elusUtil.sortElusBySdeaMandatImportance);

    setImportantElus(importantElusTerritoire);
  }, [elus, filtreMandats]);

  if (isLoading) {
    return <ElusListSkeleton />;
  }

  return (
    <Grid item container xs={12} spacing={2}>
      {importantElus.map((p) => (
        <Grid item container xs={12} key={p.id}>
          <Grid item container xs={12}>
            <Typography>{elusUtil.getHigherSdeaMandat(p)?.libelleFonction}</Typography>
          </Grid>
          <Grid item container xs={12}>
            <EluLink elu={p} />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default TerritoireInformationsGenerales;
