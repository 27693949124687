import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Grid } from "@mui/material";
import { arrayUtil } from "@sdeapps/react-core";
import { competencesUtil } from "utils";
import type { TransfertViewModel } from "models";
import CommuneSousCompetencesAccordionSummary from "./CommuneSousCompetencesAccordionSummary";
import GceSousCompetencesAccordion from "components/Informations/SousCompetences/GceSousCompetencesAccordion";

interface CommuneGceSousCompetencesProps {
  transferts: Array<TransfertViewModel>;
}

function CommuneGceSousCompetences({
  transferts,
}: Readonly<CommuneGceSousCompetencesProps>): ReactNode {
  /** Sous-Compétences rangées par :
   * [Perimetre + Transferts
   *   [Bassin Versants
   *     [Sous-Compétences]
   *   ]]
   */
  const [transfertsByPerimetreAndBassinVersant, setTransfertsByPerimetreAndBassinVersant] =
    useState<Array<Array<Array<TransfertViewModel>>>>([]);

  useEffect(() => {
    // Groupe les sous-compétences par (Périmètre et Type de transfert), puis (Bassin versant),
    // et enfin les range par ordre de (Périmètre et Type de transfert) puis (sous-compétences).

    const transfertsByPerimetre = arrayUtil.groupByArray(
      transferts,
      (t) => `${t.idPerimetre}${t.idEpci}`
    );
    const result: Array<Array<Array<TransfertViewModel>>> = transfertsByPerimetre.map((sCA) =>
      arrayUtil.groupByArray(sCA, (t) => t.bassinVersant ?? "")
    );

    result.forEach((r) => {
      r.forEach((_r) => {
        _r.sort(competencesUtil.sortGceTransferts);
      });
    });
    result.sort((r1, r2) =>
      `${r1[0][0].perimetre.libelle}${r1[0][0].idEpci}`.localeCompare(
        `${r2[0][0].perimetre.libelle}${r2[0][0].idEpci}`
      )
    );
    setTransfertsByPerimetreAndBassinVersant(result);
  }, [transferts]);

  return (
    <Grid container item xs={12} spacing={2}>
      {transfertsByPerimetreAndBassinVersant.map((transfertsByBassinVersant) => {
        return (
          <Grid item xs={12} key={`${transfertsByBassinVersant[0][0].id}`}>
            <GceSousCompetencesAccordion
              transfertsByBassinVersants={transfertsByBassinVersant}
              defaultExpanded={transfertsByPerimetreAndBassinVersant.length === 1}
              summary={
                <CommuneSousCompetencesAccordionSummary
                  epci={transfertsByBassinVersant[0][0].epci}
                  perimetre={transfertsByBassinVersant[0][0].perimetre}
                />
              }
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default CommuneGceSousCompetences;
