import { createSvgIcon } from "@mui/material";

export const GrandCycleDeEauIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="5" fill="#93D4C4" />
    <path
      d="M3.66667 16.2708C5.33333 15.0208 7 15.0208 9.5 16.2708C12 17.5208 14.9167 16.2708 14.9167 16.2708C18.6667 14.6041 20.3333 16.2708 20.3333 16.2708"
      stroke="#F1F2F3"
      strokeWidth="0.666667"
      strokeLinecap="round"
    />
    <path
      d="M3.66665 17.7639C5.33331 16.5139 6.99998 16.5139 9.49998 17.7639C12 19.0139 14.9166 17.7639 14.9166 17.7639C18.6666 16.0972 20.3333 17.7639 20.3333 17.7639"
      stroke="#F1F2F3"
      strokeWidth="0.666667"
      strokeLinecap="round"
    />
    <path
      d="M3.66665 19.257C5.33331 18.007 6.99998 18.007 9.49998 19.257C12 20.507 14.9166 19.257 14.9166 19.257C18.6666 17.5904 20.3333 19.257 20.3333 19.257"
      stroke="#F1F2F3"
      strokeWidth="0.666667"
      strokeLinecap="round"
    />
    <path
      d="M7.78716 9.97902L7.51005 10.3804L7.99041 10.4652C10.2369 10.8615 11.9261 10.8635 13.1833 9.96441C14.4343 9.06966 15.1671 7.34625 15.7029 4.56603L15.7723 4.20601L15.4059 4.19389C13.9672 4.14634 12.8108 4.48683 11.6314 5.41242C10.4723 6.32204 9.2962 7.79309 7.78716 9.97902Z"
      stroke="#F1F2F3"
      strokeWidth="0.626126"
      strokeLinecap="round"
    />
    <path
      d="M9.32741 10.3413L8.89405 10.6838L9.41057 10.8796C11.8042 11.787 13.6214 12.1988 15.107 11.7231C16.6149 11.2403 17.6832 9.88038 18.6837 7.5391L18.8273 7.20313L18.4733 7.11275C16.9304 6.71882 15.6321 6.72919 14.2104 7.25291C12.8077 7.76966 11.302 8.78057 9.32741 10.3413Z"
      stroke="#F1F2F3"
      strokeWidth="0.626126"
      strokeLinecap="round"
    />
    <path
      d="M6.99983 9.91673C8.50548 9.78499 11.941 11.5033 12.4244 13.6847"
      stroke="#F1F2F3"
      strokeWidth="1.25225"
      strokeLinecap="round"
    />
  </svg>,
  "GrandCycleDeEauIcon"
);
