import { Avatar } from "@mui/material";
import { useUser } from "@sdeapps/react-core";
import { type ReactElement } from "react";

function getInitials(name: string): string {
  const names = name.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}

function HeaderAvatar(): ReactElement {
  const { user } = useUser();

  return (
    <Avatar alt={user.displayName} src={user.avatar}>
      {user?.avatar == null && getInitials(user.displayName)}
    </Avatar>
  );
}

export default HeaderAvatar;
