import { useEffect, useState, useCallback } from "react";
import type { ReactNode } from "react";
import { Link, Typography } from "@mui/material";
import type { LinkProps } from "@mui/material";
import { routesConfig } from "config/app-config";
import type { Territoire } from "models";
import { territoiresService } from "services";
import TypographySkeleton from "./TypographySkeleton";
import { useErrorHandler, withComponentErrorBoundary } from "utils/errorHandling";
import InlineErrorChip from "components/Errors/InlineErrorChip";

type TerritoireLinkData1 = {
  territoire: Territoire;
  id?: never;
  libelle?: never;
  noLink?: boolean;
};

type TerritoireLinkData2 = {
  territoire?: never;
  id: string;
  libelle?: string;
  noLink?: boolean;
};

type TerritoireLinkProps = LinkProps & (TerritoireLinkData1 | TerritoireLinkData2);

function TerritoireLink({
  territoire,
  id,
  libelle,
  noLink = false,
  ...linkProps
}: Readonly<TerritoireLinkProps>): ReactNode {
  const [libelleTerritoire, setLibelleTerritoire] = useState("");
  const [link, setLink] = useState("");

  const { catchErrors, isLoading, setIsLoading } = useErrorHandler({
    default: () => {
      setLibelleAndLink("Territoire inconnu", id ?? "");
    },
  });

  const setLibelleAndLink = useCallback(
    (libelle: string, id: string): void => {
      setLibelleTerritoire(libelle);
      setLink(routesConfig.territoire.getParameterPath(id));
      setIsLoading(false);
    },
    [setIsLoading]
  );

  useEffect(() => {
    async function getTerritoire(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const t = await territoiresService.getById(id!);
      setLibelleAndLink(t.libelle, t.id);
    }

    if (territoire != null) {
      setLibelleAndLink(territoire.libelle, territoire.id);
    } else if (libelle != null) {
      setLibelleAndLink(libelle, id);
    } else {
      void catchErrors(getTerritoire);
    }
  }, [territoire, id, libelle, setLibelleAndLink, catchErrors]);

  if (isLoading) {
    return <TypographySkeleton sx={{ maxWidth: 200 }} />;
  }

  if (noLink) {
    return (
      <Typography component="span" color="primary" fontWeight={500}>
        {libelleTerritoire}
      </Typography>
    );
  } else {
    return (
      <Link href={link} {...linkProps}>
        {libelleTerritoire}
      </Link>
    );
  }
}

const TerritoireLinkWithErrorBoundary = withComponentErrorBoundary(TerritoireLink, {
  override: {
    fallback: (_) => <InlineErrorChip text="Territoire inconnu" />,
  },
});

export default TerritoireLinkWithErrorBoundary;
