import type { User } from "@sdeapps/react-core";
import { graphApiClient } from "./apiClients";

/**
 * Récupère un agent depuis l'API Graph
 * @param agentId est l'identifiant de l'agent
 * @returns
 */
async function getAgentById(agentId: string): Promise<User | undefined> {
  const url = `/users/${agentId}`;
  const res = await graphApiClient.get<{ data: User }, { data: User }>(url);
  return res.data;
}

export const graphService = {
  getAgentById,
};
