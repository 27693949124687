import type { Perimetre, RaisonRetraitTransfert } from "models";
import type { TransfertEditionViewModel } from "../models/TransfertEditionViewModel";

export enum TransfertsEditionActionType {
  ReaffectationPerimetre = "Réaffectation Périmètre",
  ReaffectationEPCI = "Réaffectation EPCI",
  DirectIndirect = "Direct > Indirect",
  IndirectDirect = "Indirect > Direct",
  RetraitTransfert = "Retrait Transfert",
  SuppressionTransfert = "Suppression Transfert",
}

export enum TransfertsEditionReducerActionType {
  INIT_DATA = "init-data",
  DATA_LOADED = "data-loaded",

  SELECT_TOGGLE = "select-toggle",
  SELECT_ALL = "all-select",
  UNSELECT_ALL = "all-unselect",

  USER_ACTION = "user-action",
  USER_PARAMETRES = "user-parametres",
}

interface TransfertsEditionBaseAction<T> {
  type: TransfertsEditionReducerActionType;
  payload?: T;
}

interface InitData extends TransfertsEditionBaseAction<boolean> {
  type: TransfertsEditionReducerActionType.INIT_DATA;
}
interface DataLoaded
  extends TransfertsEditionBaseAction<{
    perimetre: Perimetre;
    transferts: Array<TransfertEditionViewModel>;
  }> {
  type: TransfertsEditionReducerActionType.DATA_LOADED;
}

interface ToggleSelection extends TransfertsEditionBaseAction<string> {
  type: TransfertsEditionReducerActionType.SELECT_TOGGLE;
}
interface SelectAll extends TransfertsEditionBaseAction<undefined> {
  type: TransfertsEditionReducerActionType.SELECT_ALL;
}
interface UnSelectAll extends TransfertsEditionBaseAction<undefined> {
  type: TransfertsEditionReducerActionType.UNSELECT_ALL;
}

interface UserAction extends TransfertsEditionBaseAction<TransfertsEditionActionType> {
  type: TransfertsEditionReducerActionType.USER_ACTION;
}

interface UserParametres extends TransfertsEditionBaseAction<TransfertsEditionActionData> {
  type: TransfertsEditionReducerActionType.USER_PARAMETRES;
}

export type TransfertsEditionReducerAction =
  | InitData
  | DataLoaded
  | ToggleSelection
  | SelectAll
  | UnSelectAll
  | UserAction
  | UserParametres;

export interface TransfertsEditionActionData {
  idCible?: string;
  dateEffet?: Date;
  raisonDuRetrait?: RaisonRetraitTransfert;
}
export interface TransfertsEditionState {
  /** Action de gestion sélectionnée par l'utilisateur. L'action impacte la liste des transferts utilisés. */
  actionUtilisateur?: TransfertsEditionActionType;
  actionParametres: TransfertsEditionActionData;
  perimetre?: Perimetre;
  /** Tous les transferts du périmètre, préférez utiliser `filteredTransferts` pour l'affichage */
  _transferts: Array<TransfertEditionViewModel>;
  /** Liste des transferts valides pour l'action utilisateur sélectionnée. Peut être vide. */
  filteredTransferts: Array<TransfertEditionViewModel>;
  /** Liste des identifiants de transfert sélectionnés */
  selectedTransferts: Array<string>;
  isLoading: boolean;
}

export type TransfertsEditionDispatch = (action: TransfertsEditionReducerAction) => void;
