import { Endpoints } from "constants/endpoints";
import type { EtablissementPublicModelForm, EtablissementPublic } from "models";
import { etablissementsPublicsApiClient } from "./apiClients";

/**
 * Récupère toutes les mairies (du 57, 67, 68)
 * @returns Une liste de mairies
 */
async function getAllMairies(): Promise<Array<EtablissementPublic>> {
  const url = `/${Endpoints.COMMUNES}/${Endpoints.MAIRIES}`;
  return etablissementsPublicsApiClient.get(url);
}

/**
 * Récupère les mairies d'une commune
 * @param code code INSEE de la commune
 * @returns Une liste de mairies
 */
async function getMairiesByCodeInsee(code: string): Promise<Array<EtablissementPublic>> {
  const url = `/${Endpoints.COMMUNES}/${code}/${Endpoints.MAIRIES}`;
  return etablissementsPublicsApiClient.get(url);
}

/**
 * Récupère les sieges d'un Epci
 * @param siren numero SIREN de l'EPCI
 * @returns Une liste de sieges
 */
async function getEpciSiegesBySiren(siren: string): Promise<Array<EtablissementPublic>> {
  const url = `/${Endpoints.EPCIS}/${siren}/${Endpoints.SIEGES}`;
  return etablissementsPublicsApiClient.get(url);
}

async function putMairie(code: string, e: EtablissementPublicModelForm): Promise<void> {
  const url = `/${Endpoints.COMMUNES}/${code}/${Endpoints.MAIRIES}`;
  await etablissementsPublicsApiClient.put(url, e);
}

async function putEpci(code: string, e: EtablissementPublicModelForm): Promise<void> {
  const url = `/${Endpoints.EPCIS}/${code}/${Endpoints.SIEGES}`;
  await etablissementsPublicsApiClient.put(url, e);
}

async function deleteSurchargeSdeaMairie(code: string): Promise<void> {
  const url = `/${Endpoints.COMMUNES}/${code}/${Endpoints.MAIRIES}`;
  await etablissementsPublicsApiClient.delete(url);
}

async function deleteSurchargeSdeaEpci(code: string): Promise<void> {
  const url = `/${Endpoints.EPCIS}/${code}/${Endpoints.SIEGES}`;
  await etablissementsPublicsApiClient.delete(url);
}

export const etablissementsPublicsService = {
  getAllMairies,
  getMairiesByCodeInsee,
  getEpciSiegesBySiren,
  putMairie,
  putEpci,
  deleteSurchargeSdeaMairie,
  deleteSurchargeSdeaEpci,
};
