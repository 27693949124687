import { useState, useEffect } from "react";
import type { ReactNode } from "react";
import { TableCell, TableRow } from "@mui/material";
import type { Transfert } from "models";
import type Competence from "constants/Competence";
import type { EauAssGepuContrat } from "constants/EauAssContrats";
import DatesRows from "../DatesRows";
import PorteeAccordionDetailWithIcon from "./PorteeAccordionDetailWithIcon";

interface EauAssSousCompetencesAccordionDetailsWithDatesProps {
  transferts: Array<Transfert>;
  sousCompetence: EauAssGepuContrat;
  competence: Competence;
  portee: number;
  isPorteesAdditionnelles?: boolean;
}

/**
 * Affiche les lignes du tableau de sous-compétences et portées avec dates qui concernent
 * une portée
 * @param isPorteesAdditionnelles : si true, on n'affichera pas la portée si elle n'est pas
 * transférée.
 * @returns
 */
function EauAssPorteesAccordionDetailsRowWithDates({
  transferts,
  sousCompetence,
  competence,
  portee,
  isPorteesAdditionnelles = false,
}: Readonly<EauAssSousCompetencesAccordionDetailsWithDatesProps>): ReactNode {
  const [hasPortee, setHasPortee] = useState(false);
  const [porteeTransferts, setPorteeTransferts] = useState<Array<Transfert>>([]);

  useEffect(() => {
    const _transferts = [...transferts].filter(
      (transfert) => transfert.code === `${competence}-${sousCompetence.code}-${portee}`
    );
    setPorteeTransferts(_transferts);
    setHasPortee(_transferts.length > 0);
  }, [competence, transferts, portee, sousCompetence]);

  if (porteeTransferts.length > 0) {
    return (
      <>
        {porteeTransferts?.map((transfert) => (
          <TableRow hover key={transfert.id}>
            <TableCell />
            <TableCell>
              <PorteeAccordionDetailWithIcon isPorteeAdheree={hasPortee} transfert={transfert} />
            </TableCell>
            <DatesRows dates={[transfert?.dateDeliberation, transfert?.datePremierTransfert]} />
          </TableRow>
        ))}
      </>
    );
  }

  if (isPorteesAdditionnelles) {
    return null;
  }

  return (
    <TableRow hover>
      <TableCell />
      <TableCell colSpan={3}>
        <PorteeAccordionDetailWithIcon
          isPorteeAdheree={hasPortee}
          transfert={`${competence}-${sousCompetence.code}-${portee}`}
        />
      </TableCell>
    </TableRow>
  );
}

export default EauAssPorteesAccordionDetailsRowWithDates;
