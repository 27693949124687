export const EauAssSousCompetencesId: Record<string, string> = {
  CE: "Contrôle, entretien et exploitation des équipements publics",
  AM: "Amélioration des équipements publics",
  AD: "Assistance administrative",
  AC: "Systèmes d'assainissement non collectif",
  ET: "Étude des équipements publics",
  EX: "Extension des équipements publics",
  MO: "Maîtrise d'ouvrage / Réalisation",
  RE: "Rénovation des équipements publics",
  AB: "Gestion des abonnés",
};

export const EauAssPorteesId: Record<number, string> = {
  1: "contrôle",
  2: "collecte",
  3: "distribution",
  4: "production",
  5: "traitement",
  6: "transport",
  7: "entretien",
  8: "limitée aux équipements publics de production",
  9: "limitée aux équipements publics de traitement",
  10: "limitées aux branchements",
  14: "limitée aux branchements des équipements publics de collecte",
};
