import { Grid, Skeleton } from "@mui/material";
import { type ReactElement } from "react";

function ElusListSkeleton(): ReactElement {
  return (
    <Grid item container xs={12} spacing={2}>
      {[0, 1, 2, 3].map((v) => (
        <Grid item container xs={12} key={v}>
          <Grid item container xs={12}>
            <Skeleton variant="text" sx={{ width: "50%" }} />
          </Grid>
          <Grid item container xs={12}>
            <Skeleton variant="text" sx={{ width: "30%" }} />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default ElusListSkeleton;
