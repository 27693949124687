import { type ReactElement } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTransfertsEditionContext } from "../store/transfertsEditionContext";
import Competence from "constants/Competence";
import PerimetreEditTransfertsTableContent from "./PerimetreEditTransfertsTableContent";
import { grey } from "@mui/material/colors";

function PerimetreEditTransfertsList(): ReactElement {
  const {
    state: { perimetre: { competence } = {}, filteredTransferts, isLoading },
  } = useTransfertsEditionContext();
  const showBassinVersant = competence === Competence.GCE;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: grey[100] }}>
            <TableCell padding="checkbox" sx={{ lineHeight: "1em", py: 1.5 }}></TableCell>
            <TableCell sx={{ lineHeight: "1em", py: 1.5 }}>
              <Typography fontWeight={600}>Commune</Typography>
            </TableCell>
            {showBassinVersant && (
              <TableCell sx={{ lineHeight: "1em", py: 1.5 }}>
                <Typography fontWeight={600}>Bassin versant</Typography>
              </TableCell>
            )}
            <TableCell sx={{ lineHeight: "1em", py: 1.5 }}>
              <Typography fontWeight={600}>EPCI</Typography>
            </TableCell>
            <TableCell sx={{ lineHeight: "1em", py: 1.5 }}>
              <Typography fontWeight={600}>Sous-Compétence</Typography>
            </TableCell>
            <TableCell sx={{ lineHeight: "1em", py: 1.5 }}>
              <Typography fontWeight={600}>Portée</Typography>
            </TableCell>
            <TableCell sx={{ lineHeight: "1em", py: 1.5 }}>
              <Typography fontWeight={600}>Début</Typography>
              <Typography variant="caption">yyyy-MM-dd</Typography>
            </TableCell>
            <TableCell sx={{ lineHeight: "1em", py: 1.5 }}>
              <Typography fontWeight={600}>Délibération</Typography>
              <Typography variant="caption">yyyy-MM-dd</Typography>
            </TableCell>
            <TableCell sx={{ lineHeight: "1em", py: 1.5 }}>
              <Typography fontWeight={600}>Assemblée Générale</Typography>
              <Typography variant="caption">yyyy-MM-dd</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <PerimetreEditTransfertsTableContent
            isLoading={isLoading}
            showBassinVersant={showBassinVersant}
            transferts={filteredTransferts}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PerimetreEditTransfertsList;
