import { CircularProgress, Grid } from "@mui/material";
import { type ReactElement } from "react";

function LoadingScreen(): ReactElement {
  return (
    <Grid container sx={{ flexGrow: 1 }} justifyContent="center">
      <Grid item sx={{ marginY: "20vh" }}>
        <CircularProgress size="15vh" />
      </Grid>
    </Grid>
  );
}

export default LoadingScreen;
