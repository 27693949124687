import type { ReactElement } from "react";
import { Grid, Typography } from "@mui/material";
import CompetenceTypography from "components/Typography/CompetenceTypography";
import TypeTransfert from "constants/TypeTransfert";
import type { Perimetre } from "models";

interface PerimetreSubTitleProps {
  perimetre: Perimetre;
}

function PerimetreSubTitle({ perimetre }: Readonly<PerimetreSubTitleProps>): ReactElement {
  return (
    <Grid item container columnGap={2} direction="row" xs={12} alignItems="center" sx={{ mt: 1 }}>
      <CompetenceTypography competence={perimetre.competence} variant="caption" fontSize="1rem" />
      <Typography variant="caption" fontSize="1rem">
        {perimetre.typeTransfert === TypeTransfert.PI ? "Transfert complet" : "Transfert partiel"}
      </Typography>
    </Grid>
  );
}

export default PerimetreSubTitle;
