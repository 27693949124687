import { useState, useEffect } from "react";
import type { ReactNode } from "react";
import { Grid, MenuItem, Button, FormHelperText, Stack } from "@mui/material";
import TypeTransfert from "constants/TypeTransfert";
import type { Perimetre, PerimetreModelForm, Territoire } from "models";
import { territoiresService, perimetresService, networkService } from "services";
import { useErrorHandler, ErrorNames } from "utils/errorHandling";
import LoadingScreen from "components/Template/LoadingScreen";
import { useForm, useWatch } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import { routesConfig } from "config/app-config";
import { useNavigate } from "react-router-dom";
import { useSearch } from "providers";
import { dateUtil } from "@sdeapps/react-core";
import ControlledTextField from "components/inputs/ControlledTextField";
import ControlledDateTime from "components/inputs/ControlledDateTime";
import LoadingButton from "components/LoadingButton";
import ToastMessages from "constants/ToastMessages";
import CompetenceChip from "components/Typography/CompetenceChip";
import { competencesAssociationList } from "constants/CompetenceMap";
import { enqueueSnackbar } from "notistack";
import WarningIcon from "@mui/icons-material/Warning";

function PerimetreForm(): ReactNode {
  const [territoires, setTerritoires] = useState<Array<Territoire>>([]);
  const [perimetres, setPerimetres] = useState<Array<Perimetre>>([]);
  const [perimetreWithSameCodeSdea, setPerimetreWithSameCodeSdea] = useState<Perimetre>();
  const navigate = useNavigate();
  const { update, addOptimisticPerimetre } = useSearch();

  const { handleSubmit, control } = useForm<PerimetreModelForm>({ shouldFocusError: false });
  const codeSdea = useWatch({ control, name: "codeSdea" });
  const competence = useWatch({ control, name: "competence" });

  const { catchErrors, isLoading } = useErrorHandler();
  const { catchErrors: catchCreateErrors, isLoading: isSending } = useErrorHandler({
    dontThrow: true,
    defaultIsLoading: false,
    [ErrorNames.BadRequest]: (error) => {
      // Le périmètre n'a pas été créé à cause d'une faute dans la requête, on précise
      // laquelle à l'utilisateur
      enqueueSnackbar({
        variant: "error",
        message: error.message,
      });
    },
    default: () => {
      // Le périmètre n'a pas été créé, on affiche une snackbar d'erreur à l'utilisateur
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR_RETRY,
      });
    },
  });

  useEffect(() => {
    async function getTerritoires(): Promise<void> {
      const _territoires = await territoiresService.getAll();
      setTerritoires(_territoires);
    }

    async function getPerimetres(): Promise<void> {
      const _perimetres = await perimetresService.getAll();
      setPerimetres(_perimetres);
    }

    void catchErrors(getTerritoires);
    void catchErrors(getPerimetres);
  }, [catchErrors]);

  useEffect(() => {
    setPerimetreWithSameCodeSdea(
      perimetres.find((p) => p.code === codeSdea && p.competence === competence)
    );
  }, [codeSdea, competence, perimetres]);

  const submitPerimetre: SubmitHandler<PerimetreModelForm> = async function (
    data: PerimetreModelForm
  ): Promise<void> {
    async function createPerimetre(): Promise<void> {
      const createdPerimetre = await perimetresService.create(data);

      enqueueSnackbar({
        variant: "success",
        message: ToastMessages.CREATED_PERIMETRE,
      });

      /**
       * On a reçu une 201 Created; le périmètre est créé dans les données maîtres,
       * on part du principe que tout va bien et on ajoute les données du périmètre
       * créé dans la recherche.
       */
      addOptimisticPerimetre(createdPerimetre);
      navigate(routesConfig.admin.path);

      /**
       * On attend que la réplication des données soit terminée (=que le périmètre créé
       * soit présent dans les tables de vues) pour mettre à jour toutes les listes de
       * la recherche.
       */
      await networkService.waitForReplication(
        async () => {
          await perimetresService.getById(createdPerimetre.id);
        },
        20,
        update,
        () => {
          enqueueSnackbar({
            variant: "warning",
            message: ToastMessages.LONG_REPLICATION,
          });
          update();
        }
      );
    }

    await catchCreateErrors(createPerimetre);
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <Grid component="form" onSubmit={handleSubmit(submitPerimetre)} item container spacing={4}>
      <Grid item container xs={12} spacing={4}>
        <Grid item xs={12}>
          <ControlledTextField
            name="libelle"
            control={control}
            defaultValue=""
            rules={{ required: "Saisir un nom est obligatoire" }}
            fullWidth
            label="Désignation du périmètre *"
            variant="outlined"
            placeholder="Commission locale de ..."
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControlledTextField
            select
            name="competence"
            control={control}
            rules={{ required: "Choisir une compétence est obligatoire" }}
            label="Compétence du périmètre *">
            {competencesAssociationList.map((c) => (
              <MenuItem key={c.competence} value={c.competence}>
                <CompetenceChip competence={c.competence} sx={{ marginRight: 1 }} />
                {c.label}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid container item xs={12} md={6}>
          <ControlledTextField
            name="codeSdea"
            control={control}
            rules={{ required: "Saisir un code Sdea est obligatoire" }}
            type="number"
            label="Code du périmètre *"
            variant="outlined"
            placeholder="A saisir"
          />
          {perimetreWithSameCodeSdea != null && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <WarningIcon color="warning" />
              <FormHelperText>
                Attention ! Ce code SDEA est déjà utilisé pour cette compétence par le périmètre{" "}
                {perimetreWithSameCodeSdea.libelle}
              </FormHelperText>
            </Stack>
          )}
        </Grid>
        <Grid container item xs={12} sm={6}>
          <ControlledDateTime
            name="dateMembre"
            control={control}
            rules={{
              required: "Choisir une date de création est obligatoire",
              validate: (value: Date | null) =>
                dateUtil.isValid(value) || "Veuillez renseigner une date valide",
            }}
            label="Date de création *"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            select
            name="typeTransfert"
            control={control}
            rules={{ required: "Choisir un type de transfert est obligatoire" }}
            label="Type de transfert *">
            {Object.values(TypeTransfert).map((v) => (
              <MenuItem key={v} value={v}>
                {v === TypeTransfert.PI ? "Transfert complet" : "Transfert partiel"}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            select
            name="idTerritoire"
            control={control}
            rules={{ required: "Choisir un territoire est obligatoire" }}
            label="Territoire de rattachement *">
            {territoires.map((t) => {
              return (
                <MenuItem key={t.id} value={t.id}>
                  {t.libelle}
                </MenuItem>
              );
            })}
          </ControlledTextField>
        </Grid>
      </Grid>
      <Grid container item xs={12} gap={2}>
        <Button href={routesConfig.admin.path} color="error">
          Annuler
        </Button>
        <LoadingButton variant="contained" loading={isSending}>
          Enregistrer
        </LoadingButton>
      </Grid>
    </Grid>
  );
}

export default PerimetreForm;
