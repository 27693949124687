import { useCallback, useEffect, useState } from "react";
import type { ReactNode } from "react";
import type Competence from "constants/Competence";
import { Button, Grid, TextField } from "@mui/material";
import GceContrats from "constants/GceContrats";
import CheckboxForArray from "./CheckboxForArray";
import type { CreateTransfertsModelFormGceTransfert } from "models";

interface RowProps {
  index: number;
  value: Array<CreateTransfertsModelFormGceTransfert>;
  onChange: (a: Array<CreateTransfertsModelFormGceTransfert>) => void;
}

function Row({ index, value, onChange }: Readonly<RowProps>): ReactNode {
  function onChangeBassinVersant(v: string): void {
    const newValue = [...value];
    newValue[index].bassinVersant = v;
    onChange(newValue);
  }

  function onChangeCodeTransferts(v: Array<string>): void {
    const newValue = [...value];
    newValue[index].codeTransferts = v;
    onChange(newValue);
  }

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="Bassin versant"
          variant="outlined"
          value={value[index].bassinVersant}
          onChange={(event) => {
            onChangeBassinVersant(event.target.value);
          }}
        />
      </Grid>
      {GceContrats.map((sousCompetence) => (
        <Grid container item key={sousCompetence.code} xs={12} alignItems="center">
          <CheckboxForArray
            value={value[index].codeTransferts}
            onChange={onChangeCodeTransferts}
            codes={`GCE-${sousCompetence.code}-0`}
            label={sousCompetence.nom}
          />
        </Grid>
      ))}
    </Grid>
  );
}

interface NewTransfertPorteesGceProps {
  value: Array<CreateTransfertsModelFormGceTransfert>;
  onChange: (a: Array<CreateTransfertsModelFormGceTransfert>) => void;
  competence: Competence;
}

function NewTransfertPorteesGce({
  value,
  onChange,
  competence,
}: Readonly<NewTransfertPorteesGceProps>): ReactNode {
  const [count, setCount] = useState<number>(1);
  const [countArray, setCountArray] = useState<Array<number>>([0]);
  const resetAllPortees = useCallback((): void => {
    onChange([]);
  }, [onChange]);

  useEffect(() => {
    resetAllPortees();
  }, [onChange, resetAllPortees, competence]);

  return (
    <Grid container item xs={12} spacing={4}>
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={() => {
            setCount(count + 1);
            setCountArray([...countArray, countArray.length]);
          }}>
          Ajouter un bassin versant
        </Button>
      </Grid>
      {/* Tester avec value plutôt en ajoutant des lignes à value au lieu de setcount + 1 */}
      {countArray.map((v) => {
        if (value[v] == null) {
          value[v] = { bassinVersant: "", codeTransferts: [] };
        }
        return <Row index={v} key={v} value={value} onChange={onChange} />;
      })}
    </Grid>
  );
}

export default NewTransfertPorteesGce;
