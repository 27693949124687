import { useMemo, type ReactNode } from "react";
import { Typography } from "@mui/material";
import { dateUtil } from "@sdeapps/react-core";

interface CommuneCommentaireInformationProps {
  auteurDisplayName?: string;
  dateCreation?: string;
}
function CommuneCommentaireInformation({
  auteurDisplayName,
  dateCreation,
}: Readonly<CommuneCommentaireInformationProps>): ReactNode {
  const infoModification = useMemo<string | undefined>(() => {
    const infos: Array<string> = [];
    if (auteurDisplayName != null) {
      infos.push(auteurDisplayName);
    }
    if (dateCreation != null) {
      infos.push("le");
      infos.push(dateUtil.format(dateCreation, "dd/MM/yyyy"));
      infos.push("à");
      infos.push(dateUtil.format(dateCreation, "HH:mm"));
    }
    return infos.length > 0 ? infos.join(" ") : undefined;
  }, [auteurDisplayName, dateCreation]);

  return (
    infoModification != null && (
      <Typography variant="caption">
        Dernière modification&nbsp;:{` ${infoModification}`}
      </Typography>
    )
  );
}

export default CommuneCommentaireInformation;
