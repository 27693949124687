import { useCallback, useEffect, useState } from "react";
import type { ReactElement } from "react";
import { Grid, Typography } from "@mui/material";
import type { TypographyOwnProps } from "@mui/material";
import type { Commune, CommuneAssocieeDeleguee } from "models";
import CommuneLink from "./Links/CommuneLink";
import { arrayUtil } from "@sdeapps/react-core";
import { communesAssocieesDelegueesService } from "services";
import { useErrorHandler, withComponentErrorBoundary } from "utils/errorHandling";
import type { SdeappsError } from "utils/errorHandling";
import ListSkeleton from "./ListSkeleton";

interface CommunesListProps {
  communes: Array<Commune>;
  communesAssocieesDelegueesToHighlight?: Set<string>;
  columnNumber?: number;
  lengthSentence?: string;
  zeroSentence?: string;
  isLoading?: boolean;
  error?: SdeappsError;
}

function CommunesList({
  communes,
  communesAssocieesDelegueesToHighlight,
  columnNumber = 3,
  lengthSentence = `${communes.length} communes ont adhéré.`,
  zeroSentence = "Aucune commune n'a adhéré",
  isLoading = false,
  error,
}: Readonly<CommunesListProps>): ReactElement {
  const [communesColumns, setCommunesColumns] = useState<Array<Array<Commune>>>([]);
  const [communesAssocieesDeleguees, setCommunesAssocieesDeleguees] =
    useState<Record<string, Array<CommuneAssocieeDeleguee>>>();

  const { catchErrors, setOriginalError, isLoading: isCadsLoading } = useErrorHandler();

  useEffect(() => {
    if (error != null) {
      setOriginalError(error);
    }
  }, [error, setOriginalError]);

  useEffect(() => {
    async function getCommunesAssocieesDeleguees(): Promise<void> {
      const _communes = [...communes];
      _communes.sort((a, b) => a.libelle.localeCompare(b.libelle));
      setCommunesColumns(arrayUtil.separateIntoNArrays(_communes, columnNumber));

      const cadsAssociation = await Promise.all(
        _communes.map(async (c) => {
          return {
            cheflieu: c.id,
            cads: await communesAssocieesDelegueesService.getByChefLieuId(c.id),
          };
        })
      );

      const cadsRecord: Record<string, Array<CommuneAssocieeDeleguee>> = {};
      cadsAssociation.forEach((cadAssoc) => {
        cadsRecord[cadAssoc.cheflieu] = cadAssoc.cads;
      });
      setCommunesAssocieesDeleguees(cadsRecord);
    }

    void catchErrors(getCommunesAssocieesDeleguees);
  }, [communes, columnNumber, catchErrors]);

  const getStyleToHighlight = useCallback(
    (communeId: string): { fontWeight: string | number; color: TypographyOwnProps["color"] } => {
      if (communesAssocieesDelegueesToHighlight?.has(communeId) === true) {
        return { fontWeight: 600, color: "primary.main" };
      } else {
        return { fontWeight: "inherit", color: "inherit" };
      }
    },
    [communesAssocieesDelegueesToHighlight]
  );

  if (isLoading || isCadsLoading) {
    return <ListSkeleton lines={3} />;
  }

  if (communes.length === 0) {
    return (
      <Grid item xs={12}>
        <Typography>{zeroSentence}</Typography>
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="caption" fontStyle="italic">
          {lengthSentence}
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        {communesColumns.map((communeColumn, i) => {
          const key = `column ${i}`;
          return (
            <Grid
              container
              item
              xs={12}
              md={12 / columnNumber}
              direction="column"
              key={key}
              spacing={0.5}>
              {communeColumn.map((c) => {
                return (
                  <Grid item container key={c.siren}>
                    <Grid item xs={12}>
                      <CommuneLink commune={c} />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: -1 }}>
                      <Typography variant="caption" fontStyle="italic">
                        {communesAssocieesDeleguees?.[c.id]?.map((cad, i, a) => (
                          <span key={cad.id}>
                            <CommuneLink
                              commune={cad}
                              variant="caption"
                              {...getStyleToHighlight(cad.id)}
                            />
                            {i < a.length - 1 && ", "}
                          </span>
                        ))}
                        {"\u00A0"}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

const CommunesListWithErrorBoundary = withComponentErrorBoundary(CommunesList);

export default CommunesListWithErrorBoundary;
