import { TabContext, TabList } from "@mui/lab";
import { Grid, tabClasses, tabsClasses, type TabsProps } from "@mui/material";
import type Competence from "constants/Competence";
import competenceMap from "constants/CompetenceMap";
import { type ReactNode, type ReactElement } from "react";

interface CompetenceTabsProps extends TabsProps {
  tabs: Array<ReactNode>;
  panels: Array<ReactNode>;
}

function CompetenceTabs({
  value,
  onChange,
  tabs,
  panels,
}: Readonly<CompetenceTabsProps>): ReactElement {
  return (
    <TabContext value={value}>
      <Grid container>
        <Grid item xs={12}>
          <TabList
            onChange={onChange}
            variant="scrollable"
            scrollButtons="auto"
            selectionFollowsFocus
            sx={{
              [`*.${tabClasses.root}`]: { color: `text.primary` },
              [`*.${tabClasses.selected}`]: {
                color: `${competenceMap.get(value as Competence)?.color}.main`,
              },
              [`*.${tabsClasses.indicator}`]: {
                backgroundColor: `${competenceMap.get(value as Competence)?.color}.main`,
              },
            }}>
            {tabs}
          </TabList>
        </Grid>
        <Grid container item xs={12} spacing={2} sx={{ "*.MuiTabPanel-root": { pr: 0 } }}>
          {panels}
        </Grid>
      </Grid>
    </TabContext>
  );
}

export default CompetenceTabs;
