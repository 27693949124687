import { CircularProgress, Grid, type GridProps, type SxProps, Typography } from "@mui/material";
import { type ReactElement } from "react";
import { useSearch } from "providers";
import { LazyLoadedSearchResults, BestSearchResults } from "./";

interface SearchResultsProps extends GridProps {
  sx?: SxProps;
}

/**
 * Affiche l'interface (messages, CircularProgress) et les résultats de la recherche.
 * A entourer d'un SearchProvider.
 * @param sx?: SxProps
 */
export function SearchResults({ sx, ...props }: Readonly<SearchResultsProps>): ReactElement {
  const { isDirty, isSearching, isLoading, isNoMatch, filteredResults, bestResults } = useSearch();

  return (
    <Grid {...props} container sx={{ position: "relative", ...sx }} spacing={1}>
      {(isSearching || (isLoading && (isDirty || isNoMatch))) && (
        <Grid
          item
          container
          xs={12}
          sx={{ position: "absolute", zIndex: (theme) => theme.zIndex.drawer + 3 }}
          justifyContent="center">
          <CircularProgress />
        </Grid>
      )}

      {!isNoMatch && filteredResults.length === 0 && bestResults.length === 0 && (
        <Grid item xs={12} textAlign="center">
          <Typography color="grey">
            Recherchez une Commune, un EPCI, un Périmètre, un Territoire, un Élu...
          </Typography>
        </Grid>
      )}

      <BestSearchResults item />

      <LazyLoadedSearchResults initialResultsNumber={40} step={30} />

      {!isLoading && isNoMatch && (
        <Grid item xs={12} textAlign="center">
          <Typography>
            Nous n'avons trouvé aucun résultat correspondant à votre recherche.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
