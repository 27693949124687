import { useState, useEffect } from "react";
import type { ReactNode } from "react";
import { Autocomplete, TextField, Typography, createFilterOptions } from "@mui/material";
import { useController } from "react-hook-form";
import type { FieldValues, FieldPath } from "react-hook-form";
import { stringUtil } from "@sdeapps/react-core";
import type { Territoire } from "models";
import type { ControlledInput } from "utils";
import { useErrorHandler } from "utils/errorHandling";
import { territoiresService } from "services";
import InputSkeleton from "components/InputSkeleton";

interface TerritoireAutoCompleteProps<T extends FieldValues, Name extends FieldPath<T>>
  extends ControlledInput<T, Name> {
  label: string;
}

function TerritoireAutoComplete<T extends FieldValues, Name extends FieldPath<T>>({
  name,
  label,
  control,
  rules,
}: Readonly<TerritoireAutoCompleteProps<T, Name>>): ReactNode {
  const [territoires, setTerritoires] = useState<Array<Territoire>>([]);
  const filterOptions = createFilterOptions({
    trim: true,
    stringify: (option: Territoire) => stringUtil.normalize(option.libelle),
  });

  const {
    field: { onChange, ref },
    fieldState: { error },
  } = useController({ name, control, rules });

  const { catchErrors, isLoading } = useErrorHandler();

  useEffect(() => {
    async function getTerritoires(): Promise<void> {
      const _territoires = await territoiresService.getAll();

      _territoires.sort((a, b) =>
        stringUtil.normalize(a.libelle).localeCompare(stringUtil.normalize(b.libelle))
      );

      setTerritoires(_territoires);
    }

    void catchErrors(getTerritoires);
  }, [catchErrors]);

  if (isLoading) {
    return <InputSkeleton />;
  }

  return (
    <Autocomplete
      fullWidth
      autoHighlight
      openOnFocus
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={territoires}
      filterOptions={filterOptions}
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
      isOptionEqualToValue={(v1: Territoire, v2: Territoire): boolean => v1.id === v2.id}
      getOptionLabel={(territoire: Territoire) => territoire.libelle}
      renderOption={(props, option) => (
        <Typography component="li" {...props} sx={{ display: "block" }} key={option.id}>
          {option.libelle}
        </Typography>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label ?? "Territoire"}
          placeholder="Nom du Territoire"
          inputRef={ref}
          error={error != null}
          helperText={error?.message}
        />
      )}
    />
  );
}

export default TerritoireAutoComplete;
