import { forwardRef, useState, useEffect } from "react";
import type { ReactElement, Ref, ReactNode } from "react";
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Slide,
  Switch,
  Toolbar,
  Typography,
} from "@mui/material";
import ControlledTextField from "components/inputs/ControlledTextField";
import type { EtablissementPublic, EtablissementPublicModelForm } from "models";
import { type TransitionProps, enqueueSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { type SubmitHandler, useForm } from "react-hook-form";
import ToastMessages from "constants/ToastMessages";
import { useErrorHandler } from "utils/errorHandling";
import LoadingButton from "components/LoadingButton";
import { useNavigate } from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";

const nbsp = "\u00A0"; // non-breakable space

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * On vérifie si au moins un champ est renseigné dans le formulaire
 */
function validate1ChampRenseigne(values: Array<string | undefined | null>): boolean | string {
  return (
    // `JS for noobies`:
    values
      // 1. L'utilisation de la méthode `.filter(Boolean)` permet d'éliminer
      //    toutes valeurs _falsy_, et donc les `undefined`, `null` et `""` (chaine vide).
      .filter(Boolean)
      // 2. Suivi par l'appel à `.join("")` permet de concaténer toutes les valeurs du tableaux
      //    (sans séparateur étant donné l'argument `""`).
      .join("")
      // 3. Finalement, l'utilisation de la méthode `.trim()` permet de s'assurer d'éliminer toutes
      //    valeurs résultantes de la concaténation de chaines d'espaces uniquement.
      .trim() !== "" ||
    // 4. Finalement, si la comparaison avec une chaine vide n'est pas vérifiée, retour d'un texte
    //    pouvant être utilisé au niveau de l'input pour indiquer la validation en erreur.
    "Au moins un changement est requis pour enregister (Email, Téléphone ou Adresse postale)."
  );
}

/**
 * On check si au moins un des trois champs de l'adresse est rempli
 */
function checkAdresse(values: Array<string | undefined | null>): boolean {
  return values.filter(Boolean).join("").trim() !== "" || false;
}

interface EtablissementPublicFormProps {
  etablissementPublic?: Partial<EtablissementPublic>;
  open: boolean;
  idCollectivite: string; // codeInsee ou SIREN d'un EPCI
  setOpen: (b: boolean) => void;
  upsertEtablissementCoordonnees: (id: string, e: EtablissementPublicModelForm) => Promise<void>;
  suppEtablissementCoordonnees: (id: string) => Promise<void>;
}

/**
 * Modal qui contient le formulaire de surcharge des coordonnées
 */
function EtablissementPublicForm({
  etablissementPublic,
  open,
  setOpen,
  idCollectivite,
  upsertEtablissementCoordonnees,
  suppEtablissementCoordonnees,
}: Readonly<EtablissementPublicFormProps>): ReactNode {
  const navigate = useNavigate();
  const [checked, setChecked] = useState<boolean>(etablissementPublic?.adresse != null);
  const { handleSubmit, control, watch, reset } = useForm<EtablissementPublicModelForm>({
    shouldFocusError: false,
  });
  const { catchErrors, isLoading } = useErrorHandler({
    dontThrow: true,
    defaultIsLoading: false,
    default: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR_RETRY,
      });
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(event.target.checked);
  };

  const adresseValidationRule = {
    required: checked && "Pour la création d'une nouvelle adresse, ce champ est obligatoire.",
  };

  const oneValidationRule = {
    validate: () =>
      (checkAdresse([
        watch("adresse.codePostal"),
        watch("adresse.commune"),
        watch("adresse.numeroVoie"),
      ] as Array<string | undefined>) &&
        checked) ||
      validate1ChampRenseigne([watch("email"), watch("telephone")] as Array<string | undefined>),
  };

  function handleClose(): void {
    setOpen(false);
  }

  useEffect(() => {
    reset(etablissementPublic);
  }, [
    etablissementPublic,
    open,
    setOpen,
    idCollectivite,
    upsertEtablissementCoordonnees,
    suppEtablissementCoordonnees,
    reset,
  ]);

  const submitEtablissementPublic: SubmitHandler<EtablissementPublicModelForm> = async function (
    data: EtablissementPublicModelForm
  ): Promise<void> {
    async function createEtablissementPublic(): Promise<void> {
      const res: EtablissementPublicModelForm = {
        ...data,
        id: etablissementPublic?.id,
        nom: etablissementPublic?.nom,
        adresse: checked
          ? {
              numeroVoie: data.adresse?.numeroVoie?.trim(),
              codePostal: data.adresse?.codePostal?.trim(),
              commune: data.adresse?.commune?.trim(),
              latitude: undefined,
              longitude: undefined,
              serviceDistribution: data.adresse?.serviceDistribution?.trim(),
              complement1: data.adresse?.complement1?.trim(),
              complement2: data.adresse?.complement2?.trim(),
              typeAdresse: undefined,
            }
          : undefined,
        telephone: data.telephone === "" ? undefined : data.telephone?.trim(),
        email: data.email === "" ? undefined : data.email?.trim(),
      };
      await upsertEtablissementCoordonnees(idCollectivite, res);

      enqueueSnackbar({
        variant: "success",
        message: ToastMessages.CREATED_ETABLISSEMENT_PUBLIC,
      });
    }

    await catchErrors(createEtablissementPublic);

    handleClose();
    navigate(0);
  };

  const submitDeleteEtablissementPublic = async function (): Promise<void> {
    async function deleteEtablissementPublic(): Promise<void> {
      await suppEtablissementCoordonnees(idCollectivite);

      enqueueSnackbar({
        variant: "success",
        message: ToastMessages.DELETED_ETABLISSEMENT_PUBLIC,
      });
    }

    await catchErrors(deleteEtablissementPublic);

    handleClose();
    navigate(0);
  };

  return (
    <Dialog
      component="form"
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(submitEtablissementPublic)}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" color="white">
            {etablissementPublic?.nom}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={2}>
            <Grid container item xs={12} alignItems="center">
              <Grid item xs={6}>
                <DialogContentText variant="h3">Informations de contact</DialogContentText>
              </Grid>
              <Grid container item xs={6} justifyContent="flex-end">
                <LoadingButton
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={submitDeleteEtablissementPublic}
                  loading={isLoading}
                  variant="contained"
                  color="error"
                  disabled={etablissementPublic?.modifiedBy == null}
                  startIcon={<DeleteIcon />}>
                  Supprimer les coordonnées
                </LoadingButton>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <ControlledTextField
                name="email"
                control={control}
                defaultValue={etablissementPublic?.email}
                fullWidth
                label="Adresse email"
                variant="outlined"
                type="email"
                placeholder="ex : mairie@contact.fr"
                rules={{
                  ...oneValidationRule,
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Adresse e-mail invalide.",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ControlledTextField
                name="telephone"
                control={control}
                defaultValue={etablissementPublic?.telephone}
                fullWidth
                label="Téléphone"
                variant="outlined"
                placeholder="ex : 03.00.00.00.00"
                rules={{
                  ...oneValidationRule,
                  pattern: {
                    value: /^((\+\d{2}[./\s]?(\d{1,2}[./\s]?))|(\d{1,2}[./\s]?))(\d{2}[./\s]?){4}$/,
                    message: `Numéro de téléphone invalide : Les formats acceptés sont XX${nbsp}XX${nbsp}XX${nbsp}XX${nbsp}XX, XX.XX.XX.XX.XX, +XX${nbsp}X${nbsp}XX${nbsp}XX${nbsp}XX${nbsp}XX.`,
                  },
                }}
                defaultHelperText={`Les formats acceptés sont XX${nbsp}XX${nbsp}XX${nbsp}XX${nbsp}XX, XX.XX.XX.XX.XX, +XX${nbsp}X${nbsp}XX${nbsp}XX${nbsp}XX${nbsp}XX.`}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} direction="row" alignItems="center">
            <Typography>Voulez-vous changer l'adresse postale de la commune : Non</Typography>
            <Switch checked={checked} onChange={handleChange} />
            <Typography>Oui</Typography>
          </Grid>
          {checked && (
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <DialogContentText variant="h3">Adresse</DialogContentText>
              </Grid>
              <Grid item xs={6}>
                <ControlledTextField
                  name="adresse.numeroVoie"
                  control={control}
                  defaultValue={etablissementPublic?.adresse?.numeroVoie}
                  fullWidth
                  label="Rue et voie *"
                  variant="outlined"
                  placeholder="ex : 4 impasse des alouettes"
                  rules={adresseValidationRule}
                  disabled={!checked}
                />
              </Grid>
              <Grid item xs={6}>
                <ControlledTextField
                  name="adresse.serviceDistribution"
                  control={control}
                  defaultValue={etablissementPublic?.adresse?.serviceDistribution}
                  fullWidth
                  label="Service de distribution"
                  variant="outlined"
                  placeholder="ex : BP 7003"
                  disabled={!checked}
                />
              </Grid>
              <Grid item xs={6}>
                <ControlledTextField
                  name="adresse.complement1"
                  control={control}
                  defaultValue={etablissementPublic?.adresse?.complement1}
                  fullWidth
                  label="Complement d'adresse 1"
                  variant="outlined"
                  placeholder="ex : 1er étage, batiment A ..."
                  disabled={!checked}
                />
              </Grid>
              <Grid item xs={6}>
                <ControlledTextField
                  name="adresse.complement2"
                  control={control}
                  defaultValue={etablissementPublic?.adresse?.complement2}
                  fullWidth
                  label="Complement d'adresse 2"
                  variant="outlined"
                  placeholder="ex : 1er étage, batiment A ..."
                  disabled={!checked}
                />
              </Grid>
              <Grid item xs={6}>
                <ControlledTextField
                  name="adresse.codePostal"
                  control={control}
                  defaultValue={etablissementPublic?.adresse?.codePostal}
                  fullWidth
                  label="Code postal *"
                  variant="outlined"
                  placeholder="ex : 67350"
                  rules={adresseValidationRule}
                  disabled={!checked}
                />
              </Grid>
              <Grid item xs={6}>
                <ControlledTextField
                  name="adresse.commune"
                  control={control}
                  defaultValue={etablissementPublic?.adresse?.commune}
                  fullWidth
                  label="Commune *"
                  variant="outlined"
                  placeholder="ex : Val-de-Moder"
                  rules={adresseValidationRule}
                  disabled={!checked}
                />
              </Grid>
            </Grid>
          )}
          <Grid container item xs={12} spacing={2} sx={{ mt: 2 }} justifyContent="flex-end">
            <Grid item xs={12} md>
              <Typography variant="caption">
                Attention ! Les données saisies seront prioritaires par rapport aux données
                publiques dans les différents exports.
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Button variant="outlined" onClick={handleClose}>
                Annuler
              </Button>
            </Grid>
            <Grid item xs="auto">
              <LoadingButton
                variant="contained"
                loading={isLoading}
                type="submit"
                startIcon={<SaveIcon />}>
                Enregister les modifications
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default EtablissementPublicForm;
