import type { AxiosError } from "axios";

export interface SdeappsErrorOptions {
  originalError?: Error | AxiosError;
  stack?: string;
  status?: number;
  statusText?: string;
  description?: string;
}

/**
 * Enum listant le nom des différentes {@link SdeappsError}.
 *
 * Sert à distinguer les types d'erreurs dans notre gestion des erreurs.
 */
export enum ErrorNames {
  BadRequest = "SdeappsErrorBadRequest",
  Unauthorized = "SdeappsErrorUnauthorized",
  Forbidden = "SdeappsErrorForbidden",
  SiteDisabled = "SdeappsErrorSiteDisabled",
  NotFound = "SdeappsErrorNotFound",
  Conflict = "SdeappsErrorConflict",
  ServerError = "SdeappsErrorServerError",
  Error = "SdeappsError",
}

/**
 * Classe représentant une erreur analysée et structurée par nos soins.
 *
 * Sert à distinguer les types d'erreurs dans notre gestion des erreurs.
 */
export class SdeappsError extends Error {
  status?: number;
  statusText?: string;
  description?: string;
  stack?: string;
  originalError?: Error | AxiosError;

  constructor(message?: string, errorOptions?: SdeappsErrorOptions) {
    super(message);
    this.name = ErrorNames.Error;
    this.originalError = errorOptions?.originalError;

    if (errorOptions?.stack != null) {
      this.stack = errorOptions?.stack;
    }
    this.status = errorOptions?.status;
    this.statusText = errorOptions?.statusText;
    this.description = errorOptions?.description;
  }
}

/**
 * Classe représentant une erreur **400 Bad Request** analysée et structurée par nos soins.
 *
 * Sert à distinguer les types d'erreurs dans notre gestion des erreurs.
 */
export class SdeappsErrorBadRequest extends SdeappsError {
  constructor(message?: string, errorOptions?: SdeappsErrorOptions) {
    super(message, { ...errorOptions, status: 400, statusText: "Bad Request" });
    this.name = ErrorNames.BadRequest;
  }
}

/**
 * Classe représentant une erreur **401 Unauthorized** analysée et structurée par nos soins.
 *
 * Sert à distinguer les types d'erreurs dans notre gestion des erreurs.
 */
export class SdeappsErrorUnauthorized extends SdeappsError {
  constructor(message?: string, errorOptions?: SdeappsErrorOptions) {
    super(message, { ...errorOptions, status: 401, statusText: "Unauthorized" });
    this.name = ErrorNames.Unauthorized;
  }
}

/**
 * Classe représentant une erreur **403 Forbidden** analysée et structurée par nos soins.
 *
 * Sert à distinguer les types d'erreurs dans notre gestion des erreurs.
 */
export class SdeappsErrorForbidden extends SdeappsError {
  constructor(message?: string, errorOptions?: SdeappsErrorOptions) {
    super(message, { ...errorOptions, status: 403, statusText: "Forbidden" });
    this.name = ErrorNames.Forbidden;
  }
}

/**
 * Classe représentant une erreur **403 Site Disabled**  analysée et structurée par nos soins.
 *
 * Sert à distinguer les types d'erreurs dans notre gestion des erreurs.
 */
export class SdeappsErrorSiteDisabled extends SdeappsError {
  constructor(message?: string, errorOptions?: SdeappsErrorOptions) {
    super(message, { ...errorOptions, status: 403, statusText: "Site Disabled" });
    this.name = ErrorNames.SiteDisabled;
  }
}

/**
 * Classe représentant une erreur **404 Not Found** analysée et structurée par nos soins.
 *
 * Sert à distinguer les types d'erreurs dans notre gestion des erreurs.
 */
export class SdeappsErrorNotFound extends SdeappsError {
  constructor(message?: string, errorOptions?: SdeappsErrorOptions) {
    super(message, { ...errorOptions, status: 404, statusText: "Not Found" });
    this.name = ErrorNames.NotFound;
  }
}

/**
 * Classe représentant une erreur **409 Conflict** analysée et structurée par nos soins.
 *
 * Sert à distinguer les types d'erreurs dans notre gestion des erreurs.
 */
export class SdeappsErrorConflict extends SdeappsError {
  constructor(message?: string, errorOptions?: SdeappsErrorOptions) {
    super(message, { ...errorOptions, status: 409, statusText: "Conflict" });
    this.name = ErrorNames.Conflict;
  }
}

/**
 * Classe représentant une erreur **500 Internal Server Error** analysée et structurée par nos soins.
 *
 * Sert à distinguer les types d'erreurs dans notre gestion des erreurs.
 */
export class SdeappsErrorServerError extends SdeappsError {
  constructor(message?: string, errorOptions?: SdeappsErrorOptions) {
    super(message, { ...errorOptions, status: 500, statusText: "Internal Server Error" });
    this.name = ErrorNames.ServerError;
  }
}
