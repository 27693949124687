import { type ReactElement } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  useTheme,
  useMediaQuery,
  Link,
  Typography,
} from "@mui/material";
import { envConfig, routesConfig } from "config/app-config";
import UserMenu from "./UserMenu";
import HeaderAvatar from "./HeaderAvatar";
import TitleImage from "assets/header-logo-title.svg";
import { useLocation } from "react-router-dom";
import { useContextualMenu } from "hooks";
import HeaderSearch from "./HeaderSearch";

function getEnvName(): string {
  const env = envConfig.name ?? "";
  if (env?.length > 0 && env?.toLocaleLowerCase() !== "prod") {
    return `${env.toLocaleUpperCase()}`;
  }
  return "";
}

type HeaderProps = { unAuthenticated?: boolean };

function Header({ unAuthenticated = false }: Readonly<HeaderProps>): ReactElement {
  const location = useLocation();
  const { isOpen, handleOpen, handleClose, menuAnchor } = useContextualMenu();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AppBar sx={{ mb: 4, position: "sticky", top: 0 }}>
      <Toolbar>
        {location.pathname !== routesConfig.home.path && (
          <Link href="/" sx={{ marginRight: 1 }}>
            <img
              src={TitleImage}
              alt="Collectivités"
              width={isSmallScreen ? "125px" : "184px"}
              style={{ display: "block" }}
            />
          </Link>
        )}
        {location.pathname !== routesConfig.home.path && <HeaderSearch />}
        <div style={{ flexGrow: 1 }} />
        <Typography
          color="white"
          sx={{
            position: "absolute",
            display: "inline-block",
            verticalAlign: "middle",
            right: "70px",
            zIndex: -1,
          }}>
          {getEnvName()}
        </Typography>
        <IconButton edge="end" onClick={handleOpen} color="inherit">
          {!unAuthenticated ? <HeaderAvatar /> : <Avatar />}
        </IconButton>
      </Toolbar>
      {!unAuthenticated && <UserMenu open={isOpen} anchorEl={menuAnchor} onClose={handleClose} />}
    </AppBar>
  );
}

export default Header;
