import { createSvgIcon } from "@mui/material";

export const PerimetreIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.9524 14.9568C6.95763 20.8113 3.80952 19.5859 0 14.2759C6.38095 18.6307 12.381 10.2881 17.0476 9.64658C22 8.96581 24 14.2759 24 14.2759C22.7619 12.521 18.8571 12.0974 14.9524 14.9568Z"
      fill="currentColor"
    />
    <path
      d="M14.7665 9.46181C9.42861 12.3092 6.19052 15.6013 1.33337 10.1551C8.95242 13.9415 11.3334 7.51881 16.0953 7.13531C20.576 6.77445 22.4762 10.8359 22.4762 10.8359C21.5239 9.46181 18.8234 7.29772 14.7665 9.46181Z"
      fill="currentColor"
    />
    <path
      d="M11.7143 6.89116C10.6667 7.53882 8.7619 8.66119 8 8.66119C9.14286 7.98041 10.9524 6.43402 12 5.80193C12.5714 5.45715 14.1905 4.22092 16.2857 4.1682C18.6694 4.10822 20.7619 5.80193 21.5238 7.02741C18.381 4.66573 15.2381 4.71268 11.7143 6.89116Z"
      fill="currentColor"
    />
  </svg>,
  "PerimetreIcon"
);

export const SdeaIcon = PerimetreIcon;
