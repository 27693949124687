import { createSvgIcon } from "@mui/material";

export const EauxPluvialesIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="5" fill="#D5A5E8" />
    <path
      d="M16.7465 9.59088L16.6555 10.0833H17.1562H17.2083C18.6882 10.0833 19.9167 11.3369 19.9167 12.9166C19.9167 14.4964 18.6882 15.75 17.2083 15.75H6.79167C5.31178 15.75 4.08333 14.4964 4.08333 12.9166C4.08333 11.6521 4.87218 10.591 5.94673 10.2234L6.28274 10.1084L6.22247 9.75843C6.187 9.55248 6.16667 9.33924 6.16667 9.12498C6.16667 7.24397 7.62623 5.74998 9.39583 5.74998C10.6296 5.74998 11.7094 6.47302 12.2529 7.55199L12.5398 8.12165L12.9462 7.63003C13.3516 7.13947 13.9467 6.83331 14.6042 6.83331C15.7976 6.83331 16.7917 7.84319 16.7917 9.12498C16.7917 9.28172 16.7753 9.43551 16.7465 9.59088Z"
      fill="white"
      stroke="white"
      strokeWidth="0.833333"
    />
    <path
      d="M12.1633 12.704L12.0076 12.5074L11.8429 12.6965C11.496 13.0947 11.1786 13.4528 10.8898 13.7789C10.1771 14.5831 9.63782 15.1918 9.2551 15.7241C8.70557 16.4882 8.45833 17.1212 8.45833 17.9813C8.45833 19.735 10.0706 21.1115 12 21.1115C13.9294 21.1115 15.5417 19.735 15.5417 17.9813C15.5417 17.5337 15.3951 17.0731 15.1696 16.6187C14.9434 16.1633 14.6317 15.7019 14.2869 15.2498C13.9418 14.7973 13.5593 14.3487 13.19 13.9204C13.144 13.8671 13.0983 13.8141 13.0529 13.7615C12.7316 13.3896 12.4258 13.0354 12.1633 12.704Z"
      fill="#D5A5E8"
      stroke="white"
      strokeWidth="0.416667"
    />
    <path
      d="M13.5248 17.697C13.3433 18.1649 12.734 18.814 11.879 19.1763"
      stroke="#F1F2F3"
      strokeWidth="0.416667"
      strokeLinecap="round"
    />
    <ellipse cx="13.686" cy="16.8583" rx="0.258164" ry="0.238095" fill="#F1F2F3" />
  </svg>,
  "EauxPluvialesIcon"
);
