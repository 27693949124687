import { Endpoints } from "constants/endpoints";
import type { Epci } from "models";
import { collectivitesApiClient } from "./apiClients";

/**
 * Récupère un EPCI en fonction de son numéro SIREN
 * @param siren Numéro SIREN
 * @returns un EPCI
 */
async function getById(siren: string): Promise<Epci> {
  const url = `/${Endpoints.EPCIS}/${siren}`;
  return collectivitesApiClient.get(url);
}

/**
 * Récupère tous les Epcis
 * @returns Array<Epcis>
 */
async function getAll(): Promise<Array<Epci>> {
  const url = `/${Endpoints.EPCIS}`;
  return collectivitesApiClient.get(url);
}

/**
 * Récupère les Epcis dont une commune fait partie
 * @param idCommune le code INSEE de la commune
 * @returns une liste d'Epcis
 */
async function getByCommune(idCommune: string): Promise<Array<Epci>> {
  const url = `/${Endpoints.COMMUNES}/${idCommune}/${Endpoints.EPCIS}`;
  return collectivitesApiClient.get(url);
}

export const epcisService = {
  getById,
  getAll,
  getByCommune,
};
