import { NotFoundErrorPage, ErrorPage } from "pages/errors";
import HomePage from "pages/Home/HomePage";
import Root from "components/Template/Root";
import { routesConfig } from "config/app-config";
import { createBrowserRouter } from "react-router-dom";
import EpciPageWithErrorBoundary from "pages/Epci/EpciPageWithErrorBoundary";
import CommuneAssocieeDelegueePageWithErrorBoundary from "pages/CommuneAssocieeDeleguee/CommuneAssocieeDelegueePage";
import TerritoirePageWithErrorBoundary from "pages/Territoire/TerritoirePageWithErrorBoundary";
import EluPageWithErrorBoundary from "pages/Elu/EluPageWithErrorBoundary";
import CommunePageWithErrorBoundary from "pages/Commune/CommunePageWithErrorBoundary";
import AdministrationPage from "pages/Administration/AdministrationPage";
import NewPerimetreWithErrorBoundary from "pages/NewPerimetre/NewPerimetreWithErrorBoundary";
import NewTransfertPageWithErrorBoundary from "pages/NewTransfert/NewTransfertPageWithErrorBoundary";
import PerimetrePageWithErrorBoundary from "pages/Perimetre/PerimetrePageWithErrorBoundary";
import PerimetreCommunesAssocieesDeleguesWithErrorBoundary from "pages/PerimetreCommunesAssocieesDelegues/PerimetreCommunesAssocieesDeleguesWithErrorBoundary";
import FusionPerimetresWithErrorBoundary from "pages/FusionPerimetres/FusionPerimetresWithErrorBoundary";
import PerimetreEditTransfertsWithErrorBoundary from "pages/PerimetreEditTransferts/PerimetreEditTransfertsWithErrorBoundary";
import ModifyPerimetreWithErrorBoundary from "pages/ModifyPerimetre/ModifyPerimetreWithErrorBoundary";
import DissoudrePerimetreWithErrorBoundary from "pages/DissoudrePerimetre/DissoudrePerimetreWithErrorBoundary";

const authenticatedRouter = createBrowserRouter([
  {
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: routesConfig.home.path,
        element: <HomePage />,
      },
      {
        path: routesConfig.commune.path,
        element: <CommunePageWithErrorBoundary />,
      },
      {
        path: routesConfig.communeAssocieeDeleguee.path,
        element: <CommuneAssocieeDelegueePageWithErrorBoundary />,
      },
      {
        path: routesConfig.epci.path,
        element: <EpciPageWithErrorBoundary />,
      },
      {
        path: routesConfig.territoire.path,
        element: <TerritoirePageWithErrorBoundary />,
      },
      {
        path: routesConfig.perimetre.path,
        element: <PerimetrePageWithErrorBoundary />,
      },
      {
        path: routesConfig.perimetreCommunesAssocieesDeleguees.path,
        element: <PerimetreCommunesAssocieesDeleguesWithErrorBoundary />,
      },
      {
        path: routesConfig.perimetreEditTransferts.path,
        element: <PerimetreEditTransfertsWithErrorBoundary />,
      },
      {
        path: routesConfig.perimetreModify.path,
        element: <ModifyPerimetreWithErrorBoundary />,
      },
      {
        path: routesConfig.elu.path,
        element: <EluPageWithErrorBoundary />,
      },
      {
        path: routesConfig.admin.path,
        element: <AdministrationPage />,
      },
      {
        path: routesConfig.newPerimetre.path,
        element: <NewPerimetreWithErrorBoundary />,
      },
      {
        path: routesConfig.newTransferts.path,
        element: <NewTransfertPageWithErrorBoundary />,
      },
      {
        path: routesConfig.fusionPerimetres.path,
        element: <FusionPerimetresWithErrorBoundary />,
      },
      {
        path: routesConfig.dissoudrePerimetre.path,
        element: <DissoudrePerimetreWithErrorBoundary />,
      },
      {
        path: routesConfig.any.path,
        element: <NotFoundErrorPage />,
      },
    ],
  },
]);

export default authenticatedRouter;
