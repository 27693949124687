import { createSvgIcon } from "@mui/material";

export const CollectiviteIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.7,17.6V8.9L9.3,3"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinejoin="round"
      d="M21.3,10.3v11H4.8v-11L13,3L21.3,10.3z"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth="1.3"
      d="M4.8,12.1h16.5 M8,7.6v13.7 M18.1,7.6v13.7 M11.2,15.8h3.7v5.5h-3.7V15.8z"
    />
  </svg>,
  "CollectiviteIcon"
);
