import { Checkbox, FormControlLabel } from "@mui/material";
import { type ReactNode } from "react";

interface CheckboxForArrayProps {
  value: Array<string>;
  onChange: (a: Array<string>) => void;
  codes: string | Array<string>;
  label?: string;
  disabled?: boolean;
}

/**
 * Checkbox qui permet de gérer la présence d'une ou plusieurs string dans un tableau référence `value`.
 * La prop `codes` prend en paramètre soit une string, soit un tableau de string.
 *
 * Si `codes` est une string :
 *  - la checkbox est checkée si le tableau référence contient la string `codes`
 *  - checker la checkbox ajoutera la string `codes` dans le tableau référence
 *  - déchecker la checkbox enlèvera la string `codes` du le tableau référence
 *
 * Si `codes` est un tableau de string :
 *  - la checkbox est checkée si une des string contenue dans `codes` est contenue dans le tableau de référence
 *  - checker la checkbox ajoutera toutes les string contenues dans `codes` au tableau référence
 *  - déchecker la checkbox enlèvera toutes les string contenues dans `codes` du tableau référence
 *
 * @param value le tableau référence
 * @param onChange une fonction qui permet de modifier le tableau référence
 * @param codes la string ou le tableau de string que l'on ajoute ou enlève au tableau de référence
 * @param label le label de la checkbox
 */
function CheckboxForArray({
  value,
  onChange,
  codes,
  label,
  disabled = false,
}: Readonly<CheckboxForArrayProps>): ReactNode {
  function addPortee(newPorteeCode: string): void {
    onChange([...value, newPorteeCode]);
  }

  function removePortee(porteeCode: string): void {
    const portees = [...value];
    const index = portees.indexOf(porteeCode);
    if (index > -1) {
      portees.splice(index, 1);
    }
    onChange(portees);
  }

  function addPortees(newPorteeCodes: Array<string>): void {
    onChange([...value, ...newPorteeCodes]);
  }

  function removePortees(porteeCodes: Array<string>): void {
    const portees = [...value];

    porteeCodes.forEach((porteeCode) => {
      const index = portees.indexOf(porteeCode);
      if (index > -1) {
        portees.splice(index, 1);
      }
    });
    onChange(portees);
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={
            Array.isArray(codes)
              ? codes.some((truc) => value?.includes(truc))
              : value?.includes(codes)
          }
          onChange={(_, checked) => {
            if (checked) {
              Array.isArray(codes) ? addPortees(codes) : addPortee(codes);
            } else {
              Array.isArray(codes) ? removePortees(codes) : removePortee(codes);
            }
          }}
          disabled={disabled}
        />
      }
      label={label}
    />
  );
}

export default CheckboxForArray;
