import { useState } from "react";
import type { ReactNode } from "react";
import { CircularProgress, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import { Authorization, dateUtil } from "@sdeapps/react-core";
import CommuneLink from "components/Links/CommuneLink";
import PerimetreLinkWithErrorBoundary from "components/Links/PerimetreLink";
import TerritoireLink from "components/Links/TerritoireLink";
import CompetenceChip from "components/Typography/CompetenceChip";
import { ApplicationRole } from "constants/ApplicationRole";
import type { Mandat } from "models";
import { elusUtil, timeUtil } from "utils";
import { useErrorHandler } from "utils/errorHandling";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import ControlledDateTime from "components/inputs/ControlledDateTime";
import { useForm } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import ToastMessages from "constants/ToastMessages";
import { useNavigate } from "react-router-dom";
import { mandatsService } from "services";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteMandatDialog from "./DeleteMandatDialog";

interface MandatSdeaTableRowProps {
  mandatSdea: Mandat;
}

export interface MandatSdeaClotureModel {
  dateFin: Date;
}

function MandatSdeaTableRow({ mandatSdea }: Readonly<MandatSdeaTableRowProps>): ReactNode {
  const [isCloturing, setIsCloturing] = useState(false);
  const [isDeleteDialogMandatOpen, setIsDeleteDialogMandatOpen] = useState(false);

  const navigate = useNavigate();

  const { handleSubmit, control, reset } = useForm<MandatSdeaClotureModel>();
  const { catchErrors, isLoading: isSending } = useErrorHandler({
    dontThrow: true,
    defaultIsLoading: false,
    default: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR_RETRY,
      });
    },
  });

  function openCloturingInterface(): void {
    setIsCloturing(true);
  }

  function closeCloturingInterface(): void {
    setIsCloturing(false);
    reset();
  }

  const clotureMandat: SubmitHandler<MandatSdeaClotureModel> = async function (
    data: MandatSdeaClotureModel
  ): Promise<void> {
    async function sendClotureMandat(): Promise<void> {
      await mandatsService.cloturer(data.dateFin, mandatSdea.id);

      enqueueSnackbar({
        variant: "success",
        message: ToastMessages.CLOTURED_MANDAT,
      });

      await timeUtil.sleep(1000);
      navigate(0);
    }

    await catchErrors(sendClotureMandat);
  };

  function openDeleteMandatDialog(): void {
    setIsDeleteDialogMandatOpen(true);
  }

  function closeDeleteMandatDialog(): void {
    setIsDeleteDialogMandatOpen(false);
  }

  return (
    <TableRow>
      <TableCell>
        <Typography>{elusUtil.getLibelleFonction(mandatSdea)}</Typography>
      </TableCell>
      <TableCell>
        {mandatSdea.competence != null && <CompetenceChip competence={mandatSdea.competence} />}
      </TableCell>
      <TableCell>
        {mandatSdea.idTerritoire != null && (
          <TerritoireLink
            sx={{ display: "block" }}
            id={mandatSdea.idTerritoire}
            libelle={mandatSdea.libelleTerritoire}
          />
        )}
        {mandatSdea.idPerimetre != null && mandatSdea.competence != null && (
          <PerimetreLinkWithErrorBoundary
            sx={{ display: "block" }}
            id={mandatSdea.idPerimetre}
            libelle={mandatSdea.libellePerimetre}
          />
        )}
        {mandatSdea.codeCollectivite != null && (
          <CommuneLink sx={{ display: "block" }} id={mandatSdea.codeCollectivite} />
        )}
      </TableCell>
      <TableCell width="250px">
        <Typography>
          À partir du{" "}
          {mandatSdea.dateDebutMandat != null
            ? dateUtil.format(mandatSdea.dateDebutMandat, "dd/MM/yyyy")
            : "???"}
          {mandatSdea.dateFinMandat != null &&
            ` - jusqu'au ${dateUtil.format(mandatSdea.dateFinMandat, "dd/MM/yyyy")}`}
          {mandatSdea.dateFinMandat == null && isCloturing && (
            <ControlledDateTime
              name="dateFin"
              label="Fin du mandat *"
              control={control}
              size="small"
              sx={mandatSdea.dateDebutMandat != null ? { marginTop: 1 } : undefined}
              rules={{
                required: "Choisir une date de fin de mandat est obligatoire pour le clôturer",
                validate: (value: Date | null) =>
                  dateUtil.isValid(value) || "Veuillez renseigner une date valide",
              }}
            />
          )}
        </Typography>
      </TableCell>

      <Authorization roles={ApplicationRole.ADMINISTRATOR}>
        <TableCell width="120px">
          {mandatSdea.dateFinMandat == null && (
            <>
              {isCloturing &&
                (isSending ? (
                  <CircularProgress />
                ) : (
                  <IconButton
                    onClick={() => {
                      void handleSubmit(clotureMandat)();
                    }}
                    color="primary"
                    title="Enregistrer">
                    <SaveIcon />
                  </IconButton>
                ))}

              {isCloturing && (
                <IconButton onClick={closeCloturingInterface} color="error" title="Annuler">
                  <ClearIcon />
                </IconButton>
              )}

              {!isCloturing && (
                <IconButton onClick={openCloturingInterface} title="Clôturer">
                  <EditIcon />
                </IconButton>
              )}
            </>
          )}

          {!isCloturing && (
            <IconButton onClick={openDeleteMandatDialog} color="error" title="Supprimer">
              <DeleteIcon />
            </IconButton>
          )}
        </TableCell>
        <DeleteMandatDialog
          open={isDeleteDialogMandatOpen}
          handleClose={closeDeleteMandatDialog}
          mandatSdea={mandatSdea}
        />
      </Authorization>
    </TableRow>
  );
}

export default MandatSdeaTableRow;
