import { Container, Grid } from "@mui/material";
import { Authorization } from "@sdeapps/react-core";
import { routesConfig } from "config/app-config";
import { ApplicationRole } from "constants/ApplicationRole";
import type { ReactNode } from "react";
import AgExportButton from "./components/AgExportButton";
import {
  etatPrefectureCollectivites,
  etatPrefectureCommunesIsolees,
  etatPrefectureConseilGeneral,
} from "constants/EtatPrefecture";
import PageTitle from "components/PageTitle";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import { AdministrationArea } from "./components/AdministrationArea";
import EtatPrefectureButton from "./components/EtatPrefectureButton";
import { NavigationButton } from "./components/NavigationButton";
import { SdeaIcon } from "icons";

function AdministrationPage(): ReactNode {
  return (
    <Authorization roles={ApplicationRole.ADMINISTRATOR}>
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <PageTitle title="Administration" />

          <AdministrationArea
            title="Export"
            icon={<PermContactCalendarOutlinedIcon />}
            items={[<AgExportButton key="agExportButton" />]}
          />

          <AdministrationArea
            title="Nouvelles données"
            icon={<AddBusinessOutlinedIcon />}
            childrenSpacing={1}
            items={[
              <NavigationButton
                key="nouveau-perimetre"
                libelle="Nouveau périmètre SDEA"
                href={routesConfig.newPerimetre.path}
              />,
              <NavigationButton
                key="nouveau-transfert"
                libelle="Nouveau Transfert"
                href={routesConfig.newTransferts.path}
              />,
            ]}
          />

          <AdministrationArea
            title="Périmètres"
            icon={<SdeaIcon />}
            childrenSpacing={1}
            items={[
              <NavigationButton
                key="fusion-perimetres"
                libelle="Fusionner des périmètres"
                href={routesConfig.fusionPerimetres.path}
                color="secondary"
              />,
              <NavigationButton
                key="dissoudre-perimetre"
                libelle="Dissoudre un périmètre"
                href={routesConfig.dissoudrePerimetre.path}
                color="secondary"
              />,
            ]}
          />

          <AdministrationArea
            title="États préfecture"
            icon={<AutoStoriesOutlinedIcon />}
            items={etatPrefectureCommunesIsolees.map(({ type, libelle }) => (
              <EtatPrefectureButton key={type} type={type} libelle={libelle} />
            ))}
          />
          <AdministrationArea
            items={etatPrefectureCollectivites.map(({ type, libelle }) => (
              <EtatPrefectureButton key={type} type={type} libelle={libelle} />
            ))}
          />
          <AdministrationArea
            items={etatPrefectureConseilGeneral.map(({ type, libelle }) => (
              <EtatPrefectureButton key={type} type={type} libelle={libelle} />
            ))}
          />
        </Grid>
      </Container>
    </Authorization>
  );
}

export default AdministrationPage;
