import { useEffect, useState, useCallback } from "react";
import type { ReactNode } from "react";
import { Link, Typography } from "@mui/material";
import type { LinkProps } from "@mui/material";
import { routesConfig } from "config/app-config";
import type { Epci } from "models";
import { epcisUtil } from "utils";
import { useErrorHandler } from "utils/errorHandling";
import { epcisService } from "services";
import TypographySkeleton from "./TypographySkeleton";

type EpciLinkData1 = {
  epci?: never;
  id: string;
  libelle?: string;
};

type EpciLinkData2 = {
  epci: Epci;
  id?: never;
  libelle?: never;
};

type EpciLinkCommonProps = {
  noLink?: boolean;
};

type EpciLinkProps = LinkProps & EpciLinkCommonProps & (EpciLinkData1 | EpciLinkData2);

function EpciLink({
  epci,
  id,
  libelle,
  noLink = false,
  ...linkProps
}: Readonly<EpciLinkProps>): ReactNode {
  const [libelleEpci, setLibelleEpci] = useState("");
  const [link, setLink] = useState("");

  const { catchErrors, isLoading, setIsLoading } = useErrorHandler({
    dontThrow: true,
    default: () => {
      setLibelleAndLink("EPCI inconnu", id ?? "");
    },
  });

  const setLibelleAndLink = useCallback(
    (_libelle: string, _id: string): void => {
      setLibelleEpci(epcisUtil.ToFullName(_libelle));
      setLink(routesConfig.epci.getParameterPath(_id));
      setIsLoading(false);
    },
    [setIsLoading]
  );

  useEffect(() => {
    async function getEpci(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const _epci = await epcisService.getById(id!);
      setLibelleAndLink(_epci.libelle, _epci.id);
    }

    if (epci != null) {
      setLibelleAndLink(epci.libelle, epci.id);
    } else if (libelle != null) {
      setLibelleAndLink(libelle, id);
    } else {
      void catchErrors(getEpci);
    }
  }, [catchErrors, epci, id, libelle, setLibelleAndLink]);

  if (isLoading) {
    return <TypographySkeleton sx={{ maxWidth: 300 }} />;
  }

  if (noLink) {
    return (
      <Typography component="span" color="primary" fontWeight={500}>
        {libelleEpci}
      </Typography>
    );
  } else {
    return (
      <Link href={link} {...linkProps}>
        {libelleEpci}
      </Link>
    );
  }
}

export default EpciLink;
