import ConnectionPage from "pages/ConnectionPage";
import { routesConfig } from "config/app-config";
import { createBrowserRouter } from "react-router-dom";
import { ErrorPage } from "pages/errors";
import UnauthenticatedRoot from "components/Template/UnauthenticatedRoot";

const unAuthenticatedRouter = createBrowserRouter([
  {
    element: <UnauthenticatedRoot />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: routesConfig.any.path,
        element: <ConnectionPage />,
      },
    ],
  },
]);

export default unAuthenticatedRouter;
