import { type ReactElement } from "react";
import { Typography } from "@mui/material";

interface InlineErrorChipProps {
  text?: string;
}

function InlineErrorChip({ text = "ERREUR" }: Readonly<InlineErrorChipProps>): ReactElement {
  return (
    <Typography
      variant="body2"
      component="span"
      color="error.main"
      sx={{
        backgroundColor: "error.light",
        paddingX: 1,
        paddingY: 0.5,
        fontWeight: 600,
        borderRadius: 1,
        display: "inline-block",
      }}>
      {text}
    </Typography>
  );
}

export default InlineErrorChip;
