import { createSvgIcon } from "@mui/material";

export const CommuneIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.25 10.1666V21.1666H3.75V10.1666L12 2.83331L20.25 10.1666Z"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinejoin="round"
    />
    <path
      d="M3.75 12.0001H20.25M6.95833 7.41673V21.1667M17.0417 7.41673V21.1667M10.1667 15.6667H13.8333V21.1667H10.1667V15.6667Z"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  "CommuneIcon"
);
