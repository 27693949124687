import { Endpoints } from "constants/endpoints";
import type { Territoire } from "models";
import { collectivitesApiClient } from "./apiClients";

/**
 * Récupère tous les territoires
 * @param code code de la région
 * @returns tous les Territoires
 */
async function getAll(): Promise<Array<Territoire>> {
  const url = `/${Endpoints.TERRITOIRES}`;
  return collectivitesApiClient.get(url);
}

/**
 * Récupère un territoire en fonction de son id
 * @param id id du territoire
 * @returns un Territoire
 */
async function getById(id: string): Promise<Territoire> {
  const url = `/${Endpoints.TERRITOIRES}/${id}`;
  return collectivitesApiClient.get(url);
}

export const territoiresService = {
  getById,
  getAll,
};
