import Competence from "constants/Competence";
import competenceMap, { competencesAssociationList } from "constants/CompetenceMap";
import GceContrats from "constants/GceContrats";
import type { Mandat, Perimetre, Transfert, TransfertViewModel } from "models";
import { porteesUtil } from "utils";

function getSortAnythingByCompetence<T>(
  f: (t: T) => Competence = (t) => t as Competence
): (a: T, b: T) => number {
  return (a, b) => (competenceMap.get(f(a))?.index ?? 0) - (competenceMap.get(f(b))?.index ?? 0);
}

function sortCompetences(a: Competence, b: Competence): number {
  return getSortAnythingByCompetence()(a, b);
}

function sortTransfertViewModelByCompetence(a: TransfertViewModel, b: TransfertViewModel): number {
  return getSortAnythingByCompetence(
    (transfert: TransfertViewModel) => transfert.perimetre.competence
  )(a, b);
}

function sortTransfertByCompetence(a: Transfert, b: Transfert): number {
  return getSortAnythingByCompetence(
    (transfert: Transfert) => transfert.code.substring(0, 3) as Competence
  )(a, b);
}

function sortPerimetresByCompetence(a: Perimetre, b: Perimetre): number {
  return getSortAnythingByCompetence((perimetre: Perimetre) => perimetre.competence)(a, b);
}

function sortGceTransferts(a: Transfert, b: Transfert): number {
  return (
    (GceContrats.find((c) => c.code === porteesUtil.getSousCompetenceId(a))?.ordre ?? 0) -
    (GceContrats.find((c) => c.code === porteesUtil.getSousCompetenceId(b))?.ordre ?? 0)
  );
}

function sortMandatsByCompetence<T extends Mandat>(a: T, b: T): number {
  return getSortAnythingByCompetence((m: T) => m.competence ?? Competence.GEPU)(a, b);
}

function getCompetencesFromTransferts(transferts: Array<Transfert>): Array<Competence> {
  const competences: Array<Competence> = [];
  competencesAssociationList.forEach(({ competence: c }) => {
    if (transferts.some((t) => t.code.includes(c.toString()))) {
      competences.push(c);
    }
  });
  return competences;
}

function getCompetencesFromPerimetres(perimetres: Array<Perimetre>): Array<Competence> {
  const competences: Array<Competence> = [];
  competencesAssociationList.forEach(({ competence: c }) => {
    if (perimetres.some((p) => p.competence === c)) {
      competences.push(c);
    }
  });
  return competences;
}

export const competencesUtil = {
  sortCompetences,
  sortTransfertViewModelByCompetence,
  sortTransfertByCompetence,
  sortPerimetresByCompetence,
  sortGceTransferts,
  getCompetencesFromTransferts,
  getCompetencesFromPerimetres,
  sortMandatsByCompetence,
};
