import { useState, useEffect } from "react";
import type { ReactNode } from "react";
import { Grid, MenuItem, Button } from "@mui/material";
import type { ModifyPerimetreModelForm, Perimetre, Territoire, PatchData } from "models";
import { territoiresService, perimetresService } from "services";
import { DATE_FORMAT_FOR_API_REQUEST, patchUtils, timeUtil } from "utils";
import { useErrorHandler, ErrorNames } from "utils/errorHandling";
import LoadingScreen from "components/Template/LoadingScreen";
import { useForm, useWatch } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import { dateUtil } from "@sdeapps/react-core";
import ControlledTextField from "components/inputs/ControlledTextField";
import ControlledDateTime from "components/inputs/ControlledDateTime";
import LoadingButton from "components/LoadingButton";
import ToastMessages from "constants/ToastMessages";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useSearch } from "providers";
import { routesConfig } from "config/app-config";

const DATE_FORMAT = "dd/MM/yyyy";

function getFormattedDateStringOrNull(date?: string | Date | null): string | null {
  if (date != null) {
    return dateUtil.format(date, DATE_FORMAT);
  }

  return null;
}

/* async function isModifiedPerimetreReplicated(
  modifiedPerimetre: ModifyPerimetreModelForm
): Promise<boolean> {
  const distantPerimetre = await perimetresService.getById(modifiedPerimetre.id);
  console.log("modifiedPerimetre", modifiedPerimetre);
  console.log("distantPerimetre", distantPerimetre);

  let allOk: boolean =
    modifiedPerimetre.libelle === distantPerimetre.libelle &&
    modifiedPerimetre.idTerritoire === distantPerimetre.territoire.id;

  allOk = allOk && true;

  return (
    dateUtil.format(modifiedPerimetre.dateMembre, DATE_FORMAT) ===
      dateUtil.format(distantPerimetre.dateMembre, DATE_FORMAT) &&
    dateUtil.format(modifiedPerimetre.dateTransfertComplet, DATE_FORMAT) ===
      dateUtil.format(distantPerimetre.dateTransfertComplet, DATE_FORMAT) &&
    modifiedPerimetre.libelle === distantPerimetre.libelle &&
    modifiedPerimetre.idTerritoire === distantPerimetre.territoire.id
  );
} */

function toModifyPerimetreModelForm(perimetre: Perimetre): ModifyPerimetreModelForm {
  return {
    id: perimetre.id,
    idTerritoire: perimetre.territoire.id,
    libelle: perimetre.libelle,
    dateMembre: perimetre.dateMembre != null ? dateUtil.getDate(perimetre.dateMembre) : null,
    dateTransfertComplet:
      perimetre.dateTransfertComplet != null
        ? dateUtil.getDate(perimetre.dateTransfertComplet)
        : null,
  };
}

function getOnlyModifiedPropsPatchData(
  perimetre: Partial<ModifyPerimetreModelForm>,
  perimetreInitial: Perimetre
): Array<PatchData> {
  const toReplaceProps: Array<[string, unknown]> = [];
  const toRemoveProps: Array<string> = [];

  if (perimetre.libelle !== perimetreInitial.libelle) {
    toReplaceProps.push(["/libelle", perimetre.libelle]);
  }

  if (
    dateUtil.isValid(perimetre.dateMembre) &&
    getFormattedDateStringOrNull(perimetre.dateMembre) !==
      getFormattedDateStringOrNull(perimetreInitial.dateMembre)
  ) {
    if (perimetre.dateMembre == null) {
      toRemoveProps.push("/dateMembre");
    } else {
      toReplaceProps.push([
        "/dateMembre",
        dateUtil.format(perimetre.dateMembre, DATE_FORMAT_FOR_API_REQUEST),
      ]);
    }
  }

  if (
    dateUtil.isValid(perimetre.dateTransfertComplet) &&
    getFormattedDateStringOrNull(perimetre.dateTransfertComplet) !==
      getFormattedDateStringOrNull(perimetreInitial.dateTransfertComplet)
  ) {
    if (perimetre.dateTransfertComplet == null) {
      toRemoveProps.push("/dateTransfertComplet");
    } else {
      toReplaceProps.push([
        "/dateTransfertComplet",
        dateUtil.format(perimetre.dateTransfertComplet, DATE_FORMAT_FOR_API_REQUEST),
      ]);
    }
  }

  if (perimetre.idTerritoire !== perimetreInitial.territoire.id) {
    toReplaceProps.push(["/idTerritoire", perimetre.idTerritoire]);
  }

  const patchData: Array<PatchData> = [];
  patchUtils.addReplaceToPatchData(toReplaceProps, patchData);
  patchUtils.addRemoveToPatchData(toRemoveProps, patchData);

  return patchData;
}

interface PerimetreFormProps {
  perimetre: Perimetre;
}

function PerimetreForm({ perimetre: perimetreInitial }: Readonly<PerimetreFormProps>): ReactNode {
  const [territoires, setTerritoires] = useState<Array<Territoire>>([]);
  const [modifiedPatchData, setModifiedPatchData] = useState<Array<PatchData>>([]);
  const navigate = useNavigate();
  const { update } = useSearch();

  const { catchErrors, isLoading } = useErrorHandler();
  const { catchErrors: catchCreateErrors, isLoading: isSending } = useErrorHandler({
    dontThrow: true,
    defaultIsLoading: false,
    [ErrorNames.BadRequest]: (error) => {
      // Le périmètre n'a pas été modifié à cause d'une faute dans la requête, on précise
      // laquelle à l'utilisateur
      enqueueSnackbar({
        variant: "error",
        message: error.message,
      });
    },
    default: () => {
      // Le périmètre n'a pas été modifié, on affiche une snackbar d'erreur à l'utilisateur
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR_RETRY,
      });
    },
  });

  const { handleSubmit, control } = useForm<ModifyPerimetreModelForm>({
    shouldFocusError: false,
    defaultValues: toModifyPerimetreModelForm(perimetreInitial),
  });
  const perimetre = useWatch({ control });

  useEffect(() => {
    async function getTerritoires(): Promise<void> {
      const _territoires = await territoiresService.getAll();
      setTerritoires(_territoires);
    }

    void catchErrors(getTerritoires);
  }, [catchErrors]);

  useEffect(() => {
    setModifiedPatchData(getOnlyModifiedPropsPatchData(perimetre, perimetreInitial));
  }, [perimetre, perimetreInitial]);

  const submitPerimetre: SubmitHandler<ModifyPerimetreModelForm> = async function (
    data: ModifyPerimetreModelForm
  ): Promise<void> {
    async function modifyPerimetre(): Promise<void> {
      await perimetresService.modify(
        modifiedPatchData,
        perimetreInitial.territoire.id,
        perimetreInitial.id
      );
      enqueueSnackbar({
        variant: "success",
        message: ToastMessages.MODIFIED_PERIMETRE,
      });

      /**
       * On attend que la réplication des données soit terminée pour mettre à jour
       * toutes les listes de la recherche. Pour l'instant, on fake en attendant un temps défini.
       */
      await timeUtil.sleep(5000);
      update();
      navigate(routesConfig.perimetre.getParameterPath(data.id));
      /* await networkService.waitForReplication(
        async () => {
          if (!(await isModifiedPerimetreReplicated(data))) {
            throw new SdeappsError("Modification pas encore répliquée");
          }
        },
        20,
        update,
        () => {
          enqueueSnackbar({
            variant: "warning",
            message: ToastMessages.LONG_REPLICATION,
          });
          update();
        } 
      ); */
    }

    await catchCreateErrors(modifyPerimetre);
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <Grid component="form" onSubmit={handleSubmit(submitPerimetre)} item container spacing={4}>
      <Grid item container xs={12} spacing={4}>
        <Grid item xs={12}>
          <ControlledTextField
            name="libelle"
            control={control}
            defaultValue=""
            rules={{ required: "Saisir un nom est obligatoire" }}
            fullWidth
            label="Désignation du périmètre *"
            variant="outlined"
            placeholder="Commission locale de ..."
          />
        </Grid>
        <Grid container item xs={12} sm={6}>
          <ControlledDateTime
            name="dateMembre"
            control={control}
            rules={{
              validate: (value: Date | null | undefined) =>
                dateUtil.isValid(value) || "Veuillez renseigner une date valide",
            }}
            label="Date de création"
          />
        </Grid>
        <Grid container item xs={12} sm={6}>
          <ControlledDateTime
            name="dateTransfertComplet"
            control={control}
            rules={{
              validate: (value: Date | null | undefined) =>
                dateUtil.isValid(value) || "Veuillez renseigner une date valide",
            }}
            label="Date de transfert complet"
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            select
            name="idTerritoire"
            control={control}
            rules={{ required: "Choisir un territoire est obligatoire" }}
            label="Territoire de rattachement *">
            {territoires.map((t) => (
              <MenuItem key={t.id} value={t.id}>
                {t.libelle}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
      </Grid>
      <Grid container item xs={12} gap={2}>
        <Button
          onClick={() => {
            navigate(-1);
          }}
          color="error">
          Annuler
        </Button>
        <LoadingButton
          variant="contained"
          loading={isSending}
          disabled={modifiedPatchData.length === 0}>
          Enregistrer
        </LoadingButton>
      </Grid>
    </Grid>
  );
}

export default PerimetreForm;
