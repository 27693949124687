import { Avatar, Box, Grid, Typography } from "@mui/material";
import type { ReactElement, ReactNode } from "react";

interface AdministrationAreaProps {
  title?: string;
  icon?: string | ReactElement;
  items?: Array<ReactElement>;
  childrenSpacing?: number;
}
export function AdministrationArea({
  icon,
  title,
  items,
  childrenSpacing,
}: Readonly<AdministrationAreaProps>): ReactNode {
  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <Typography
        variant="h3"
        sx={{ minHeight: "48px", marginBottom: 1, display: "flex", alignItems: "center" }}>
        {icon != null && <Avatar sx={{ mr: 1 }}>{icon}</Avatar>}
        {title ?? <>&nbsp;</>}
      </Typography>
      {items?.map((child) => (
        <Box key={child.key} mb={childrenSpacing}>
          {child}
        </Box>
      ))}
    </Grid>
  );
}
