import { Grid } from "@mui/material";
import { useEffect, type ReactNode, useCallback } from "react";
import CheckboxForArray from "./CheckboxForArray";

interface NewTransfertPorteesGepuProps {
  value: Array<string>;
  onChange: (a: Array<string>) => void;
}

const porteesGepu = [
  {
    code: "GEPU-0-0",
    libelle: "Collecte, transport, stockage et traitement des eaux pluviales des aires urbaines.",
  },
];

function NewTransfertPorteesGepu({
  value,
  onChange,
}: Readonly<NewTransfertPorteesGepuProps>): ReactNode {
  const resetAllPortees = useCallback((): void => {
    onChange([]);
  }, [onChange]);

  useEffect(() => {
    resetAllPortees();
  }, [onChange, resetAllPortees]);

  return (
    <Grid container item xs={12}>
      {porteesGepu.map((sousCompetence) => {
        return (
          <Grid container item key={sousCompetence.libelle} xs={12} alignItems="center">
            <Grid item xs={12} alignItems="center">
              <CheckboxForArray
                value={value}
                onChange={onChange}
                codes={sousCompetence.code}
                label={sousCompetence.libelle}
              />
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default NewTransfertPorteesGepu;
