import type { ReactNode } from "react";
import { Grid, Typography } from "@mui/material";
import type { GridProps } from "@mui/material";
import HasCompetenceIcon from "./HasCompetenceIcon";
import { porteesUtil } from "utils";
import type { Transfert } from "models";
import { grey } from "@mui/material/colors";

interface PorteeAccordionDetailWithIconProps extends GridProps {
  isPorteeAdheree: boolean;
  transfert: string | Transfert;
}

function PorteeAccordionDetailWithIcon({
  isPorteeAdheree,
  transfert,
  ...gridProps
}: Readonly<PorteeAccordionDetailWithIconProps>): ReactNode {
  return (
    <Grid container alignItems="center" spacing={1} {...gridProps}>
      <Grid item xs="auto">
        <HasCompetenceIcon checked={isPorteeAdheree} />
      </Grid>
      <Grid item xs>
        <Typography color={isPorteeAdheree ? undefined : grey[400]} component="span">
          {porteesUtil.getPorteeLabel(transfert)}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PorteeAccordionDetailWithIcon;
