import { type PropsWithChildren, type ReactNode } from "react";
import { ApplicationRole } from "constants/ApplicationRole";
import { MenuItem } from "@mui/material";
import InformationBox from "components/InformationBox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";

interface CompetencesEtPorteesInformationBoxProps extends PropsWithChildren {
  additionalMenuItems?: ReactNode;
  additionalRightStuff?: ReactNode;
  openModal: () => void;
}

function EtablissementPublicInformationBox({
  children,
  additionalMenuItems,
  additionalRightStuff,
  openModal,
}: Readonly<CompetencesEtPorteesInformationBoxProps>): ReactNode {
  return (
    <InformationBox
      hasBackground
      icon={VisibilityIcon}
      articleTitle="Informations générales"
      subTitle={additionalRightStuff}
      actionsRoles={ApplicationRole.ETABLISSEMENT_PUBLIC_WRITE_ALL}
      actions={[
        <MenuItem key="correction-coordonnees" onClick={openModal}>
          <EditIcon color="primary" sx={{ mr: 1 }} />
          Corriger les coordonnées
        </MenuItem>,
        additionalMenuItems,
      ]}>
      {children}
    </InformationBox>
  );
}

export default EtablissementPublicInformationBox;
