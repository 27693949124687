import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableBodyClasses,
  tableCellClasses,
  tableContainerClasses,
  tableRowClasses,
} from "@mui/material";
import type { Perimetre, Personne, Mandat, Commune } from "models";
import { communesService, elusService } from "services";
import PerimetreDeleguesSdeaSkeleton from "./PerimetreDeleguesSdeaSkeleton";
import PerimetreDeleguesSdeaRow from "./PerimetreDeleguesSdeaRow";
import Fonctions from "constants/Fonctions";
import { elusUtil, excelUtil } from "utils";
import { useErrorHandler, withComponentErrorBoundary } from "utils/errorHandling";
import { Authorization } from "@sdeapps/react-core";
import { ApplicationRole } from "constants/ApplicationRole";
import { enqueueSnackbar } from "notistack";
import ToastMessages from "constants/ToastMessages";
import LoadingButton from "components/LoadingButton";

interface PerimetreDeleguesSdeaProps {
  perimetre: Perimetre;
}

function PerimetreDeleguesSdea({ perimetre }: Readonly<PerimetreDeleguesSdeaProps>): ReactNode {
  const [elus, setElus] = useState<Array<Personne>>([]);
  const [communes, setCommunes] = useState<Array<Commune>>([]);

  const { catchErrors: catchElusErrors, isLoading: isElusLoading } = useErrorHandler();
  const { catchErrors: catchCommunesErrors } = useErrorHandler();
  const { catchErrors: catchExcelErrors, isLoading: isExcelLoading } = useErrorHandler({
    dontThrow: true,
    defaultIsLoading: false,
    default: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR_RETRY,
      });
    },
  });

  useEffect(() => {
    async function getElus(): Promise<void> {
      setElus([]);
      const _perimetreElus = await elusService.getPersonnesByPerimetre(perimetre);

      const _elus: Array<Personne> = elusUtil.filterAndBoilElusMandatsDown(
        _perimetreElus,
        (m: Mandat): boolean => {
          return (
            [
              Fonctions.PRESIDENT_COMMISSION_LOCALE,
              Fonctions.SUPPLEANT_PRESIDENT_COMMISSION_LOCALE,
              Fonctions.DELEGUE_ASSEMBLEE_GENERALE,
            ].includes(m.idFonction as Fonctions) &&
            m.idPerimetre === perimetre.id &&
            m.competence === perimetre.competence
          );
        }
      );

      _elus.sort(elusUtil.sortElusByMandatImportance);
      const activeElus = elusUtil.getActiveElusAndMandats(_elus);

      setElus(activeElus);
    }

    async function getCommunes(): Promise<void> {
      setCommunes([]);
      const _communes = await communesService.getByPerimetre(perimetre.id);
      setCommunes(_communes);
    }

    void catchElusErrors(getElus);
    void catchCommunesErrors(getCommunes);
  }, [catchElusErrors, catchCommunesErrors, perimetre]);

  function createFeuillePresence(): void {
    async function create(): Promise<void> {
      await excelUtil.createFeuillePresencePerimetre(perimetre, elus, communes);
    }

    void catchExcelErrors(create);
  }

  if (isElusLoading) {
    return <PerimetreDeleguesSdeaSkeleton />;
  }

  if (elus.length === 0) {
    return (
      <Grid item xs={12}>
        <Typography>Ce périmètre SDEA n'a aucun délégué.</Typography>
      </Grid>
    );
  }

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={12}>
        <Typography>
          <Typography component="span" fontWeight="600">
            {elus.length}
          </Typography>{" "}
          membre{elus.length > 1 ? "s" : ""} du périmètre SDEA :
        </Typography>
      </Grid>
      <Authorization roles={[ApplicationRole.REPORTS_READ_ALL]}>
        <Grid item xs={12}>
          <LoadingButton
            variant="contained"
            onClick={createFeuillePresence}
            loading={isExcelLoading}>
            EXPORTER FEUILLE DE PRESENCE
          </LoadingButton>
        </Grid>
      </Authorization>
      <Grid item xs={12}>
        <TableContainer
          sx={{
            [`&.${tableContainerClasses.root} .${tableBodyClasses.root} .${tableRowClasses.root}:last-child .${tableCellClasses.root}`]:
              { border: "none" },
          }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography fontWeight={600}>Elu</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={600}>Fonction</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={600}>Commune</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {elus?.map((elu) => (
                <PerimetreDeleguesSdeaRow
                  key={elu?.id}
                  elu={elu}
                  communes={communes}
                  importantFonctions={[Fonctions.PRESIDENT_COMMISSION_LOCALE]}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

const PerimetreDeleguesSdeaWithErrorBoundary = withComponentErrorBoundary(PerimetreDeleguesSdea);
export default PerimetreDeleguesSdeaWithErrorBoundary;
