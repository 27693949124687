import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { enqueueSnackbar } from "notistack";
import { Button, Grid, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useForm } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import { Authorization, useUser } from "@sdeapps/react-core";
import type { User } from "@sdeapps/react-core";
import { ApplicationRole } from "constants/ApplicationRole";
import ToastMessages from "constants/ToastMessages";
import ControlledTextField from "components/inputs/ControlledTextField";
import LoadingButton from "components/LoadingButton";
import type { Commentaire } from "models";
import { communesService, graphService } from "services";
import { withComponentErrorBoundary, useErrorHandler } from "utils/errorHandling";
import ChatOutlined from "@mui/icons-material/ChatOutlined";
import { grey } from "@mui/material/colors";
import CommuneCommentaireInformation from "./CommuneCommentaireInformation";
import EditIcon from "@mui/icons-material/Edit";

async function getAgent(
  agentId: string | undefined,
  setter: (user: User | undefined) => void
): Promise<void> {
  let agent: User | undefined;
  if (agentId != null) {
    agent = await graphService.getAgentById(agentId);
  }
  setter(agent);
}

interface CommuneCommentaireProps {
  idCommune: string;
  commentaire?: Commentaire;
}
function CommuneCommentaire({
  idCommune,
  commentaire: commentaireFromProps = { valeur: "" },
}: Readonly<CommuneCommentaireProps>): ReactNode {
  const [commentaire, setCommentaire] = useState<Commentaire>(commentaireFromProps);
  const { isRoles } = useUser();
  const { catchErrors, isLoading: isSending } = useErrorHandler({
    dontThrow: true,
    defaultIsLoading: false,
    default: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR_RETRY,
      });
    },
  });

  const [auteur, setAuteur] = useState<User | undefined>(undefined);

  const [isEditing, setIsEditing] = useState(false);
  const { handleSubmit, control, reset, formState } = useForm<Commentaire>({
    shouldFocusError: false,
  });
  useEffect(() => {
    reset(commentaire);
    void catchErrors(async () => {
      void getAgent(commentaire.idAuteur, setAuteur);
    });
  }, [reset, commentaire, catchErrors]);

  const sendData: SubmitHandler<Commentaire> = async function ({
    valeur,
  }: Commentaire): Promise<void> {
    if (!formState.isDirty) {
      return;
    }
    await catchErrors(async () => {
      const commentaire = await communesService.postCommuneCommentaire(idCommune, { valeur });
      setCommentaire(commentaire);
      setIsEditing(false);
    });
  };

  const hasCommentaire = commentaire.valeur != null && commentaire.valeur !== "";

  if (!hasCommentaire && !isRoles(ApplicationRole.ELUS_WRITE_ALL)) {
    return <></>;
  }

  return (
    <Grid
      container
      item
      spacing={2}
      alignItems="flex-start"
      component="form"
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(sendData)}
      xs={12}>
      {isEditing ? (
        <Grid item xs={12} sm>
          <ControlledTextField control={control} name="valeur" label="Commentaire" multiline />
        </Grid>
      ) : (
        hasCommentaire && (
          <>
            <Grid item xs="auto">
              <ChatOutlined sx={{ color: grey[700] }} />
            </Grid>
            <Grid item xs={12} sm>
              <Typography whiteSpace="break-spaces">{commentaire.valeur}</Typography>
              <CommuneCommentaireInformation
                auteurDisplayName={auteur?.displayName}
                dateCreation={commentaire.dateCreation}
              />
            </Grid>
          </>
        )
      )}
      <Grid item container xs={12}>
        <Authorization roles={ApplicationRole.ELUS_WRITE_ALL}>
          {isEditing ? (
            <>
              <Button
                variant="text"
                color="error"
                sx={{ mr: 2 }}
                onClick={(e) => {
                  e.preventDefault();
                  reset(commentaireFromProps);
                  setIsEditing(!isEditing);
                }}>
                Annuler
              </Button>
              <LoadingButton
                variant="contained"
                startIcon={<SaveIcon />}
                loading={isSending}
                disabled={!formState.isDirty}>
                Enregistrer
              </LoadingButton>
            </>
          ) : (
            <Button
              variant="contained"
              color={isEditing ? "primary" : "secondary"}
              type={!isEditing ? "submit" : "button"}
              startIcon={hasCommentaire ? <EditIcon /> : <ChatOutlined />}
              onClick={() => {
                setIsEditing(!isEditing);
              }}>
              {hasCommentaire ? "Modifier le commentaire" : "Ajouter un commentaire"}
            </Button>
          )}
        </Authorization>
      </Grid>
    </Grid>
  );
}

const CommuneCommentaireWithErrorBoundary = withComponentErrorBoundary(CommuneCommentaire);

export default CommuneCommentaireWithErrorBoundary;
