import type { ReactNode } from "react";
import { Button, Container, Grid } from "@mui/material";
import InformationBox from "components/InformationBox";
import PageTitle from "components/PageTitle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Authorization, dateUtil } from "@sdeapps/react-core";
import { ApplicationRole } from "constants/ApplicationRole";
import { useErrorHandler, SdeappsError, withPageErrorBoundary } from "utils/errorHandling";
import NewTransfertInformationsPrincipales from "./components/NewTransfertInformationsPrincipales";
import { routesConfig } from "config/app-config";
import { useForm } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import type { CreateTransfertsModelForm, TransfertCreateData, CommuneWithTransferts } from "models";
import { CommuneIcon } from "icons";
import NewTransfertCollectivite from "./components/NewTransfertCollectivite";
import NewTransfertPortees from "./components/NewTransfertPortees";
import { enqueueSnackbar } from "notistack";
import ToastMessages from "constants/ToastMessages";
import { useNavigate } from "react-router-dom";
import LoadingButton from "components/LoadingButton";
import Competence from "constants/Competence";
import { transfertsService } from "services";

function getDates(
  createTransfertsData: CreateTransfertsModelForm
): [dateDebut: string, dateDeliberation: string, dateAssembleeGenerale: string] {
  const dateDebut = dateUtil.format(createTransfertsData.dateDebut, "yyyy-MM-dd");
  const dateDeliberation = dateUtil.format(createTransfertsData.dateDeliberation, "yyyy-MM-dd");
  const dateAssembleeGenerale = dateUtil.format(
    createTransfertsData.dateAssembleeGenerale,
    "yyyy-MM-dd"
  );

  return [dateDebut, dateDeliberation, dateAssembleeGenerale];
}

function createTransfertCreateData(
  createTransfertsData: CreateTransfertsModelForm,
  code: string,
  bassinVersant?: string
): TransfertCreateData {
  if (createTransfertsData.perimetre == null) {
    throw new SdeappsError("createTransfertsData.perimetre est null !");
  }
  const [dateDebut, dateDeliberation, dateAssembleeGenerale] = getDates(createTransfertsData);

  const transfert: TransfertCreateData = {
    code,
    dateDebut,
    dateDeliberation,
    dateAssembleeGenerale,
    bassinVersant,
    raisonCreation: createTransfertsData.raisonCreation,
    idPerimetre: createTransfertsData.perimetre.id,
    ...(!createTransfertsData.isCommuneDirectTransfert
      ? { idEpci: createTransfertsData.idEpci }
      : {}),
  };

  return transfert;
}

function createTransfertsGce(
  createTransfertsData: CreateTransfertsModelForm
): Array<TransfertCreateData> {
  const transfertCreateDatas: Array<TransfertCreateData> = [];

  createTransfertsData.codeTransfertsGce.forEach((groupByBassinVersant) => {
    groupByBassinVersant.codeTransferts.forEach((code) => {
      const transfert = createTransfertCreateData(
        createTransfertsData,
        code,
        groupByBassinVersant.bassinVersant === "" ? undefined : groupByBassinVersant.bassinVersant
      );
      transfertCreateDatas.push(transfert);
    });
  });

  return transfertCreateDatas;
}

function createTransfertsEauAss(
  createTransfertsData: CreateTransfertsModelForm
): Array<TransfertCreateData> {
  return createTransfertsData.codeTransfertsEauAssGepu.map((code) => {
    const transfert = createTransfertCreateData(createTransfertsData, code);
    return transfert;
  });
}

async function createCommuneTransferts(
  createTransfertsData: CreateTransfertsModelForm,
  communeId: string
): Promise<CommuneWithTransferts> {
  if (createTransfertsData.perimetre == null) {
    throw new SdeappsError("createTransfertsData.perimetre est null !");
  }

  const newTransfertsCreateData: Array<TransfertCreateData> =
    createTransfertsData.perimetre.competence === Competence.GCE
      ? createTransfertsGce(createTransfertsData)
      : createTransfertsEauAss(createTransfertsData);

  return transfertsService.createCommuneTransferts(communeId, newTransfertsCreateData);
}

function NewTransfertPage(): ReactNode {
  const { handleSubmit, control, watch, setValue } = useForm<CreateTransfertsModelForm>({
    shouldFocusError: false,
    defaultValues: {
      perimetre: null,
      codeTransfertsEauAssGepu: [],
      codeTransfertsGce: [],
      isCommuneDirectTransfert: true,
      communeIds: [],
    },
  });

  const navigate = useNavigate();

  const { catchErrors, isLoading: isSending } = useErrorHandler({
    dontThrow: true,
    defaultIsLoading: false,
    default: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR_RETRY,
      });
    },
  });

  const submitTransferts: SubmitHandler<CreateTransfertsModelForm> = async function (
    createTransfertsData: CreateTransfertsModelForm
  ): Promise<void> {
    async function createTransferts(): Promise<void> {
      if (createTransfertsData.perimetre == null) {
        throw new SdeappsError("createTransfertsData.perimetre est null !");
      }

      if (createTransfertsData.isCommuneDirectTransfert) {
        await createCommuneTransferts(createTransfertsData, createTransfertsData.idCommune);
      } else {
        await Promise.all(
          createTransfertsData.communeIds.map(
            async (communeId) => await createCommuneTransferts(createTransfertsData, communeId)
          )
        );
      }

      enqueueSnackbar({
        variant: "success",
        message: ToastMessages.CREATED_TRANSFERT,
      });

      navigate(routesConfig.admin.path);
    }

    await catchErrors(createTransferts);
  };

  return (
    <Container>
      <Authorization roles={ApplicationRole.ADMINISTRATOR}>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <Grid container spacing={2} component="form" onSubmit={handleSubmit(submitTransferts)}>
          <PageTitle title="Nouveau Transfert" />
          <Grid item container xs={12}>
            <InformationBox icon={VisibilityIcon} articleTitle="Informations principales">
              <NewTransfertInformationsPrincipales control={control} />
            </InformationBox>
          </Grid>
          <Grid item container xs={12}>
            <InformationBox icon={CommuneIcon} articleTitle="Communes">
              <NewTransfertCollectivite control={control} watch={watch} setValue={setValue} />
            </InformationBox>
          </Grid>
          <Grid item container xs={12}>
            <InformationBox icon={VisibilityIcon} articleTitle="Sous-Compétences et Portées">
              <NewTransfertPortees control={control} competence={watch("perimetre")?.competence} />
            </InformationBox>
          </Grid>
          <Grid container item xs={12} gap={2}>
            <Button href={routesConfig.admin.path} color="error">
              Annuler
            </Button>
            <LoadingButton variant="contained" loading={isSending}>
              Enregistrer
            </LoadingButton>
          </Grid>
        </Grid>
      </Authorization>
    </Container>
  );
}

const NewTransfertPageWithErrorBoundary = withPageErrorBoundary(NewTransfertPage);

export default NewTransfertPageWithErrorBoundary;
