import { Endpoints } from "constants/endpoints";
import type { Mandat, MandatSdeaCreateModel, PatchData } from "models";
import { dateUtil } from "@sdeapps/react-core";
import { elusApiClient } from "./apiClients";

/**
 * Créée un mandat SDEA
 * @param data les données du mandat à créer
 * @returns le mandat créé
 */
async function create(data: MandatSdeaCreateModel): Promise<Mandat> {
  const url = `/${Endpoints.MANDATS}`;
  return elusApiClient.post(url, data);
}

async function cloturer(dateFin: Date, idMandat: string): Promise<void> {
  const url = `/${Endpoints.MANDATS}/${idMandat}`;

  const patchData: Array<PatchData> = [
    {
      op: "replace",
      path: "/dateFinMandat",
      value: dateUtil.format(dateFin, "yyyy-MM-dd"),
    },
  ];

  return elusApiClient.patch(url, patchData);
}

async function deleteMandat(idMandat: string): Promise<void> {
  const url = `/${Endpoints.MANDATS}/${idMandat}`;

  return elusApiClient.delete(url);
}

export const mandatsService = {
  create,
  cloturer,
  deleteMandat,
};
