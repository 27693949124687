import type { PopulationsCad } from "models";

export interface CommuneAssocieeDeleguee {
  id: string;
  libelle: string;
  codeChefLieu: string;
  ncc: string;
  nccEnr: string;
  dateDebut: string;
  dateFin: string;
  type: TypeCommuneAssocieeDeleguee;
  population: PopulationsCad;
}

export enum TypeCommuneAssocieeDeleguee {
  DELEGUEE = "COMD",
  ASSOCIEE = "COMA",
}
