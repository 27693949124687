import { Endpoints } from "constants/endpoints";
import type { Commentaire, CommentaireCreateDto, Commune } from "models";
import { collectivitesApiClient } from "./apiClients";

/**
 * Récupère une commune en fonction de son code INSEE
 * @param codeInsee code INSEE
 * @returns Commune
 */
async function getById(codeInsee: string): Promise<Commune> {
  const url = `/${Endpoints.COMMUNES}/${codeInsee}`;
  return collectivitesApiClient.get(url);
}

/**
 * Récupère toutes les communes
 * @returns Array<Commune>
 */
async function getAll(): Promise<Array<Commune>> {
  const url = `/${Endpoints.COMMUNES}`;
  return collectivitesApiClient.get(url);
}

/**
 * Récupère toutes les communes d'Alsace-Moselle
 * @returns Array<Commune>
 */
async function getAllInAlsaceMoselle(): Promise<Array<Commune>> {
  const _communes = await getAll();
  const _filteredCommunes = _communes.filter((commune) =>
    ["57", "67", "68"].includes(commune.departement.id)
  );

  return _filteredCommunes;
}

/**
 * Récupère toutes les communes d'un EPCI
 * @param siren le SIREN de l'EPCI
 * @returns Array<Commune>
 */
async function getByEpci(siren: string): Promise<Array<Commune>> {
  const url = `/${Endpoints.EPCIS}/${siren}/${Endpoints.COMMUNES}`;
  return collectivitesApiClient.get(url);
}

/**
 * Récupère toutes les communes d'un Perimetre
 * @param id l'id du Périmètre
 * @returns les Communes du périmètre
 */
async function getByPerimetre(id: string): Promise<Array<Commune>> {
  const url = `/${Endpoints.PERIMETRES}/${id}/${Endpoints.COMMUNES}`;
  return collectivitesApiClient.get(url);
}

/**
 * Récupère toutes les communes d'un Territoire
 * @param id l'id du Territoire
 * @returns les Communes du Territoire
 */
async function getByTerritoire(id: string): Promise<Array<Commune>> {
  const url = `/${Endpoints.TERRITOIRES}/${id}/${Endpoints.COMMUNES}`;
  return collectivitesApiClient.get(url);
}

/**
 * Ajoute un commentaire à d'une commune
 * @param communeId l'identifiant de la commune du commentaire
 * @param data Commentaire à créer
 */
async function postCommuneCommentaire(
  communeId: string,
  data: CommentaireCreateDto
): Promise<Commentaire> {
  const url = `/${Endpoints.COMMUNES}/${communeId}/commentaire`;
  return collectivitesApiClient.post(url, data);
}

export const communesService = {
  getAll,
  getAllInAlsaceMoselle,
  getById,
  getByEpci,
  getByPerimetre,
  getByTerritoire,
  postCommuneCommentaire,
};
