import { forwardRef } from "react";
import type { PropsWithChildren, ReactElement } from "react";
import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material/styles";
import type { ThemeOptions } from "@mui/material/styles";
import { frFR } from "@mui/material/locale";
import { Link as RouterLink } from "react-router-dom";
import type { LinkProps as RouterLinkProps } from "react-router-dom";
import type { LinkProps } from "@mui/material/Link";

/**
 * Ceci sert à customizer les composants MUI Link et Button pour utiliser
 * le lien de react-router avec la prop "href" mappée sur "to"
 */
const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});
LinkBehavior.displayName = "LinkBehavior";

const theme: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1600,
    },
  },
  palette: {
    primary: {
      light: "#e0f3f8",
      main: "#017cac",
      dark: "#01638a",
    },
    secondary: {
      light: "#FCDBE1",
      main: "#EF4565",
      dark: "#EC2247",
    },
    info: {
      light: "#EFF1F8",
      main: "#617ABB",
      dark: "#4B64AF",
    },
    success: {
      light: "#E5F4F0",
      main: "#02986A",
      dark: "#027451",
    },
    warning: {
      light: "#FFF8ED",
      main: "#FFBF4D",
      dark: "#FFB029",
    },
    error: {
      light: "#FCEFED",
      main: "#E1604D",
      dark: "#DC452E",
    },
    gce: {
      light: "#E7F4F1",
      main: "#93D4C4",
      dark: "#76AA9D",
      contrastText: "#FFF",
    },
    eau: {
      light: "#DEEFF5",
      main: "#5AB1CB",
      dark: "#488EA2",
      contrastText: "#FFF",
    },
    gepu: {
      light: "#EEE7F5",
      main: "#D5A5E8",
      dark: "#AA84BA",
      contrastText: "#FFF",
    },
    ass: {
      light: "#F6EBE6",
      main: "#E1BBAB",
      dark: "#B49689",
      contrastText: "#FFF",
    },
    text: {
      primary: "#4B515D",
      secondary: "#585A5D",
      disabled: "#888B90",
    },
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", "sans serif"',
    fontSize: 16,
    h1: {
      fontSize: "2.44rem",
      fontWeight: "700",
      color: "#4B515D",
    },
    h2: {
      fontSize: "1.95rem",
      fontWeight: "700",
      color: "#4B515D",
    },
    h3: {
      fontSize: "1.56rem",
      fontWeight: "600",
      color: "#4B515D",
    },
    h4: {
      fontSize: "1.4rem",
      fontWeight: "600",
      color: "#4B515D",
    },
    h5: {
      fontSize: "1.3rem",
      fontWeight: "600",
      color: "#4B515D",
    },
    h6: {
      fontSize: "1.2rem",
      fontWeight: "600",
      color: "#4B515D",
    },
    subtitle1: {
      fontSize: "1.25rem",
      fontWeight: "400",
      color: "#4B515D",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: "400",
      color: "#4B515D",
    },
    body2: {
      fontSize: "0.8rem",
      fontWeight: "400",
      color: "#4B515D",
    },
    button: {
      fontSize: "1rem",
      textTransform: "none",
    },
    caption: {
      fontSize: "0.8rem",
      color: "grey",
    },
  },
  /** On customize les composants MUI Link et Button pour utiliser
   * le lien de react-router avec la prop "href" mappée sur "to"
   */
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
        variant: "body1",
        underline: "hover",
        fontWeight: 500,
      } satisfies LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
};

const tempTheme = createTheme(theme, frFR);
export const SDEAppsTheme = responsiveFontSizes(tempTheme);

export function SDEAppsThemeProvider({ children }: Readonly<PropsWithChildren>): ReactElement {
  return <ThemeProvider theme={SDEAppsTheme}>{children}</ThemeProvider>;
}

declare module "@mui/material/styles" {
  interface Palette {
    gce: Palette["primary"];
    eau: Palette["primary"];
    ass: Palette["primary"];
    gepu: Palette["primary"];
  }

  interface PaletteOptions {
    gce: PaletteOptions["primary"];
    eau: PaletteOptions["primary"];
    ass: PaletteOptions["primary"];
    gepu: PaletteOptions["primary"];
  }
}

// Update the Button's "color" prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    gce: true;
    eau: true;
    ass: true;
    gepu: true;
  }
}

export type colorPaletteKey =
  | "primary"
  | "secondary"
  | "inherit"
  | "grey"
  | "error"
  | "warning"
  | "info"
  | "success"
  | "gce"
  | "eau"
  | "ass"
  | "gepu"
  | undefined;
