import type { ReactNode } from "react";
import { Typography } from "@mui/material";
import EpciLink from "components/Links/EpciLink";
import PerimetreLinkWithErrorBoundary from "components/Links/PerimetreLink";
import TerritoireLink from "components/Links/TerritoireLink";
import type { Epci, Perimetre } from "models";

interface CommuneSousCompetencesAccordionSummaryProps {
  perimetre: Perimetre;
  epci?: Epci;
}

function CommuneSousCompetencesAccordionSummary({
  perimetre,
  epci,
}: Readonly<CommuneSousCompetencesAccordionSummaryProps>): ReactNode {
  return (
    <Typography sx={{ ml: 2 }}>
      <PerimetreLinkWithErrorBoundary perimetre={perimetre} fontWeight={600} />
      <br />
      Adhésion{" "}
      {epci != null ? (
        <>
          via <EpciLink epci={epci} />
        </>
      ) : (
        "directe"
      )}
      {" - "}
      <TerritoireLink territoire={perimetre.territoire} />
    </Typography>
  );
}

export default CommuneSousCompetencesAccordionSummary;
