import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import type { TypographyProps } from "@mui/material";
import { dateUtil } from "@sdeapps/react-core";
import type { Heure, Horaire } from "models";

function formatHours(heures: Array<Heure>): string {
  return heures
    .map((h, i) => {
      if (h.a !== "" && h.de !== "") {
        const _de = dateUtil.format(`1900-01-01T${h.de}`, "HH:mm");
        const _a = dateUtil.format(`1900-01-01T${h.a}`, "HH:mm");

        return `${_de} - ${_a}${
          i < heures.length - 1 && heures[i + 1].a !== "" && heures[i + 1].de !== "" ? " et " : ""
        }`;
      }
      return "";
    })
    .join("");
}

interface HorairesTypographyProps extends TypographyProps {
  horaires: Array<Horaire>;
}

interface JourHoraire {
  index: number;
  jour?: string;
  horaire: string;
}

const daysOfTheWeek = [
  { index: 0, jour: "Lundi" },
  { index: 1, jour: "Mardi" },
  { index: 2, jour: "Mercredi" },
  { index: 3, jour: "Jeudi" },
  { index: 4, jour: "Vendredi" },
  { index: 5, jour: "Samedi" },
] as const;

function HorairesTypography({ horaires }: Readonly<HorairesTypographyProps>): ReactNode {
  const [joursHoraires, setJoursHoraires] = useState<Array<JourHoraire>>([]);

  useEffect(() => {
    const _joursHoraire: Array<JourHoraire> = [];
    horaires.forEach((j) => {
      const fullCalendar: Array<JourHoraire> = [];

      for (
        let v = daysOfTheWeek.findIndex((d) => d.jour === j.du);
        v <= daysOfTheWeek.findIndex((d) => d.jour === j.au);
        v++
      ) {
        fullCalendar.push({
          index: daysOfTheWeek[v].index,
          jour: daysOfTheWeek[v].jour,
          horaire: formatHours(j.heures),
        });
      }
      _joursHoraire.push(...fullCalendar);
    });

    for (const day of daysOfTheWeek) {
      if (!_joursHoraire.some((e) => e.jour === day.jour)) {
        _joursHoraire.push({
          index: day.index,
          jour: day.jour,
          horaire: "Fermé",
        });
      }
    }

    _joursHoraire.sort((a, b) => a.index - b.index);
    setJoursHoraires(_joursHoraire);
  }, [horaires]);

  return (
    <Grid container item xs={12}>
      {joursHoraires.map((jh) => (
        <Grid key={jh.index} container item xs={12}>
          <Grid item xs={12} sm={4}>
            <Typography fontWeight="500" textAlign="right">
              {jh.jour} :
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography textAlign="right">{jh.horaire}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default HorairesTypography;
