import type { ReactNode } from "react";
import { Button } from "@mui/material";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import type { Perimetre, Territoire, Commune } from "models";
import type Competence from "constants/Competence";
import competenceMap from "constants/CompetenceMap";

type Collectivite = Commune | Perimetre | Territoire;

interface SigLinkProps {
  collectivite: Collectivite;
  competenceTerritoire?: Competence;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function instanceOfCommune(object: any): object is Commune {
  return "codeSdea" in object;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function instanceOfPerimetre(object: any): object is Perimetre {
  return "competence" in object;
}

function getHref(c: Collectivite, competenceTerritoire?: Readonly<Competence>): string {
  let href = "";
  if (instanceOfCommune(c)) {
    // Commune
    href = `https://sig.sdea.local/Intranet/tmpl/ElyxWebIntranet/?CTX=EAU%20-%20WEB&KEY=PE_COMMUNE*/${c.codeSdea}`;
  } else if (instanceOfPerimetre(c)) {
    // Perimetre
    const ca = competenceMap.get(c.competence);
    href = `https://sig.sdea.local/Intranet/tmpl/ElyxWebIntranet/?CTX=${ca?.contextSigPerimetre}&KEY=${ca?.keySigPerimetre}*/${c.code}`;
  } else if (competenceTerritoire != null) {
    // Territoire
    const keySigTerritoire = competenceMap.get(competenceTerritoire)?.keySigTerritoire;
    href = `https://sig.sdea.local/Intranet/tmpl/ElyxWebIntranet/?CTX=WEB_DEFAUT&KEY=${keySigTerritoire}*/${c.id}`;
  }

  return href;
}

function SigLink({ collectivite, competenceTerritoire }: Readonly<SigLinkProps>): ReactNode {
  const color =
    competenceTerritoire == null ? "primary" : competenceMap.get(competenceTerritoire)?.color;

  return (
    <Button
      size="large"
      variant="contained"
      color={color}
      href={getHref(collectivite, competenceTerritoire)}
      target="_blank"
      startIcon={<MapOutlinedIcon />}>
      Cartographie SIG{" "}
      {competenceTerritoire != null && competenceMap.get(competenceTerritoire)?.smallLabel}
    </Button>
  );
}

export default SigLink;
