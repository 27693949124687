import type { ReactNode } from "react";
import { Typography } from "@mui/material";
import { dateUtil } from "@sdeapps/react-core";
import { EtablissementPublicSource } from "models";
import type { EtablissementPublic } from "models";

interface EtablissementPublicTitleProps {
  etablissementPublic: EtablissementPublic;
}

function EtablissementPublicTitle({
  etablissementPublic,
}: Readonly<EtablissementPublicTitleProps>): ReactNode {
  if (etablissementPublic.source === EtablissementPublicSource.OPEN_DATA) {
    return (
      <>
        <Typography variant="subtitle2">Source : données publiques</Typography>
        <Typography variant="caption">
          Mise à jour le {dateUtil.format(etablissementPublic?.dateModification, "dd/MM/yyyy")}
        </Typography>
      </>
    );
  }
  if (etablissementPublic.source === EtablissementPublicSource.SDEA) {
    return (
      <>
        <Typography variant="subtitle2">Source : SDEA</Typography>
        <Typography variant="caption">
          Mise à jour par {etablissementPublic?.modifiedBy?.name} le{" "}
          {dateUtil.format(etablissementPublic?.dateModification, "dd/MM/yyyy")}
        </Typography>
      </>
    );
  }
  return null;
}

export default EtablissementPublicTitle;
