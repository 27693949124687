import { useState, useEffect } from "react";
import type { ReactNode } from "react";
import { List, ListItem } from "@mui/material";
import EpciLink from "components/Links/EpciLink";
import { epcisService } from "services";
import { useErrorHandler, withComponentErrorBoundary } from "utils/errorHandling";
import { arrayUtil } from "@sdeapps/react-core";
import ListSkeleton from "components/ListSkeleton";
import type { Epci } from "models";

interface CommuneEpcisInformationsProps {
  idCommune: string;
}

function CommuneEpcisInformations({
  idCommune,
}: Readonly<CommuneEpcisInformationsProps>): ReactNode {
  const [epcis, setEpcis] = useState<Array<Epci>>([]);

  const { catchErrors, isLoading } = useErrorHandler();

  useEffect(() => {
    async function getEpcis(): Promise<void> {
      const _epcis = await epcisService.getByCommune(idCommune);

      setEpcis(arrayUtil.dedupArray(_epcis, (epci) => epci.id));
    }

    void catchErrors(getEpcis);
  }, [catchErrors, idCommune]);

  if (isLoading) {
    return <ListSkeleton />;
  }

  return (
    <List>
      {epcis?.map((epci) => (
        <ListItem key={epci.id}>
          <EpciLink epci={epci} />
        </ListItem>
      ))}
    </List>
  );
}

const CommuneEpcisInformationsWithErrorBoundary =
  withComponentErrorBoundary(CommuneEpcisInformations);

export default CommuneEpcisInformationsWithErrorBoundary;
