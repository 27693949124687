import type { ReactNode } from "react";
import { Checkbox, Skeleton, TableCell, TableRow, Typography } from "@mui/material";
import { useTransfertsEditionContext } from "../store/transfertsEditionContext";
import { TransfertsEditionReducerActionType } from "../store/transfertsEditionTypes";
import type { TransfertEditionViewModel } from "../models/TransfertEditionViewModel";

interface PerimetreEditTransfertsTableContentProps {
  isLoading: boolean;
  showBassinVersant: boolean;
  transferts: Array<TransfertEditionViewModel>;
}
function PerimetreEditTransfertsTableContent({
  isLoading,
  showBassinVersant,
  transferts,
}: Readonly<PerimetreEditTransfertsTableContentProps>): ReactNode {
  const { dispatch } = useTransfertsEditionContext();

  if (isLoading) {
    return [0, 1, 2, 3, 4].map((i) => (
      <TableRow key={i}>
        <TableCell colSpan={10}>
          <Skeleton />
        </TableCell>
      </TableRow>
    ));
  }
  if (transferts.length === 0) {
    return (
      <TableRow>
        <TableCell colSpan={10}>
          <Typography textAlign="center">Pas de transferts éligibles</Typography>
        </TableCell>
      </TableRow>
    );
  }

  return transferts.map((transfert) => (
    <TableRow
      hover
      key={transfert.id}
      selected={transfert.selected}
      onClick={() => {
        dispatch({
          type: TransfertsEditionReducerActionType.SELECT_TOGGLE,
          payload: transfert.id,
        });
      }}
      sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}>
      <TableCell padding="checkbox">
        <Checkbox color="primary" checked={transfert.selected} />
      </TableCell>
      <TableCell>
        <Typography>{transfert.commune?.libelle ?? transfert.idCommune}</Typography>
      </TableCell>
      {showBassinVersant && (
        <TableCell>
          <Typography>{transfert.bassinVersant ?? "Tous"}</Typography>
        </TableCell>
      )}
      <TableCell>
        <Typography>{transfert.epci?.libelle ?? transfert.idEpci}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{transfert.sousCompetenceLibelle}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{transfert.porteeLibelle}</Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap>{transfert.datePremierTransfert}</Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap>{transfert.dateDeliberation}</Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap>{transfert.dateAssembleeGenerale}</Typography>
      </TableCell>
    </TableRow>
  ));
}

export default PerimetreEditTransfertsTableContent;
