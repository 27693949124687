export interface EtablissementPublic {
  codeInsee: string;
  source: EtablissementPublicSource;
  id: string;
  pivotLocal?: number;
  nom?: string;
  email?: string;
  telephone?: string;
  adresse: Adresse;
  horaires?: Array<Horaire>;
  modifiedBy?: SdeaUser;
  url?: string;
  ancienCodePivot?: string;
  dateModification: Date | string;
}

export enum EtablissementPublicSource {
  OPEN_DATA = "OPEN_DATA",
  SDEA = "SDEA",
}

export interface Adresse {
  numeroVoie?: string;
  codePostal?: string;
  commune?: string;
  latitude?: string;
  longitude?: string;
  serviceDistribution?: string;
  complement1?: string;
  complement2?: string;
  typeAdresse?: string;
}

export interface Horaire {
  du: string;
  au: string;
  heures: Array<Heure>;
}

export interface Heure {
  de: string;
  a: string;
}

export interface SdeaUser {
  oid: string;
  name: string;
  email: string;
}

export interface EtablissementPublicModelForm {
  id?: string;
  nom?: string;
  adresse?: Adresse;
  email?: string;
  telephone?: string;
}
