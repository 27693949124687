import { useState, useEffect } from "react";
import type { ReactNode } from "react";
import { Autocomplete, TextField, Typography, createFilterOptions } from "@mui/material";
import { useController } from "react-hook-form";
import type { FieldValues, FieldPath } from "react-hook-form";
import type { Commune } from "models";
import { stringUtil } from "@sdeapps/react-core";
import type { ControlledInput } from "utils";
import { useErrorHandler, withComponentErrorBoundary } from "utils/errorHandling";
import { communesService } from "services";
import InputSkeleton from "components/InputSkeleton";

interface CommuneAutoCompleteProps<T extends FieldValues, Name extends FieldPath<T>>
  extends ControlledInput<T, Name> {
  disabled?: boolean;
  variant: "id" | "commune";
}

/**
 * AutoComplete qui permet de sélectionne une Commune
 * @param variant "id" | "commune" : détermine si l'Autocomplete renvoie l'id de la commune ou l'objet Commune entier
 */
function CommuneAutoComplete<T extends FieldValues, Name extends FieldPath<T>>({
  name,
  control,
  rules,
  disabled = false,
  variant,
}: Readonly<CommuneAutoCompleteProps<T, Name>>): ReactNode {
  const filterOptions = createFilterOptions({
    trim: true,
    stringify: (option: Commune) => stringUtil.normalize(option.libelle),
  });
  const [communes, setCommunes] = useState<Array<Commune>>([]);

  const { catchErrors, isLoading } = useErrorHandler();

  const {
    field: { value, onChange, ref },
    fieldState: { error },
  } = useController({ name, control, rules });

  useEffect(() => {
    async function getCommunes(): Promise<void> {
      const _communes = await communesService.getAllInAlsaceMoselle();

      _communes.sort((a, b) =>
        stringUtil.normalize(a.libelle).localeCompare(stringUtil.normalize(b.libelle))
      );
      setCommunes(_communes);
    }

    void catchErrors(getCommunes);
  }, [catchErrors]);

  if (isLoading) {
    return <InputSkeleton />;
  }

  return (
    <Autocomplete
      value={(variant === "id" ? communes.find((c) => c.id === value) : value) ?? null}
      fullWidth
      autoHighlight
      openOnFocus
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={communes}
      filterOptions={filterOptions}
      disabled={disabled}
      onChange={(_, newValue) => {
        variant === "id" ? onChange(newValue?.id) : onChange(newValue);
      }}
      isOptionEqualToValue={(v1: Commune, v2: Commune): boolean => v1.id === v2.id}
      getOptionLabel={(commune: Commune): string =>
        `${commune.libelle} (${commune?.id?.substring(0, 2)})`
      }
      renderOption={(props, option) => (
        <Typography component="li" {...props} key={option.id}>
          {option.libelle} ({option.id.substring(0, 2)})
        </Typography>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Commune *"
          placeholder="Nom de la Commune"
          inputRef={ref}
          error={error != null}
          helperText={error?.message}
        />
      )}
    />
  );
}

const CommuneAutoCompleteWithErrorBoundary = withComponentErrorBoundary(CommuneAutoComplete);

export default CommuneAutoCompleteWithErrorBoundary;
