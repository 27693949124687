import { arrayUtil } from "@sdeapps/react-core";
import type { Transfert } from "models";

/**
 * Récupère une chaîne de charactères représentant simplement les propriétés suivantes d'un
 * transfert :
 *
 * - type de transfert
 * - code de sous-compétences
 * - bassin versant
 * - portées
 *
 * Dans le but de pouvoir simplement comparer ou ordonner plusieurs transferts entre eux.
 * @param transfert le transfert
 * @returns une `string` représentant les propriétés concernées du transfert
 */
function getTransfertsComparisonBase(transfert: Transfert): string {
  return `${transfert.idPerimetre}${transfert?.idEpci}${transfert.bassinVersant}${transfert.code}`;
}

/**
 * Récupère une chaîne de charactères représentant simplement les propriétés suivantes d'un
 * transfert :
 *
 * - type de transfert
 * - code de sous-compétences
 * - bassin versant
 * - portées
 * - date de délibération
 * - date de début de transfert
 *
 * Dans le but de pouvoir simplement comparer ou ordonner plusieurs transferts entre eux.
 * @param sousCompetence le transfert
 * @returns une `string` représentant les propriétés concernées du transfert
 */
function getTransfertsComparisonBaseWithDates(transfert: Transfert): string {
  return `${transfert.idPerimetre}${transfert?.idEpci}${transfert.bassinVersant}${transfert.code}${transfert.dateDeliberation}${transfert.dateDebut}`;
}

/**
 * Groupe les transferts d'un périmètre, d'abord par communes, puis par :
 *
 * - type de transfert
 * - code de sous-compétences
 * - bassin versant
 * - portées
 *
 * **Attention** déduplique également les transferts par code de transfert pour chaque Commune, ce qui
 * influe sur le regroupement.
 *
 * @param transferts la liste des `Transfert` que l'on veut regrouper
 * @returns une liste de listes de listes de `Transfert`. La première liste de transfert représente une Commune,
 * on a donc la liste de ceci qui est la liste de toutes les communes, puis une liste de celles-ci qui regroupe les
 * communes qui ont des transferts équivalents selon {@link getTransfertsComparisonBase}.
 */
function getGroupedPerimetreCommunes(transferts: Array<Transfert>): Array<Array<Array<Transfert>>> {
  const transfertsByCommune = arrayUtil.groupByArray(
    transferts,
    (t) => `${t.idCommune}${t.idEpci}`
  );

  // dedup by transfert code + bassin versant inside commune
  const transfertsByCommuneDedup = transfertsByCommune.map((communeTransferts) =>
    arrayUtil.dedupArray(communeTransferts, (t) => `${t.code}${t.bassinVersant}`)
  );

  const transfertsByCommuneByPortees = arrayUtil.groupByArray(
    transfertsByCommuneDedup,
    (_transfertsByCommune) => {
      let comparisonString = "";
      _transfertsByCommune.sort((a, b) =>
        getTransfertsComparisonBase(a).localeCompare(getTransfertsComparisonBase(b))
      );

      _transfertsByCommune.forEach(
        (transfert) => (comparisonString += getTransfertsComparisonBase(transfert))
      );

      return comparisonString;
    }
  );

  return transfertsByCommuneByPortees;
}

/**
 * Groupe les transferts d'un périmètre, d'abord par communes, puis par :
 *
 * - type de transfert
 * - code de sous-compétences
 * - bassin versant
 * - portées
 * - date de délibération
 * - date de début de transfert
 *
 * @param transferts la liste des `Transfert` que l'on veut regrouper
 * @returns une liste de listes de listes de `Transfert`. La première liste de transfert représente une Commune,
 * on a donc la liste de ceci qui est la liste de toutes les communes, puis une liste de celles-ci qui regroupe les
 * communes qui ont des transferts équivalents selon {@link getTransfertsComparisonBaseWithDates}.
 */
function getGroupedPerimetreCommunesWithDates(
  transferts: Array<Transfert>
): Array<Array<Array<Transfert>>> {
  const transfertsByCommune = arrayUtil.groupByArray(
    transferts,
    (t) => `${t.idCommune}${t.idEpci}`
  );

  const transfertsByCommuneByPortees = arrayUtil.groupByArray(
    transfertsByCommune,
    (_transfertsByCommune) => {
      let comparisonString = "";
      _transfertsByCommune.sort((a, b) =>
        getTransfertsComparisonBaseWithDates(a).localeCompare(
          getTransfertsComparisonBaseWithDates(b)
        )
      );

      _transfertsByCommune.forEach(
        (transfert) => (comparisonString += getTransfertsComparisonBaseWithDates(transfert))
      );

      return comparisonString;
    }
  );

  return transfertsByCommuneByPortees;
}

export const perimetresUtil = {
  getGroupedPerimetreCommunes,
  getGroupedPerimetreCommunesWithDates,
};
