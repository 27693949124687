export interface EauAssGepuContrat {
  code: string;
  portees?: Array<number>;
  porteesAdditionnelles?: Array<number>;
}

export const EauContrats: Array<EauAssGepuContrat> = [
  { code: "CE", portees: [3, 4, 6] },
  {
    code: "AM",
    portees: [3, 4, 6],
    porteesAdditionnelles: [8],
  },
  { code: "AD" },
  {
    code: "ET",
    portees: [3, 4, 6],
    porteesAdditionnelles: [8],
  },
  {
    code: "EX",
    portees: [3, 4, 6],
    porteesAdditionnelles: [5, 10],
  },
  { code: "MO", porteesAdditionnelles: [3] },
  {
    code: "RE",
    portees: [3, 4, 6],
  },
  { code: "AB" },
];

export const AssContrats: Array<EauAssGepuContrat> = [
  { code: "CE", portees: [2, 5, 6] },
  {
    code: "AM",
    portees: [2, 5, 6],
    porteesAdditionnelles: [9],
  },
  { code: "AD", porteesAdditionnelles: [2, 5, 6] },
  { code: "AC", portees: [1, 7] },
  {
    code: "ET",
    portees: [2, 5, 6],
  },
  {
    code: "EX",
    portees: [2, 5, 6],
    porteesAdditionnelles: [10, 14],
  },
  { code: "MO", porteesAdditionnelles: [2, 5, 6] },
  {
    code: "RE",
    portees: [2, 5, 6],
  },
  { code: "AB", porteesAdditionnelles: [2, 5, 6] },
];

export const GepuContrats: Array<EauAssGepuContrat> = [{ code: "0" }];
