import { useCallback } from "react";
import type { ReactNode } from "react";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type { UseFormWatch, Control, UseFormSetValue } from "react-hook-form";
import type { CreateTransfertsModelForm } from "models";
import CommuneAutoCompleteWithErrorBoundary from "components/inputs/CommuneAutoCompleteWithErrorBoundary";
import EpciAutoComplete from "./EpciAutoComplete";
import CommunesInEpciSelect from "./ControlledCommunesInEpciSelect";

interface NewTransfertCollectiviteProps {
  control: Control<CreateTransfertsModelForm>;
  watch: UseFormWatch<CreateTransfertsModelForm>;
  setValue: UseFormSetValue<CreateTransfertsModelForm>;
}

function NewTransfertCollectivite({
  control,
  watch,
  setValue,
}: Readonly<NewTransfertCollectiviteProps>): ReactNode {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const reset = useCallback((): void => {
    setValue("idCommune", "");
    setValue("idEpci", "");
    setValue("communeIds", []);
  }, [setValue]);

  return (
    <Grid item container xs={12} spacing={2}>
      {((!watch("isCommuneDirectTransfert") &&
        (watch("idEpci") === "" || watch("communeIds")?.length === 0)) ||
        (watch("idCommune") === "" && watch("isCommuneDirectTransfert"))) && (
        <Grid item xs={12}>
          <Typography color="error">
            Vous devez choisir soit une commune qui transfère directement, soit au moins une commune
            qui transfère via un EPCI.
          </Typography>
        </Grid>
      )}

      <Grid item container xs={12} md={5.5} spacing={2} alignContent="flex-start">
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={watch("isCommuneDirectTransfert")}
                onChange={(_, checked) => {
                  reset();
                  setValue("isCommuneDirectTransfert", checked);
                }}
              />
            }
            label="Je veux ajouter une commune qui transfère directement"
          />
        </Grid>
        <Grid item xs={12}>
          <CommuneAutoCompleteWithErrorBoundary
            name="idCommune"
            control={control}
            disabled={!watch("isCommuneDirectTransfert")}
            rules={{
              required: watch("isCommuneDirectTransfert") ? "Ce champ est obligatoire" : undefined,
            }}
            variant="id"
          />
        </Grid>
      </Grid>

      <Grid item xs={12} md={1}>
        <Divider orientation={isSmallScreen ? undefined : "vertical"}>
          <Typography>OU</Typography>
        </Divider>
      </Grid>

      <Grid item container xs={12} md={5.5} spacing={2} alignContent="flex-start">
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={!watch("isCommuneDirectTransfert")}
                onChange={(_, checked) => {
                  reset();
                  setValue("isCommuneDirectTransfert", !checked);
                }}
              />
            }
            label="Je veux ajouter un groupe de commune liées à un EPCI"
          />
        </Grid>
        <Grid item xs={12}>
          <EpciAutoComplete
            name="idEpci"
            control={control}
            disabled={watch("isCommuneDirectTransfert")}
            rules={{
              required: !watch("isCommuneDirectTransfert") ? "Ce champ est obligatoire" : undefined,
            }}
          />
          <CommunesInEpciSelect
            name="communeIds"
            control={control}
            idEpci={watch("idEpci")}
            disabled={watch("isCommuneDirectTransfert")}
            rules={{
              required: !watch("isCommuneDirectTransfert")
                ? "Vous devez sélectionner au moins une commune"
                : undefined,
              validate: (value: Array<string>) =>
                watch("isCommuneDirectTransfert") || value.length > 0,
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default NewTransfertCollectivite;
