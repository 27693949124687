enum ToastMessages {
  ERROR = "Oups ! Une erreur est survenue.",
  ERROR_RETRY = "Une erreur est survenue. Merci de réessayer ultérieurement.",
  ERROR_UPDATE = ERROR_RETRY,
  ERROR_NOT_FOUND = "Nous n'avons pas trouvé la ressource à laquelle vous essayez d'accéder.",
  ERROR_FORBIDDEN = "Vous n'avez pas les droits nécessaires pour accéder à cette ressource.",
  ERROR_INTERNAL_SERVER_ERROR = ERROR,
  CREATED_TRANSFERT = "Le transfert a été créé avec succès.",
  DELETED_TRANSFERT = "Le transfert a été supprimé avec succès. Veuillez attendre quelques secondes que la modification se propage.",
  DELETED_TRANSFERT_REPLICATION_SUCCESS = "Le transfert a bien été supprimé.",
  CREATED_MANDAT = "Le mandat a été créé avec succès.",
  CLOTURED_MANDAT = "Le mandat a été clôturé avec succès.",
  DELETED_MANDAT = "Le mandat a été supprimé avec succès.",
  CREATED_PERIMETRE = "Le périmètre a été créé avec succès. Veuillez noter que l'affichage des données peut prendre quelques secondes supplémentaires.",
  MODIFIED_PERIMETRE = "Le périmètre a été modifié avec succès. Veuillez noter que l'affichage des données peut prendre quelques secondes supplémentaires.",
  MERGED_PERIMETRE = "Les périmètres ont été fusionnés avec succès.",
  DISSOLVED_PERIMETRE = "Le périmètre a été dissous avec succès.",
  SEARCH_ERROR = "Une erreur est survenue. Une partie de la recherche peut être impactée. Merci de réessayer ultérieurement.",
  SEARCH_ERROR_COMMUNES = "Une erreur est survenue. La recherche des communes est impactée, mais vous pouvez continuer à utiliser le reste de l'application.",
  SEARCH_ERROR_EPCIS = "Une erreur est survenue. La recherche des epcis est impactée, mais vous pouvez continuer à utiliser le reste de l'application.",
  SEARCH_ERROR_TERRITOIRES = "Une erreur est survenue. La recherche des territoires est impactée, mais vous pouvez continuer à utiliser le reste de l'application.",
  SEARCH_ERROR_PERIMETRES = "Une erreur est survenue. La recherche des périmètres est impactée, mais vous pouvez continuer à utiliser le reste de l'application.",
  SEARCH_ERROR_ELUS = "Une erreur est survenue. La recherche des élus est impactée, mais vous pouvez continuer à utiliser le reste de l'application.",
  LONG_REPLICATION = "La réplication des données prend plus longtemps que prévu. Merci de recharger la page ultérieurement pour accéder aux données mises à jour.",
  CREATED_ETABLISSEMENT_PUBLIC = "La nouvelle adresse a été créée avec succès.",
  DELETED_ETABLISSEMENT_PUBLIC = "L'adresse a été supprimée avec succès.",
}

export default ToastMessages;
