import axios from "axios";
import type { AxiosHeaderValue, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { apiConfig, graphConfig } from "config/app-config";
import { networkService } from "services";

function getConfigWithHeaders(
  scopes: Array<string>,
  additionalHeaders?: Record<string, AxiosHeaderValue | undefined>
): (config: InternalAxiosRequestConfig<unknown>) => Promise<InternalAxiosRequestConfig<unknown>> {
  return async (config: InternalAxiosRequestConfig<unknown>) => {
    const headers = { ...(await networkService.createHeaders(scopes)), ...additionalHeaders };
    Object.entries(headers).forEach(([name, value]) => {
      config.headers.set(name, value);
    });
    return config;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getResponseData(response: AxiosResponse<any, any>): any {
  return response?.data?.data ?? response?.data ?? response;
}

// API Collectivités
const { scopes: collectivitesScope, apiName: collectivitesApiName } = apiConfig.collectivitesApi;
const collectivitesApiClient = axios.create({
  baseURL: `${apiConfig.apimBaseUrl}/${collectivitesApiName}`,
  // baseURL: "http://localhost:7108/api",
});
collectivitesApiClient.interceptors.request.use(getConfigWithHeaders(collectivitesScope));
collectivitesApiClient.interceptors.response.use(getResponseData);

// API Élus
const { scopes: elusApiScope, apiName: elusApiName } = apiConfig.elusApi;
const elusApiClient = axios.create({
  baseURL: `${apiConfig.apimBaseUrl}/${elusApiName}`,
  // baseURL: "http://localhost:7108/api",
});
elusApiClient.interceptors.request.use(getConfigWithHeaders(elusApiScope));
elusApiClient.interceptors.response.use(getResponseData);

// API Établissements publics
const { scopes: etaPubScope, apiName: etaPubApiName } = apiConfig.etablissementsPublicsApi;
const etablissementsPublicsApiClient = axios.create({
  baseURL: `${apiConfig.apimBaseUrl}/${etaPubApiName}`,
  // baseURL: "http://localhost:7108/api",
});
etablissementsPublicsApiClient.interceptors.request.use(getConfigWithHeaders(etaPubScope));
etablissementsPublicsApiClient.interceptors.response.use(getResponseData);

// API Graph
const graphApiName = graphConfig.graphEndpoint;
const graphApiScopes = apiConfig.azureAd.scopes;
const graphApiClient = axios.create({ baseURL: graphApiName });
graphApiClient.interceptors.request.use(
  getConfigWithHeaders(graphApiScopes, { ConsistencyLevel: "eventual" })
);

export { collectivitesApiClient, elusApiClient, etablissementsPublicsApiClient, graphApiClient };
