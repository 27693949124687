import type { ReactNode } from "react";
import { Grid, Typography } from "@mui/material";
import type { GridProps, SxProps } from "@mui/material";
import { useSearch } from "providers";
import { SearchResult } from "./";

interface BestSearchResultsProps extends GridProps {
  sx?: SxProps;
}

/**
 * Affiche les meilleurs résultats de la recherche.
 * A entourer d'un SearchProvider.
 * @param sx?: SxProps
 */
export function BestSearchResults({ sx, ...props }: Readonly<BestSearchResultsProps>): ReactNode {
  const { bestResults } = useSearch();

  if (bestResults.length === 0) {
    return null;
  }

  return (
    <Grid {...props} container spacing={1}>
      <Grid item container xs={12}>
        <Typography>
          <Typography component="span" fontWeight="600">
            {bestResults.length > 1 ? "Meilleurs résultats" : "Meilleur résultat"}
          </Typography>
        </Typography>
      </Grid>

      <Grid item container xs={12} spacing={1}>
        {bestResults.map((f) => (
          <SearchResult key={f.item.key} result={f} />
        ))}
      </Grid>
    </Grid>
  );
}
