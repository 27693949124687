import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import type { Personne } from "models";
import type Fonctions from "constants/Fonctions";
import ElusList from "./ElusList";
import { elusUtil } from "utils";
import type { SdeappsError } from "utils/errorHandling";
import ElusListSkeleton from "./ElusListSkeleton";
import InformationsError from "components/Errors/InformationsError";

interface FilteredElusListProps {
  elus: Array<Personne>;
  fonctions: Array<Fonctions> | Fonctions;
  codeTerritoire: string;
  isLoading?: boolean;
  error?: SdeappsError;
}

function FilteredElusList({
  elus,
  fonctions,
  codeTerritoire,
  isLoading = false,
  error,
}: Readonly<FilteredElusListProps>): ReactNode {
  const [realFonctions, setRealFonctions] = useState<Array<Fonctions>>([]);
  const [filteredElus, setFilteredElus] = useState<Array<Personne>>([]);

  useEffect(() => {
    setRealFonctions(Array.isArray(fonctions) ? fonctions : [fonctions]);
  }, [fonctions]);

  useEffect(() => {
    setFilteredElus(
      [...elus]
        .filter((elu: Personne) =>
          elu.mandats?.some(
            (m) =>
              (realFonctions?.includes(m.idFonction as Fonctions) ?? false) &&
              m.idTerritoire === codeTerritoire
          )
        )
        .sort(elusUtil.sortByName)
    );
  }, [codeTerritoire, elus, realFonctions]);

  if (isLoading) {
    return <ElusListSkeleton />;
  }

  if (error != null) {
    return <InformationsError />;
  }

  return <ElusList elus={filteredElus} />;
}

export default FilteredElusList;
