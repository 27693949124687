import { Link, Skeleton, Typography } from "@mui/material";
import { type ReactElement } from "react";

interface TelephoneSpanProps {
  telephone: string | null | undefined;
  label?: string;
  hasLink?: boolean;
  isLoading?: boolean;
  error?: boolean;
}

function TelephoneSpan({
  telephone,
  label,
  hasLink = false,
  isLoading = false,
  error = false,
}: Readonly<TelephoneSpanProps>): ReactElement {
  if (error) {
    return <Typography component="span">Impossible de charger le numéro de téléphone</Typography>;
  }

  if (isLoading) {
    return (
      <Typography component="span">
        <Skeleton sx={{ maxWidth: "50%" }} />
      </Typography>
    );
  }

  if (telephone == null || telephone === "") {
    return <Typography component="span">{label} non renseigné</Typography>;
  }

  return (
    <Typography>
      {label}{" "}
      {hasLink ? (
        <Link target="_blank" href={"tel:" + telephone.trim()}>
          {telephone}
        </Link>
      ) : (
        <Typography>{telephone}</Typography>
      )}
    </Typography>
  );
}

export default TelephoneSpan;
