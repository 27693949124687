import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import type { Personne, Mandat } from "models";
import ElusList from "./ElusList";
import { elusUtil } from "utils";
import Fonctions from "constants/Fonctions";

interface ConseilMunicipalInformationsProps {
  elus: Array<Personne>;
}

function conseilMunicipalFilter(elu: Personne): boolean {
  return (
    elu?.mandats?.some(
      (m: Mandat) =>
        m.idFonction === Fonctions.MAIRE ||
        m.idFonction === Fonctions.ADJOINT_MAIRE ||
        m.idFonction === Fonctions.CONSEILLER_MUNICIPAL
    ) ?? false
  );
}

function ConseilMunicipalInformations({
  elus,
}: Readonly<ConseilMunicipalInformationsProps>): ReactNode {
  const [conseilMunicipal, setConseilMunicipal] = useState<Array<Personne>>([]);
  const [isDisplayed, setIsDisplayed] = useState(false);

  useEffect(() => {
    const _conseilMunicipal = [...elus].filter(conseilMunicipalFilter).sort(elusUtil.sortByName);
    setConseilMunicipal(_conseilMunicipal);
  }, [elus]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setIsDisplayed(event.target.checked);
  }

  return (
    <Grid item container xs={12} spacing={2} alignItems="center">
      <Grid item xs>
        <Typography>
          Conseil Municipal:{" "}
          <Typography component="span" fontWeight="500">
            {conseilMunicipal.length}
          </Typography>{" "}
          membre
          {conseilMunicipal.length > 1 ? "s" : ""}
        </Typography>
      </Grid>
      <Grid item xs="auto">
        <FormControlLabel
          control={<Switch checked={isDisplayed} onChange={handleChange} />}
          label="Afficher"
        />
      </Grid>
      {isDisplayed && <ElusList elus={conseilMunicipal} />}
    </Grid>
  );
}

export default ConseilMunicipalInformations;
