import { Grid, Skeleton } from "@mui/material";
import { type ReactElement } from "react";

function EtablissementPublicSkeleton(): ReactElement {
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12}>
        <Skeleton variant="text" animation="wave" sx={{ height: "30px", width: "100%" }} />
      </Grid>
      <Grid item container xs={12} gap={1}>
        <Skeleton variant="text" animation="wave" sx={{ height: "30px", width: "100%" }} />
      </Grid>
      <Grid item container xs={12} gap={1}>
        <Skeleton variant="text" animation="wave" sx={{ height: "30px", width: "100%" }} />
      </Grid>
      <Grid item container xs={12} gap={1}>
        <Skeleton variant="text" animation="wave" sx={{ height: "30px", width: "100%" }} />
      </Grid>
    </Grid>
  );
}

export default EtablissementPublicSkeleton;
