import { fileUtil } from "utils";
import { typeEtatsNomFichier } from "constants/EtatPrefecture";
import type { EtatPrefectureType } from "constants/EtatPrefecture";
import { collectivitesApiClient } from "./apiClients";
import { Endpoints } from "constants/endpoints";

function getTypeFileNameOrDefault(etat: EtatPrefectureType): string {
  return typeEtatsNomFichier.get(etat) ?? `${etat.toLocaleLowerCase()}.docx`;
}

async function generateFichierEtatPrefecture(etat: EtatPrefectureType): Promise<Blob> {
  const blob = await collectivitesApiClient.post<Blob, Blob>(
    Endpoints.GENERER_ETATS_PREFECTURE,
    { TypeEtatPrefecture: etat },
    { responseType: "blob" }
  );

  fileUtil.downloadStreamAsFile(blob, getTypeFileNameOrDefault(etat), blob.type);
  return blob;
}

export const etatPrefectureService = {
  generateFichierEtatPrefecture,
};
