import { Grid, Skeleton, type SxProps } from "@mui/material";
import { type ReactElement } from "react";

interface ListSkeletonProps {
  lines?: number;
  sx?: SxProps;
}

function ListSkeleton({ lines = 4, sx }: Readonly<ListSkeletonProps>): ReactElement {
  return (
    <Grid item xs={12}>
      {[...Array(lines).keys()].map((i) => (
        <Skeleton variant="text" animation="wave" key={i} sx={sx} />
      ))}
    </Grid>
  );
}

export default ListSkeleton;
