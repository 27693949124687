import { Grid, Skeleton } from "@mui/material";
import { type ReactElement } from "react";

interface ListSkeletonProps {
  lines?: number;
}

function InputSkeleton({ lines = 1 }: Readonly<ListSkeletonProps>): ReactElement {
  return (
    <Grid item xs={12}>
      {[...Array(lines).keys()].map((i) => (
        <Skeleton key={i} variant="rounded" sx={{ height: "56px" }} />
      ))}
    </Grid>
  );
}

export default InputSkeleton;
