import { useEffect, useState } from "react";
import { matchRoutes, useLocation } from "react-router-dom";
import { routes } from "config/app-config";

export function useCurrentPathRoute(): string | undefined {
  const [path, setPath] = useState<string | undefined>(undefined);
  const location = useLocation();
  useEffect(() => {
    const match = matchRoutes(routes, location);
    setPath(match != null ? match[0]?.route?.path : undefined);
  }, [location]);

  return path;
}
