import { useCallback } from "react";
import type { ReactElement } from "react";
import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import ToastMessages from "constants/ToastMessages";
import { enqueueSnackbar } from "notistack";
import { useErrorHandler } from "utils/errorHandling";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { etatPrefectureService } from "services";
import type { EtatPrefectureType } from "constants/EtatPrefecture";

interface IEtatPrefectureButtonProps {
  libelle: string;
  type: EtatPrefectureType;
}
function EtatPrefectureButton({
  libelle,
  type,
}: Readonly<IEtatPrefectureButtonProps>): ReactElement {
  const { catchErrors, isLoading } = useErrorHandler({
    dontThrow: true,
    defaultIsLoading: false,
    default: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR_RETRY,
      });
    },
  });

  const generateFile = useCallback(() => {
    async function generateAndDownloadFile(): Promise<Blob> {
      return etatPrefectureService.generateFichierEtatPrefecture(type);
    }
    void catchErrors(generateAndDownloadFile);
  }, [type, catchErrors]);

  return (
    <Grid item>
      <LoadingButton
        size="small"
        onClick={generateFile}
        loading={isLoading}
        variant="text"
        color="inherit"
        loadingPosition="start"
        startIcon={<FileDownloadIcon />}>
        {libelle}
      </LoadingButton>
    </Grid>
  );
}

export default EtatPrefectureButton;
