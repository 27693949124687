import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Grid } from "@mui/material";
import type { Personne, Commune, Mandat } from "models";
import EluCommuneInformations from "./EluCommuneInformations";
import { elusUtil } from "utils";
import Fonctions from "constants/Fonctions";

interface ElusCommuneInformationsProps {
  elus: Array<Personne>;
  commune: Commune;
}

function isPremierAdjoint(mandat: Mandat): boolean {
  return (
    mandat.idFonction === Fonctions.ADJOINT_MAIRE &&
    (mandat?.libelleFonction?.startsWith("1er") ?? false)
  );
}

function ElusCommuneInformations({
  elus,
  commune,
}: Readonly<ElusCommuneInformationsProps>): ReactNode {
  const [importantElus, setImportantElus] = useState<Array<Personne>>([]);

  useEffect(() => {
    /**
     * Filtre les mandats que l'on souhaite afficher: Maire, 1er adjoint au maire, délégué à l'AG
     * @param mandat
     * @returns true si le mandat correspond
     */
    function importantMandatsFilter(mandat: Mandat): boolean {
      return (
        (mandat.idFonction === Fonctions.MAIRE ||
          isPremierAdjoint(mandat) ||
          mandat.idFonction === Fonctions.DELEGUE_ASSEMBLEE_GENERALE) &&
        mandat.codeCollectivite === commune.id
      );
    }

    const activeElus = elusUtil.getActiveElusAndMandats(elus);
    // On filtre les mandats des Personnes pour ne garder que ceux qui nous intéressent
    const _importantElus = elusUtil.filterAndBoilElusMandatsDown(
      activeElus,
      importantMandatsFilter
    );

    _importantElus
      .sort(elusUtil.sortByName)
      .sort(elusUtil.sortElusByInstitutionnelMandatImportance);

    setImportantElus(_importantElus);
  }, [commune.id, elus]);

  return (
    <Grid item container xs={12} alignContent="flex-start" spacing={2}>
      {importantElus.map((p) => {
        return (
          <Grid item container xs={12} key={p.id}>
            <EluCommuneInformations elu={p} />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default ElusCommuneInformations;
