import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import type { ReactNode } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import type { Transfert } from "models";
import GceSousCompetencesAccordionDetails from "./GceSousCompetencesAccordionDetails";
import GceSousCompetencesAccordionDetailsWithDates from "./GceSousCompetencesAccordionDetailsWithDates";
import { useDisplayDates } from "hooks";

interface GceSousCompetencesAccordionProps {
  transfertsByBassinVersants: Array<Array<Transfert>>;
  summary: ReactNode;
  defaultExpanded?: boolean;
}

function GceSousCompetencesAccordion({
  transfertsByBassinVersants,
  summary,
  defaultExpanded = false,
}: Readonly<GceSousCompetencesAccordionProps>): ReactNode {
  const { isDisplayingDates } = useDisplayDates();

  return (
    <Grid item xs={12}>
      <Accordion sx={{ width: "100%" }} defaultExpanded={defaultExpanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ flexDirection: "row-reverse" }}>
          {summary}
        </AccordionSummary>
        <AccordionDetails>
          {isDisplayingDates ? (
            <GceSousCompetencesAccordionDetailsWithDates
              transfertsByBassinVersants={transfertsByBassinVersants}
            />
          ) : (
            <GceSousCompetencesAccordionDetails
              transfertsByBassinVersants={transfertsByBassinVersants}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}

export default GceSousCompetencesAccordion;
