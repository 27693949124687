import { Grid } from "@mui/material";
import { type ReactElement, type PropsWithChildren } from "react";

function BlueChip({ children }: Readonly<PropsWithChildren>): ReactElement {
  return (
    <Grid
      item
      container
      xs="auto"
      sx={{
        backgroundColor: "info.light",
        padding: 0.5,
        borderRadius: 1,
      }}
      gap={1}
      alignItems="center">
      {children}
    </Grid>
  );
}

export default BlueChip;
