import type { ReactNode } from "react";
import HorairesTypography from "components/Typography/HorairesTypography";
import type { Horaire } from "models";
import InformationsError from "components/Errors/InformationsError";
import ListSkeleton from "components/ListSkeleton";

interface HorairesProps {
  horaires: Array<Horaire> | undefined;
  isLoading?: boolean;
  error?: boolean;
}

function Horaires({
  horaires,
  isLoading = false,
  error = false,
}: Readonly<HorairesProps>): ReactNode {
  if (isLoading) {
    return <ListSkeleton lines={6} sx={{ height: "25px", width: "80%" }} />;
  }

  if (error) {
    return <InformationsError />;
  }

  if (horaires == null || horaires.length === 0) {
    return null;
  }

  return <HorairesTypography horaires={horaires} />;
}

export default Horaires;
