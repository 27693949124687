import type { ReactNode } from "react";
import { TableCell, TableRow, Typography } from "@mui/material";
import CommuneLink from "components/Links/CommuneLink";
import EluLink from "components/Links/EluLink";
import type Fonctions from "constants/Fonctions";
import type { Commune, Personne } from "models";

interface PerimetreDeleguesSdeaRowProps {
  elu: Personne;
  communes?: Array<Commune>;
  importantFonctions?: Array<Fonctions>;
}

function PerimetreDeleguesSdeaRow({
  elu,
  communes,
  importantFonctions = [],
}: Readonly<PerimetreDeleguesSdeaRowProps>): ReactNode {
  return (
    <TableRow>
      <TableCell>
        <EluLink
          elu={elu}
          sx={{
            fontWeight:
              elu.mandats?.some((mandat) =>
                importantFonctions?.includes(mandat.idFonction as Fonctions)
              ) ?? false
                ? "700"
                : undefined,
          }}
        />
      </TableCell>
      <TableCell>
        {elu.mandats?.map((mandat) => (
          <Typography
            key={`${elu.id}${mandat.idFonction}`}
            fontWeight={
              importantFonctions?.includes(mandat.idFonction as Fonctions) ? "700" : undefined
            }>
            {mandat.libelleFonction}
          </Typography>
        ))}
      </TableCell>
      <TableCell>
        {elu.mandats?.map((mandat) => (
          <Typography key={`${elu.id}${mandat.idFonction}`}>
            {mandat.codeCollectivite == null ? (
              <>&nbsp;</>
            ) : (
              <CommuneLink
                id={mandat.codeCollectivite}
                libelle={communes?.find((c) => c.id === mandat.codeCollectivite)?.libelle}
              />
            )}
          </Typography>
        ))}
      </TableCell>
    </TableRow>
  );
}

export default PerimetreDeleguesSdeaRow;
