import type { ReactNode } from "react";
import { Typography } from "@mui/material";
import CommuneLink from "components/Links/CommuneLink";
import EpciLink from "components/Links/EpciLink";
import type { Mandat } from "models";
import { epcisUtil } from "utils";

function getDepartementMandatLibelle(mandat: Mandat): string {
  if (mandat.codeCollectivite === "67" || mandat.codeCollectivite === "68") {
    return mandat.codeCollectivite === "67"
      ? `${mandat.libelleCollectivite} (Bas-Rhin)`
      : `${mandat.libelleCollectivite} (Haut-Rhin)`;
  }
  return `Département de la ${mandat.libelleCollectivite}`;
}

interface GetInstitutionnelMandatCollectiviteLinkProps {
  mandatInstitutionnel: Mandat;
  noLink?: boolean;
}

function CollectiviteLinkInstitutionnelMandat({
  mandatInstitutionnel: mandat,
  noLink = false,
}: Readonly<GetInstitutionnelMandatCollectiviteLinkProps>): ReactNode {
  if (mandat.libelleCollectivite == null) {
    return null;
  }

  if (mandat.codeCollectivite != null) {
    switch (mandat.typeMandat) {
      case "commune":
        return (
          <CommuneLink
            id={mandat.codeCollectivite}
            libelle={mandat.libelleCollectivite.trim()}
            noLink={noLink}
          />
        );
      case "epci":
        return (
          <EpciLink
            id={mandat.codeCollectivite}
            libelle={mandat.libelleCollectivite.trim()}
            noLink={noLink}
          />
        );
      case "departement":
        return <Typography component="span">{getDepartementMandatLibelle(mandat)}</Typography>;
      case "region":
        return <Typography component="span">Région {mandat.libelleCollectivite.trim()}</Typography>;
      default:
        return <Typography component="span">{mandat.libelleCollectivite.trim()}</Typography>;
    }
  } else {
    return (
      <Typography component="span">
        {epcisUtil.ToFullName(mandat.libelleCollectivite.trim())}
      </Typography>
    );
  }
}

export default CollectiviteLinkInstitutionnelMandat;
