import { useState, useEffect } from "react";
import type { ReactNode } from "react";
import { FormHelperText, Grid } from "@mui/material";
import type { Commune } from "models";
import { communesService } from "services";
import type { ControlledInput } from "utils";
import { useErrorHandler, withComponentErrorBoundary } from "utils/errorHandling";
import { arrayUtil } from "@sdeapps/react-core";
import CheckboxForArray from "./CheckboxForArray";
import { useController } from "react-hook-form";
import type { FieldValues, FieldPath } from "react-hook-form";
import LoadingScreen from "components/Template/LoadingScreen";

interface ControlledCommunesInEpciSelectProps<T extends FieldValues, Name extends FieldPath<T>>
  extends ControlledInput<T, Name> {
  idEpci?: string;
  disabled?: boolean;
}

function ControlledCommunesInEpciSelect<T extends FieldValues, Name extends FieldPath<T>>({
  name,
  control,
  idEpci,
  rules,
  disabled = false,
}: Readonly<ControlledCommunesInEpciSelectProps<T, Name>>): ReactNode {
  const [epciCommunes, setEpciCommunes] = useState<Array<Commune>>([]);

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control, rules });

  const { catchErrors, isLoading } = useErrorHandler({
    defaultIsLoading: false,
  });

  useEffect(() => {
    async function getEpcis(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const _communes = await communesService.getByEpci(idEpci!);

      setEpciCommunes(_communes);
    }
    setEpciCommunes([]);
    if (idEpci != null && idEpci !== "") {
      void catchErrors(getEpcis);
    }
  }, [catchErrors, idEpci]);

  if (isLoading) {
    // TODO proper skeleton
    return <LoadingScreen />;
  }

  return (
    <Grid item xs={12} container spacing={2}>
      {arrayUtil.separateIntoNArrays(epciCommunes, 2).map((communes, i) => (
        <Grid item container xs={12} sm={6} key={communes[0]?.id ?? i}>
          {communes.map((commune) => (
            <Grid item xs={12} key={commune.id}>
              <CheckboxForArray
                value={value}
                onChange={onChange}
                codes={commune.id}
                label={commune.libelle}
                disabled={disabled}
              />
            </Grid>
          ))}
        </Grid>
      ))}
      {error != null && <FormHelperText error>{error.message}</FormHelperText>}
    </Grid>
  );
}

const ControlledCommunesInEpciSelectWithErrorBoundary = withComponentErrorBoundary(
  ControlledCommunesInEpciSelect
);

export default ControlledCommunesInEpciSelectWithErrorBoundary;
