import { FormHelperText, Grid, Typography } from "@mui/material";
import type { ReactNode } from "react";
import { Controller } from "react-hook-form";
import type { Control } from "react-hook-form";
import type { CreateTransfertsModelForm } from "models";
import Competence from "constants/Competence";
import NewTransfertPorteesEauAss from "./NewTransfertPorteesEauAss";
import NewTransfertPorteesGce from "./NewTransfertPorteesGce";
import NewTransfertPorteesGepu from "./NewTransfertPorteesGepu";

interface NewTransfertPorteesProps {
  control: Control<CreateTransfertsModelForm>;
  competence?: Competence;
}

function NewTransfertPortees({
  control,
  competence,
}: Readonly<NewTransfertPorteesProps>): ReactNode {
  if (competence == null) {
    return (
      <Grid item container xs={12} spacing={2}>
        <Typography color="error">Veuillez d'abord sélectionner un périmètre plus haut.</Typography>
      </Grid>
    );
  }

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={12}>
        {competence === Competence.GEPU && (
          <Controller
            name="codeTransfertsEauAssGepu"
            control={control}
            rules={{
              required: "Il est obligatoire de sélectionner la compétence",
              validate: (value: Array<string>) => value.length > 0,
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Grid container spacing={2}>
                <NewTransfertPorteesGepu value={value} onChange={onChange} />
                {error != null && <FormHelperText error>{error.message}</FormHelperText>}
              </Grid>
            )}
          />
        )}
        {competence === Competence.GCE && (
          <Controller
            name="codeTransfertsGce"
            control={control}
            rules={{
              required: "Il est obligatoire de sélectionner au moins une sous-compétence ou portée",
              validate: (value) => value.length > 0,
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Grid container spacing={2}>
                <NewTransfertPorteesGce value={value} onChange={onChange} competence={competence} />
                {error != null && <FormHelperText error>{error.message}</FormHelperText>}
              </Grid>
            )}
          />
        )}
        {[Competence.EAU, Competence.ASS].includes(competence) && (
          <Controller
            name="codeTransfertsEauAssGepu"
            control={control}
            rules={{
              required: "Il est obligatoire de sélectionner au moins une sous-compétence ou portée",
              validate: (value: Array<string>) => value.length > 0,
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Grid container spacing={2}>
                <NewTransfertPorteesEauAss
                  value={value}
                  onChange={onChange}
                  competence={competence}
                />
                {error != null && <FormHelperText error>{error.message}</FormHelperText>}
              </Grid>
            )}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default NewTransfertPortees;
