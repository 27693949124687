import { EtablissementPublicSource } from "models";
import type { EtablissementPublic } from "models";

function getEtablissementPublicFromSource(
  etablissementsPublics: Array<EtablissementPublic>
): [openData: EtablissementPublic | undefined, sdea: EtablissementPublic | undefined] {
  const openData = etablissementsPublics.find(
    (e) => e.source === EtablissementPublicSource.OPEN_DATA
  );

  return [openData, etablissementsPublics.find((e) => e.source === EtablissementPublicSource.SDEA)];
}

export const etablissementUtil = {
  getEtablissementPublicFromSource,
};
