import type { ReactNode } from "react";
import { Menu } from "@mui/material";
import type { MenuProps, PaperProps } from "@mui/material";

const paperPropsTriangle: PaperProps = {
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgb(255,255,255))",
    mt: 1.5,
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 24,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

interface ContextualMenuSpecificProps extends MenuProps {
  /** default: `false` Si vrai, ferme le menu contextuel au clic sur un item */
  closeOnClick?: boolean;
}

/**
 * Menu contextuel du profil utilisateur
 * @param closeOnClick boolean default `false` Si vrai, ferme le menu contextuel au clic sur un item
 */
function ContextualMenu({
  children,
  closeOnClick = false,
  ...menuProps
}: Readonly<ContextualMenuSpecificProps>): ReactNode {
  const { onClose } = menuProps;
  return (
    <Menu
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      slotProps={{ paper: paperPropsTriangle }}
      onClick={(e) => {
        if (onClose != null && closeOnClick) {
          onClose(e, "backdropClick");
        }
      }}
      {...menuProps}>
      {children}
    </Menu>
  );
}

export default ContextualMenu;
