import type { ReactNode } from "react";
import { TableCell, TableRow, Typography } from "@mui/material";
import { dateUtil } from "@sdeapps/react-core";
import CollectiviteLinkInstitutionnelMandat from "components/Informations/Elus/CollectiviteLinkInstitutionnelMandat";
import type { Mandat } from "models";
import { elusUtil } from "utils";

interface MandatInstitutionnelTableRowProps {
  mandat: Mandat;
}

function MandatInstitutionnelTableRow({
  mandat,
}: Readonly<MandatInstitutionnelTableRowProps>): ReactNode {
  return (
    <TableRow>
      <TableCell>
        <Typography>{elusUtil.getLibelleFonction(mandat)}</Typography>
      </TableCell>
      <TableCell>
        <CollectiviteLinkInstitutionnelMandat mandatInstitutionnel={mandat} />
      </TableCell>
      <TableCell width="250px">
        <Typography>
          {mandat.dateDebutFonction != null || mandat.dateDebutMandat != null
            ? `À partir du ${dateUtil.format(
                mandat.dateDebutFonction ?? mandat.dateDebutMandat,
                "dd/MM/yyyy"
              )}`
            : "Non renseignée"}
          {mandat.dateFinMandat != null &&
            ` - jusqu'au ${dateUtil.format(mandat.dateFinMandat, "dd/MM/yyyy")}`}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

export default MandatInstitutionnelTableRow;
