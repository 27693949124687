import { useEffect, useState, useCallback } from "react";
import type { ReactNode } from "react";
import { Link, Typography } from "@mui/material";
import type { LinkProps } from "@mui/material";
import { routesConfig } from "config/app-config";
import { communesService } from "services";
import TypographySkeleton from "./TypographySkeleton";
import { TypeCommuneAssocieeDeleguee } from "models";
import type { CommuneAssocieeDeleguee, Commune } from "models";
import { useErrorHandler } from "utils/errorHandling";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function instanceOfCommuneFusionnee(object: any): object is CommuneAssocieeDeleguee {
  return (
    "type" in object &&
    (object.type === TypeCommuneAssocieeDeleguee.ASSOCIEE ||
      object.type === TypeCommuneAssocieeDeleguee.DELEGUEE)
  );
}

type CommuneLinkData1 = {
  commune: Commune | CommuneAssocieeDeleguee;
  id?: never;
  libelle?: never;
  noLink?: boolean;
};

type CommuneLinkData2 = {
  commune?: never;
  id: string;
  libelle?: string;
  noLink?: boolean;
};

type CommuneLinkProps = LinkProps & (CommuneLinkData1 | CommuneLinkData2);

function CommuneLink({
  commune,
  id,
  libelle,
  noLink = false,
  ...linkProps
}: Readonly<CommuneLinkProps>): ReactNode {
  const [libelleCommune, setLibelleCommune] = useState("");
  const [link, setLink] = useState("");

  const { catchErrors, isLoading, setIsLoading } = useErrorHandler({
    dontThrow: true,
    default: () => {
      setLibelleAndLink("Commune inconnue", id ?? "");
    },
  });

  const setLibelleAndLink = useCallback(
    (libelle: string, link: string): void => {
      setLibelleCommune(libelle);
      setLink(link);
      setIsLoading(false);
    },
    [setIsLoading]
  );

  useEffect(() => {
    async function getCommune(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const c = await communesService.getById(id!);
      setLibelleAndLink(c.libelle, routesConfig.commune.getParameterPath(c.id));
    }

    if (commune != null) {
      setLibelleAndLink(
        commune.libelle,
        instanceOfCommuneFusionnee(commune)
          ? routesConfig.communeAssocieeDeleguee.getParameterPath(commune.id)
          : routesConfig.commune.getParameterPath(commune.id)
      );
    } else if (libelle != null) {
      setLibelleAndLink(libelle, routesConfig.commune.getParameterPath(id));
    } else {
      void catchErrors(getCommune);
    }
  }, [catchErrors, commune, id, libelle, setLibelleAndLink]);

  if (isLoading) {
    return <TypographySkeleton sx={{ maxWidth: 175 }} />;
  }

  if (noLink) {
    return (
      <Typography component="span" color="primary" fontWeight={500}>
        {libelleCommune}
      </Typography>
    );
  } else {
    return (
      <Link href={link} {...linkProps}>
        {libelleCommune}
      </Link>
    );
  }
}

export default CommuneLink;
