import { Fragment } from "react";
import type { ReactNode } from "react";
import { Grid, Typography } from "@mui/material";
import { arrayUtil } from "@sdeapps/react-core";
import type { Transfert } from "models";
import GceContrats from "constants/GceContrats";
import { porteesUtil } from "utils";

interface GceSousCompetencesAccordionDetailsProps {
  transfertsByBassinVersants: Array<Array<Transfert>>;
}

function GceSousCompetencesAccordionDetails({
  transfertsByBassinVersants,
}: Readonly<GceSousCompetencesAccordionDetailsProps>): ReactNode {
  return (
    <Grid container spacing={2}>
      {transfertsByBassinVersants.map((transfertByBassinVersant) => (
        <Fragment key={transfertByBassinVersant[0].id}>
          <Grid item xs={12}>
            <Typography fontWeight={600}>
              {transfertByBassinVersant[0].bassinVersant != null
                ? `Bassin versant ${transfertByBassinVersant[0].bassinVersant} `
                : "Tous les bassins versants"}
            </Typography>
          </Grid>
          {arrayUtil
            .dedupArray(transfertByBassinVersant, (a) => a.code)
            // dedup par code d'alinéa - ex: Wingersheim-les-quatre-bans
            .map((transfert) => {
              const gceContrat = GceContrats.find(
                (c) => c.code === porteesUtil.getSousCompetenceId(transfert.code)
              );
              return (
                <Grid item xs={12} key={`${transfert.id}`}>
                  <Typography>
                    <Typography component="span" color="gce.dark" fontWeight={800}>
                      {gceContrat?.numero}°
                    </Typography>{" "}
                    {gceContrat?.label}
                  </Typography>
                </Grid>
              );
            })}
        </Fragment>
      ))}
    </Grid>
  );
}

export default GceSousCompetencesAccordionDetails;
