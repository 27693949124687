enum Fonctions {
  MAIRE = "30",
  ADJOINT_MAIRE = "33",
  CONSEILLER_MUNICIPAL = "34",
  DELEGUE_ASSEMBLEE_GENERALE = "19",
  CONSEILLER_COMMUNAUTAIRE = "27",
  PRESIDENT_EPCI = "22",
  VICE_PRESIDENT_EPCI = "23",
  PRESIDENT_COMMISSION_LOCALE = "20",
  SUPPLEANT_PRESIDENT_COMMISSION_LOCALE = "64",
  PRESIDENT_TERRITOIRE = "60",
  SUPPLEANT_PRESIDENT_TERRITOIRE = "67",
  PRESIDENT_COMMISSION_MARCHES = "61",
  SUPPLEANT_PRESIDENT_COMMISSION_MARCHES = "62",
  COMMISSION_PERMANENTE = "8",
  CONSEIL_ADMINISTRATION = "52",
  CONSEIL_TERRITORIAL = "18",
}

export default Fonctions;

export const FonctionNames: Record<Fonctions, { M: string; F: string }> = {
  [Fonctions.MAIRE]: { M: "Maire", F: "Maire" },
  [Fonctions.ADJOINT_MAIRE]: { M: "Adjoint au Maire", F: "Adjointe au Maire" },
  [Fonctions.CONSEILLER_MUNICIPAL]: { M: "Conseiller Municipal", F: "Conseillère Municipale" },
  [Fonctions.DELEGUE_ASSEMBLEE_GENERALE]: {
    M: "Délégué à l'Assemblée Générale",
    F: "Déléguée à l'Assemblée Générale",
  },
  [Fonctions.CONSEILLER_COMMUNAUTAIRE]: {
    M: "Conseiller Communautaire",
    F: "Conseillère Communautaire",
  },
  [Fonctions.PRESIDENT_EPCI]: { M: "Président", F: "Présidente" },
  [Fonctions.VICE_PRESIDENT_EPCI]: { M: "Vice-Président", F: "Vice-Présidente" },
  [Fonctions.PRESIDENT_COMMISSION_LOCALE]: {
    M: "Président de la Commission Locale",
    F: "Présidente de la Commission Locale",
  },
  [Fonctions.SUPPLEANT_PRESIDENT_COMMISSION_LOCALE]: {
    M: "Suppléant au Président de la Commission Locale",
    F: "Suppléante au Président de la Commission Locale",
  },
  [Fonctions.PRESIDENT_TERRITOIRE]: { M: "Président de Territoire", F: "Présidente de Territoire" },
  [Fonctions.SUPPLEANT_PRESIDENT_TERRITOIRE]: {
    M: "Suppléant au Président de Territoire",
    F: "Suppléante au Président de Territoire",
  },
  [Fonctions.PRESIDENT_COMMISSION_MARCHES]: {
    M: "Président de la Commission des Marchés",
    F: "Présidente de la Commission des Marchés",
  },
  [Fonctions.SUPPLEANT_PRESIDENT_COMMISSION_MARCHES]: {
    M: "Suppléant au Président de la Commission des Marchés",
    F: "Suppléante au Président de la Commission des Marchés",
  },
  [Fonctions.COMMISSION_PERMANENTE]: {
    M: "Représentant à la Commission Permanente",
    F: "Représentante à la Commission Permanente",
  },
  [Fonctions.CONSEIL_ADMINISTRATION]: { M: "Administrateur du SDEA", F: "Administratrice du SDEA" },
  [Fonctions.CONSEIL_TERRITORIAL]: { M: "Conseiller Territorial", F: "Conseillère territoriale" },
};
