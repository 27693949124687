import { useState, useEffect } from "react";
import type { ReactNode } from "react";
import { Autocomplete, TextField, Typography, createFilterOptions } from "@mui/material";
import { stringUtil } from "@sdeapps/react-core";
import { epcisUtil } from "utils";
import { useErrorHandler, withComponentErrorBoundary } from "utils/errorHandling";
import InputSkeleton from "components/InputSkeleton";
import { epcisService } from "services";
import type { Epci } from "models";

const filterOptions = createFilterOptions({
  trim: true,
  stringify: (option: Epci) => stringUtil.normalize(epcisUtil.ToFullName(option.libelle)),
});

interface EpciAutocompleteProps {
  onChange: (selectedId?: Epci | null) => void;
}
function EpciAutocomplete({ onChange }: Readonly<EpciAutocompleteProps>): ReactNode {
  const [epcis, setEpcis] = useState<Array<Epci>>([]);
  const { catchErrors, isLoading } = useErrorHandler();

  useEffect(() => {
    async function getEpcis(): Promise<void> {
      const _epcis = await epcisService.getAll();

      _epcis.sort((a, b) =>
        stringUtil
          .normalize(epcisUtil.ToFullName(a.libelle))
          .localeCompare(stringUtil.normalize(epcisUtil.ToFullName(b.libelle)))
      );
      setEpcis(_epcis);
    }

    void catchErrors(getEpcis);
  }, [catchErrors]);

  if (isLoading) {
    return <InputSkeleton />;
  }

  return (
    <Autocomplete
      fullWidth
      autoHighlight
      openOnFocus
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={epcis}
      filterOptions={filterOptions}
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
      isOptionEqualToValue={(v1: Epci, v2: Epci): boolean => v1.id === v2.id}
      getOptionLabel={(epci: Epci): string => epci.libelle}
      renderOption={(props, option) => (
        <Typography component="li" {...props} key={option.id}>
          {epcisUtil.ToFullName(option.libelle)}
        </Typography>
      )}
      renderInput={(params) => <TextField {...params} label="EPCI *" placeholder="Nom de l'EPCI" />}
    />
  );
}

const EpciAutocompleteWithErrorBoundary = withComponentErrorBoundary(EpciAutocomplete);

export default EpciAutocompleteWithErrorBoundary;
