import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Grid } from "@mui/material";
import type { Personne, Mandat } from "models";
import EluLink from "components/Links/EluLink";
import type Competence from "constants/Competence";
import EluInformationsFonction from "./EluInformationsFonction";
import { competencesUtil } from "utils";

interface EluCommuneInformationsProps {
  elu: Personne;
}

const delegueString = "Délégué à l'Assemblée Générale";

type MandatDelegueAssemblee = { competences: Array<Competence> } & Mandat;

function EluCommuneInformations({ elu }: Readonly<EluCommuneInformationsProps>): ReactNode {
  const [mandats, setMandats] = useState<Array<Mandat>>([]);
  const [mandatDelegueAssemblee, setMandatDelegueAssemblee] = useState<MandatDelegueAssemblee>();

  useEffect(() => {
    const _mandats: Array<Mandat> = [];
    let _mandatDelegueAssemblee: MandatDelegueAssemblee | undefined;
    elu?.mandats?.forEach((m) => {
      const competence = m.competence;
      if (m.libelleFonction === delegueString && competence != null) {
        if (_mandatDelegueAssemblee != null) {
          if (!_mandatDelegueAssemblee.competences.includes(competence)) {
            _mandatDelegueAssemblee = {
              ..._mandatDelegueAssemblee,
              competences: [..._mandatDelegueAssemblee.competences, competence],
            };
          }
        } else {
          _mandatDelegueAssemblee = { ...m, competences: [competence] };
        }
      } else {
        _mandats.push(m);
      }
    });
    _mandatDelegueAssemblee?.competences.sort(competencesUtil.sortCompetences);
    setMandats(_mandats);
    setMandatDelegueAssemblee(_mandatDelegueAssemblee);
  }, [elu]);

  return (
    <Grid item container xs={12} alignContent="flex-start">
      <Grid item xs={12}>
        <EluLink elu={elu} fontWeight="600" />
      </Grid>

      {mandats?.map((m) => {
        if (m.libelleFonction == null) {
          return null;
        }
        return <EluInformationsFonction key={`${m.libelleFonction}${m.idPersonne}`} mandat={m} />;
      })}

      {mandatDelegueAssemblee?.libelleFonction != null && (
        <EluInformationsFonction
          mandat={mandatDelegueAssemblee}
          competences={mandatDelegueAssemblee.competences}
        />
      )}
    </Grid>
  );
}

export default EluCommuneInformations;
