import { useEffect, useState } from "react";
import type { ReactElement } from "react";
import { Grid, Skeleton, Typography } from "@mui/material";
import { competencesAssociationList } from "constants/CompetenceMap";
import { PerimetreIcon, CommuneIcon } from "icons";
import type { Territoire, Commune, Perimetre } from "models";
import BlueChip from "./BlueChip";
import SigLink from "components/Links/SigLink";
import { useErrorHandler, withComponentErrorBoundary } from "utils/errorHandling";
import { communesService } from "services";

interface TerritoireInformationsSdeaProps {
  territoire: Territoire;
  perimetres: Array<Perimetre>;
}

function TerritoireInformationsSdea({
  territoire,
  perimetres,
}: Readonly<TerritoireInformationsSdeaProps>): ReactElement {
  const [communes, setCommunes] = useState<Array<Commune>>([]);

  const { catchErrors, isLoading: isCommunesLoading } = useErrorHandler();

  useEffect(() => {
    async function getCommunes(): Promise<void> {
      const communes = await communesService.getByTerritoire(territoire.id);
      setCommunes(communes);
    }

    void catchErrors(getCommunes);
  }, [catchErrors, perimetres, territoire]);

  return (
    <Grid item container xs={12} spacing={2} alignContent="flex-start">
      <Grid item container xs={12} spacing={2}>
        <Grid item container xs="auto" gap={1} alignItems="center">
          <PerimetreIcon />
          <Typography>
            <Typography component="span" color="primary">
              {perimetres.length}
            </Typography>{" "}
            Périmètres
          </Typography>
        </Grid>
        {competencesAssociationList.map(({ icon: Icon, competence }) => {
          const perimetersNumber = perimetres.filter((p) => p.competence === competence).length;
          if (perimetersNumber === 0) return null;
          return (
            <Grid item container xs="auto" key={competence}>
              <BlueChip key={competence}>
                <Icon />
                <Typography>{perimetersNumber}</Typography>
              </BlueChip>
            </Grid>
          );
        })}
      </Grid>
      <Grid item container xs={12}>
        <BlueChip>
          <CommuneIcon />
          <Typography>
            {isCommunesLoading ? (
              <Skeleton sx={{ width: "25px", display: "inline-block" }} />
            ) : (
              communes.length
            )}{" "}
            Communes
          </Typography>
        </BlueChip>
      </Grid>
      <Grid item container xs={12} gap={2} justifyContent="space-between">
        {competencesAssociationList.map(({ competence }) => {
          return perimetres.some((p) => p.competence === competence) ? (
            <SigLink key={competence} collectivite={territoire} competenceTerritoire={competence} />
          ) : undefined;
        })}
      </Grid>
    </Grid>
  );
}

const TerritoireInformationsSdeaWithErrorBoundary = withComponentErrorBoundary(
  TerritoireInformationsSdea
);

export default TerritoireInformationsSdeaWithErrorBoundary;
