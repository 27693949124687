import type { Epci, Perimetre, Transfert, TransfertViewModel } from "models";
import { epcisService, perimetresService, transfertsService } from "services";
import { porteesUtil } from "utils";

/**
 * Enrichit une liste de transferts avec les informations des Epci et des
 * Périmètres liés pour obtenir un modèle pratique à manipuler.
 * @param idEpci l'id de l'Epci
 * @returns les transferts enrichis
 */
function getFullTransferts(
  transferts: Array<Transfert>,
  perimetres: Array<Perimetre>,
  epcis: Array<Epci>
): Array<TransfertViewModel> {
  const perimetresInconnusIds = new Set<string>();
  const epcisInconnusIds = new Set<string>();

  const _fullTransferts = transferts.map((transfert) => {
    let perimetre = perimetres?.find((p) => p.id === transfert.idPerimetre);
    if (perimetre == null) {
      perimetresInconnusIds.add(transfert.idPerimetre);
      perimetre = {
        id: transfert.idPerimetre,
        libelle: "Périmètre inconnu",
        competence: porteesUtil.getCompetence(transfert.code),
        code: "",
        territoire: { id: "undefined", libelle: "Territoire inconnu" },
      };
    }

    let epci = epcis?.find((e) => {
      return e.id === transfert.idEpci;
    });
    if (transfert.idEpci != null && epci == null) {
      epcisInconnusIds.add(transfert.idEpci);
      epci = {
        id: transfert.idEpci,
        libelle: "EPCI inconnu",
        natureJuridique: "",
        departementSiege: { id: "", libelle: "" },
        regionSiege: { id: "", libelle: "" },
      };
    }

    if (perimetresInconnusIds.size > 0) {
      console.warn(
        `Les périmètres ${[...perimetresInconnusIds].join(
          ", "
        )} de certains transfert n'ont pas été trouvés !`
      );
    }
    if (epcisInconnusIds.size > 0) {
      console.warn(
        `Les epcis ${[...epcisInconnusIds].join(
          ", "
        )} de certains transfert n'ont pas été trouvés !`
      );
    }

    const transfertPratique: TransfertViewModel = {
      ...transfert,
      perimetre,
      epci,
    };

    return transfertPratique;
  });

  return _fullTransferts;
}

/**
 * Cherche les transferts d'une Commune et les enrichit avec les Périmètres et Epcis concernés
 * pour obtenir un modèle pratique à manipuler
 * @param idCommune l'id de la Commune
 * @returns les transferts enrichis
 */
async function getFullTransfertsByCommune(idCommune: string): Promise<Array<TransfertViewModel>> {
  const [_transferts, _perimetres, _epcis] = await Promise.all([
    transfertsService.getByCommune(idCommune),
    perimetresService.getByCommune(idCommune),
    epcisService.getByCommune(idCommune),
  ]);

  return getFullTransferts(_transferts, _perimetres, _epcis);
}

/**
 * Cherche les transferts d'un Epci et les enrichit avec les Périmètres concernés
 * pour obtenir un modèle pratique à manipuler
 * @param idEpci l'id de l'Epci
 * @returns les transferts enrichis
 */
async function getFullTransfertsByEpci(idEpci: string): Promise<Array<TransfertViewModel>> {
  const [_transferts, _perimetres, epci] = await Promise.all([
    transfertsService.getByEpci(idEpci),
    perimetresService.getByEpci(idEpci),
    epcisService.getById(idEpci),
  ]);

  return getFullTransferts(_transferts, _perimetres, [epci]);
}

export const transfertsUtil = {
  getFullTransfertsByCommune,
  getFullTransfertsByEpci,
};
