import type { ReactNode } from "react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { routesConfig } from "config/app-config";
import { SearchResultCard, EluSearchResultCard } from "./";
import type { CollectiviteSearchItem } from "models";
import { CommuneIcon, CollectiviteIcon, PerimetreIcon } from "icons";
import type { FuseResult } from "fuse.js";
import CompetenceChip from "components/Typography/CompetenceChip";

interface SearchResultProps {
  result: FuseResult<CollectiviteSearchItem>;
}

/**
 * Remplit un SearchResultCard avec les bonnes informations en fonction du type de la collectivite passée en props.
 * @param collectivite Collectivite
 */
export function SearchResult({
  result: { item, matches },
}: Readonly<SearchResultProps>): ReactNode {
  switch (item.type) {
    case "COMMUNE":
      return (
        <SearchResultCard
          icon={<CommuneIcon />}
          name={item.libelle}
          departement={item.data.departement.id}
          url={routesConfig.commune.getParameterPath(item.data.id)}
          type="Commune"
          matches={matches}
        />
      );
    case "COMMUNEASSDEG":
      return (
        <SearchResultCard
          icon={<CommuneIcon />}
          name={item.libelle}
          departement={item.data.codeChefLieu?.substring(0, 2)}
          url={routesConfig.communeAssocieeDeleguee.getParameterPath(item.data.id)}
          type="Commune associée ou déléguée"
          matches={matches}
        />
      );
    case "EPCI":
      return (
        <SearchResultCard
          icon={<CollectiviteIcon />}
          name={item.libelle}
          departement={item.data.departementSiege.id}
          url={routesConfig.epci.getParameterPath(item.data.id)}
          type="Établissement Public de Coopération Intercommunale"
          matches={matches}
        />
      );
    case "PERIMETRE":
      return (
        <SearchResultCard
          icon={<PerimetreIcon />}
          name={item.data.libelle}
          url={routesConfig.perimetre.getParameterPath(item.data.id)}
          type="Périmètre"
          subtitle={<CompetenceChip competence={item.data.competence} />}
          matches={matches}
        />
      );
    case "TERRITOIRE":
      return (
        <SearchResultCard
          icon={<AccountBalanceIcon />}
          name={item.libelle}
          url={routesConfig.territoire.getParameterPath(item.data.id)}
          type="Territoire"
          matches={matches}
        />
      );
    case "ELU":
      return <EluSearchResultCard name={item.libelle} elu={item.data} matches={matches} />;
  }
}
