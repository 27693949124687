import { useEffect } from "react";
import { DEFAULT_PAGE_TITLE, routes } from "config/app-config";
import { useCurrentPathRoute } from "./useCurrentPathRoute";

export function useDocumentTitle(): void {
  const path = useCurrentPathRoute();
  useEffect(() => {
    const currentRoute = routes.find((r) => r.path === path);
    document.title = currentRoute?.element ?? DEFAULT_PAGE_TITLE;
  }, [path]);
}
