interface GceContrat {
  numero: number;
  label: string;
  code: string;
  nom: string;
  ordre: number;
}

const GceContrats: Array<GceContrat> = [
  {
    numero: 1,
    label: "L'aménagement d'un bassin ou d'une fraction du bassin hydrographique",
    code: "G1",
    nom: "1° L'aménagement d'un bassin ou d'une fraction du bassin hydrographique",
    ordre: 13,
  },
  {
    numero: 2,
    label:
      "L'entretien et l'aménagement d'un cours d'eau, canal, lac ou plan d'eau, y compris les accès à ce cours d'eau, à ce canal, à ce lac ou à ce plan d'eau",
    code: "G2",
    nom: "2° L'entretien et l'aménagement d'un cours d'eau, canal, lac ou plan d'eau, y compris les accès à ce cours d'eau, à ce canal, à ce lac ou à ce plan d'eau",
    ordre: 14,
  },
  {
    numero: 4,
    label:
      "La maîtrise des eaux pluviales et de ruissellement ou la lutte contre l'érosion des sols",
    code: "G4",
    nom: "4° La maîtrise des eaux pluviales et de ruissellement ou la lutte contre l'érosion des sols",
    ordre: 15,
  },
  {
    numero: 5,
    label: "La défense contre les inondations et contre la mer",
    code: "G5",
    nom: "5° La défense contre les inondations et contre la mer",
    ordre: 16,
  },
  {
    numero: 8,
    label:
      "La protection et la restauration des sites, des écosystèmes aquatiques et des zones humides ainsi que des formations boisées riveraines",
    code: "G8",
    nom: "8° La protection et la restauration des sites, des écosystèmes aquatiques et des zones humides ainsi que des formations boisées riveraines",
    ordre: 17,
  },
  {
    numero: 12,
    label:
      "L'animation et la concertation dans le domaine de la gestion et de la protection de la ressource en eau et des milieux aquatiques dans un sous-bassin ou un groupement de sous-bassins, ou dans un système aquifère, correspondant à une unité hydrographique",
    code: "G12",
    nom: "12° L'animation et la concertation dans le domaine de la gestion et de la protection de la ressource en eau et des milieux aquatiques dans un sous-bassin ou un groupement de sous-bassins, ou dans un système aquifère, correspondant à une unité hydrographique",
    ordre: 18,
  },
];

export default GceContrats;
