import { Button, Collapse, Container, Divider, Grid, Typography } from "@mui/material";
import type { GridSize, SxProps } from "@mui/material";
import { useState } from "react";
import type { ReactNode } from "react";
import type { SdeappsError } from "utils/errorHandling/SdeappsError";
import { useErrorBoundary } from "react-error-boundary";
import ToastMessages from "constants/ToastMessages";

const marginTopSx: SxProps = { marginTop: { xs: 4, md: 8 } };

function goToHome(): void {
  window.location.href = window.location.origin;
}

interface ErrorPageProps {
  title?: string;
  subtitle?: string;
  message?: string;
  image?: string;
  error?: SdeappsError;
}

export function ErrorPage({
  title,
  subtitle,
  message,
  image,
  error,
}: Readonly<ErrorPageProps>): ReactNode {
  const [isStackIn, setIsStackIn] = useState(false);
  const { resetBoundary } = useErrorBoundary();

  const textContainerSizes =
    image != null
      ? { xs: 10 as GridSize, md: 3 as GridSize, xl: 4 as GridSize }
      : { xs: 12 as GridSize };

  return (
    <Container maxWidth={false} sx={{ background: "white" }}>
      <Grid
        container
        columns={{ xs: 4, md: 8, xl: 12 }}
        spacing={3}
        direction="row-reverse"
        alignItems="flex-start"
        justifyContent="center">
        {image != null && (
          <Grid item xs={4} md={5} xl={6}>
            <img
              style={{ objectFit: "contain", width: "100%" }}
              alt="error illustration"
              src={image}
            />
          </Grid>
        )}
        <Grid item container spacing={3} {...textContainerSizes}>
          <Grid item xs={12}>
            <Typography variant="h1" sx={marginTopSx}>
              {title ?? ":'("}
            </Typography>
            <Typography sx={marginTopSx}>{subtitle ?? ToastMessages.ERROR}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{message}</Typography>
          </Grid>
          <Grid item xs={12} sx={marginTopSx}>
            <Button variant="contained" onClick={goToHome}>
              Retour à l’accueil
            </Button>
          </Grid>
          <Grid item xs={12} sx={marginTopSx}>
            <Button variant="outlined" onClick={resetBoundary}>
              Réessayer
            </Button>
          </Grid>
          {error != null && (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                onClick={() => {
                  setIsStackIn(!isStackIn);
                }}>
                En savoir {isStackIn ? "moins" : "plus"}
              </Button>
            </Grid>
          )}
        </Grid>
        {error != null && (
          <Grid item xs={12}>
            <Collapse in={isStackIn}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    Si vous pensez que ce comportement n'est pas normal et qu'il s'agit d'un bug,
                    merci de bien vouloir copier les informations ci-dessous dans votre ticket
                    Gestsup :
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">Page: {window.location.href}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">
                    {error?.name}
                    <br />
                    {error?.originalError?.name} {error?.status} {error?.statusText}
                    <br />
                    {error?.description}
                    <br />
                    {message}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">{error?.stack}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
