import { AxiosError } from "axios";
import {
  SdeappsError,
  SdeappsErrorForbidden,
  SdeappsErrorNotFound,
  SdeappsErrorServerError,
  SdeappsErrorSiteDisabled,
  SdeappsErrorBadRequest,
  SdeappsErrorUnauthorized,
} from "./SdeappsError";
import type { SdeappsErrorOptions } from "utils/errorHandling";

/**
 * Récpère les options de SdeappsError spécifiques aux erreurs Axios qui ont une réponse
 * @param axiosError la AxiosError à analyser
 * @returns un objet d'options de SdeappsError
 */
function getErrorOptionsFromAxiosError(axiosError: AxiosError): SdeappsErrorOptions {
  return {
    ...axiosError,
    ...axiosError.response,
    originalError: axiosError,
    description: axiosError.message,
  };
}

/**
 * Analyse les erreurs et les transforme en SdeappsError
 * @param error une erreur à analyser
 * @returns une SdeappsError
 */
function getSdeappsErrorFromUnknown(error: unknown): SdeappsError {
  if (error instanceof SdeappsError) {
    return error;
  } else if (error instanceof AxiosError) {
    if (error.response != null) {
      // Client was given an error response 4xx, 5xx etc.
      switch (error.response.status) {
        case 400:
          return new SdeappsErrorBadRequest(
            error.response?.data?.description,
            getErrorOptionsFromAxiosError(error)
          );

        case 401:
          return new SdeappsErrorUnauthorized(
            error.response?.data?.description,
            getErrorOptionsFromAxiosError(error)
          );

        case 403:
          if (error.response?.statusText === "Site Disabled") {
            return new SdeappsErrorSiteDisabled(
              error.response?.data?.description,
              getErrorOptionsFromAxiosError(error)
            );
          }
          return new SdeappsErrorForbidden(
            error.response?.data?.description,
            getErrorOptionsFromAxiosError(error)
          );

        case 404:
          return new SdeappsErrorNotFound(
            error.response?.data?.description,
            getErrorOptionsFromAxiosError(error)
          );

        case 500:
          return new SdeappsErrorServerError(
            error.response?.data?.description,
            getErrorOptionsFromAxiosError(error)
          );

        default:
          return new SdeappsError(
            error.response?.data?.description,
            getErrorOptionsFromAxiosError(error)
          );
      }
    } else if (error.request != null) {
      // TODO ???
      return new SdeappsError(error.message, {
        ...error,
        stack: error?.stack,
        originalError: error,
      });
    } else {
      // TODO ???
      return new SdeappsError(error.message, {
        ...error,
        stack: error?.stack,
        originalError: error,
      });
    }
  } else if (error instanceof Error) {
    return new SdeappsError(error.message, {
      ...error,
      stack: error?.stack,
      originalError: error,
    });
  }

  try {
    return new SdeappsError(JSON.stringify(error));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new SdeappsError(String(error));
  }
}

const errorsUtil = {
  getSdeappsErrorFromUnknown,
};

export default errorsUtil;
