import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Grid } from "@mui/material";
import { arrayUtil } from "@sdeapps/react-core";
import type { TransfertViewModel } from "models";
import CommuneSousCompetencesAccordionSummary from "./CommuneSousCompetencesAccordionSummary";
import EauAssGepuSousCompetencesAccordion from "components/Informations/SousCompetences/EauAssGepuSousCompetencesAccordion";

interface CommuneEauAssGepuSousCompetencesProps {
  transferts: Array<TransfertViewModel>;
}

function CommuneEauAssGepuSousCompetences({
  transferts,
}: Readonly<CommuneEauAssGepuSousCompetencesProps>): ReactNode {
  const [transfertsByPerimetre, setTransfertsByPerimetre] = useState<
    Array<Array<TransfertViewModel>>
  >([]);

  useEffect(() => {
    const sCbP = arrayUtil.groupByArray(transferts, (t) => `${t.idPerimetre}${t.idEpci}`);

    setTransfertsByPerimetre(sCbP);
  }, [transferts]);

  return (
    <Grid container item xs={12} spacing={2}>
      {transfertsByPerimetre.map((_transferts) => {
        return (
          <EauAssGepuSousCompetencesAccordion
            key={_transferts[0]?.idPerimetre}
            defaultExpanded={transfertsByPerimetre.length === 1}
            transferts={_transferts}
            summary={
              <CommuneSousCompetencesAccordionSummary
                epci={_transferts[0].epci}
                perimetre={_transferts[0].perimetre}
              />
            }
          />
        );
      })}
    </Grid>
  );
}

export default CommuneEauAssGepuSousCompetences;
