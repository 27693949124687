import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Grid } from "@mui/material";
import Competence from "constants/Competence";
import type { Perimetre, Transfert, Commune } from "models";
import { withComponentErrorBoundary, useErrorHandler } from "utils/errorHandling";
import PerimetreEauAssGepuSousCompetences from "./PerimetreEauAssGepuSousCompetences";
import { communesService } from "services";
import PerimetreGceSousCompetences from "./PerimetreGceSousCompetences";

interface PerimetrePorteesInformationsProps {
  perimetre: Perimetre;
  transferts: Array<Transfert>;
}

function PerimetrePorteesInformations({
  transferts,
  perimetre,
}: Readonly<PerimetrePorteesInformationsProps>): ReactNode {
  const [communes, setCommunes] = useState<Array<Commune>>([]);
  const { catchErrors } = useErrorHandler();

  useEffect(() => {
    async function getCommunes(): Promise<void> {
      const _communes = await communesService.getByPerimetre(perimetre.id);

      setCommunes(_communes);
    }

    void catchErrors(getCommunes);
  }, [catchErrors, perimetre]);

  switch (perimetre.competence) {
    case Competence.EAU:
    case Competence.ASS:
    case Competence.GEPU:
      return (
        <Grid container item xs={12} spacing={2}>
          <PerimetreEauAssGepuSousCompetences transferts={transferts} communes={communes} />
        </Grid>
      );
    case Competence.GCE:
      return (
        <Grid container item xs={12} spacing={2}>
          <PerimetreGceSousCompetences transferts={transferts} communes={communes} />
        </Grid>
      );

    default:
      return null;
  }
}

const PerimetrePorteesInformationsWithErrorBoundary = withComponentErrorBoundary(
  PerimetrePorteesInformations
);

export default PerimetrePorteesInformationsWithErrorBoundary;
