import type { ReactNode } from "react";
import { ErrorPage } from "./ErrorPage";
import ToastMessages from "constants/ToastMessages";

interface ServerErrorPageProps {
  message?: string;
  error?: Error;
}

export function ServerErrorPage({ message, error }: Readonly<ServerErrorPageProps>): ReactNode {
  return (
    <ErrorPage
      title="500"
      subtitle={ToastMessages.ERROR_INTERNAL_SERVER_ERROR}
      message={message}
      error={error}
    />
  );
}
