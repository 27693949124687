import { Container } from "@mui/material";
import type { ReactElement } from "react";
import MainSearch from "./components/MainSearch";

function HomePage(): ReactElement {
  return (
    <Container maxWidth="xl">
      <MainSearch />
    </Container>
  );
}

export default HomePage;
