import type { ReactNode } from "react";
import { Link } from "@mui/material";
import type { LinkProps } from "@mui/material";
import { routesConfig } from "config/app-config";
import type { Personne } from "models";

interface EluLinkProps extends LinkProps {
  elu: Personne;
}

function EluLink({ elu, ...props }: Readonly<EluLinkProps>): ReactNode {
  return (
    <Link href={routesConfig.elu.getParameterPath(elu.id)} {...props}>
      {elu.nom} {elu.prenom}
    </Link>
  );
}

export default EluLink;
