import type { ReactNode } from "react";
import ToastMessages from "constants/ToastMessages";
import { ErrorPage } from ".";

interface ForbiddenErrorPageProps {
  message?: string;
  error?: Error;
}

export function ForbiddenErrorPage({
  message,
  error,
}: Readonly<ForbiddenErrorPageProps>): ReactNode {
  return (
    <ErrorPage
      title="403"
      subtitle={ToastMessages.ERROR_FORBIDDEN}
      message={message}
      error={error}
    />
  );
}
