import { Grid, type GridProps, InputAdornment, OutlinedInput } from "@mui/material";
import { useSearch } from "providers";
import { type ReactElement } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

interface SearchInputProps extends GridProps {
  size?: "small" | "medium";
  autoFocus?: boolean;
  onFocus?: VoidFunction;
}

/**
 * Input de recherche de collectivités. A entourer d'un **`SearchProvider`**.
 * @param sx?: SxProps
 * @param size?: "small" | "medium"
 * @param ...props: GridProps
 */
export function SearchInput({
  sx,
  size = "medium",
  autoFocus = false,
  onFocus,
  ...props
}: Readonly<SearchInputProps>): ReactElement {
  const { searchString, setSearchString } = useSearch();
  const navigate = useNavigate();

  return (
    <Grid {...props} sx={sx}>
      <OutlinedInput
        fullWidth
        placeholder="Que recherchez-vous ?"
        size={size}
        value={searchString}
        sx={{ background: "white" }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSearchString(event.target.value ?? "");
        }}
        onFocus={onFocus}
        onKeyDownCapture={(e) => {
          if (e.key === "Enter") {
            navigate("/");
          }
        }}
        autoFocus={autoFocus}
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </Grid>
  );
}
