import { dateUtil } from "@sdeapps/react-core";
import type { PatchData } from "models";

export const DATE_FORMAT_FOR_API_REQUEST = "yyyy-MM-dd";

function toPatchDataValueString(data: unknown): string | undefined {
  if (data instanceof Date) {
    return dateUtil.format(data, DATE_FORMAT_FOR_API_REQUEST);
  }
  if (typeof data === "boolean") {
    return data.toString();
  }
  if (data === null) {
    return undefined;
  }
  return data as string;
}

function objectToReplacePatchData<T extends object>(
  data: T,
  excludes: Array<keyof T> = []
): Array<PatchData> {
  const patches: Array<PatchData> = [];

  Object.entries(data).forEach(([key, value]) => {
    if (excludes.length === 0 || !excludes.includes(key as keyof T)) {
      patches.push({
        op: "replace",
        path: `/${key}`,
        value: toPatchDataValueString(value),
      });
    }
  });

  return patches;
}

/**
 * Ajoute à un tableau de PatchData les instructions pour remplacer des attributs ciblés d'un objet. Mutate le tableau, et le renvoie également.
 * @param propertiesToReplace tableau de tuples de chemins dans l'objet cible des propriétés à supprimer, au format **"/path/to/property"**, et des valeurs correspondantes.
 * @param patchData le tableau de PatchData dans lequel ajouter les nouvelles insctructions.
 */
function addReplaceToPatchData(
  propertiesToReplace: Array<[path: string, value: unknown]>,
  patchData: Array<PatchData> = []
): Array<PatchData> {
  propertiesToReplace.forEach(([path, value]) =>
    patchData.push({
      op: "replace",
      path,
      value: toPatchDataValueString(value),
    })
  );
  return patchData;
}

/**
 * Ajoute à un tableau de PatchData les instructions pour supprimmer des attributs ciblés d'un objet. Mutate le tableau, et le renvoie également.
 * @param pathsToRemove tableau de chemins dans l'objet cible des propriétés à supprimer, au format **"/path/to/property"**.
 * @param patchData le tableau de PatchData dans lequel ajouter les nouvelles insctructions.
 */
function addRemoveToPatchData(
  pathsToRemove: Array<string>,
  patchData: Array<PatchData> = []
): Array<PatchData> {
  pathsToRemove.forEach((path) =>
    patchData.push({
      op: "remove",
      path,
    })
  );
  return patchData;
}

export const patchUtils = {
  addReplaceToPatchData,
  addRemoveToPatchData,
  objectToReplacePatchData,
};
