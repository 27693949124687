function ToFullName(libelle: string): string {
  if (libelle.toUpperCase().startsWith("CC")) {
    return `Communauté de Communes${libelle.slice(2)}`;
  }
  if (libelle.toUpperCase().startsWith("CA")) {
    return `Communauté d'Agglomération${libelle.slice(2)}`;
  }
  if (libelle.toUpperCase().startsWith("CU")) {
    return `Communauté Urbaine${libelle.slice(2)}`;
  }
  return libelle;
}

export const epcisUtil = {
  ToFullName,
};
