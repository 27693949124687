/**
 * Transforme des données stream d'une requête HTTP en fichier téléchargé par le navigaateur.
 * @param data Données à télécharger.
 * @param fileName Nom du fichier qui sera téléchargé.
 * @param contentType Type du fichier qui sera téléchargé.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function downloadStreamAsFile(
  data: BlobPart | Array<BlobPart>,
  fileName: string,
  contentType?: string
): void {
  const blob = new Blob(Array.isArray(data) ? data : [data], { type: contentType });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
  link.remove();
}

export const fileUtil = {
  downloadStreamAsFile,
};
