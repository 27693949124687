import type { ReactNode } from "react";
import { Button, Grid, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { useErrorBoundary } from "react-error-boundary";

interface InformationsErrorProps {
  title?: string;
  caption?: string;
}

const DEFAULT_TITLE = "Une erreur est survenue.";
const DEFAULT_CAPTION =
  "Vous pouvez continuer à utiliser l'application, mais certaines fonctionnalités peuvent être temporairement dégradées.";

function InformationsError({
  title = DEFAULT_TITLE,
  caption = DEFAULT_CAPTION,
}: Readonly<InformationsErrorProps>): ReactNode {
  const { resetBoundary } = useErrorBoundary();

  return (
    <Grid container item spacing={2} alignItems="center" justifyContent="center" xs={12}>
      <Grid item xs="auto">
        <WarningIcon color="warning" />
      </Grid>

      <Grid container item xs>
        <Grid container item xs={12} alignItems="center" spacing={2}>
          <Grid item>
            <Typography fontWeight="500">{title}</Typography>
          </Grid>
          <Grid item>
            <Button variant="text" size="small" onClick={resetBoundary}>
              Réessayer
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" fontStyle="italic">
            {caption}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InformationsError;
