import { Grid, List, ListItem, Skeleton } from "@mui/material";
import { type ReactElement } from "react";

function ElusInformationsSkeleton(): ReactElement {
  return (
    <List sx={{ width: "100%" }}>
      {[0, 1, 2].map((i) => {
        return (
          <ListItem key={i} sx={{ display: "inline-block" }}>
            <Skeleton variant="text" animation="wave" sx={{ height: "30px", width: "50%" }} />

            <Grid item container justifyContent="space-between">
              <Grid item xs>
                <Skeleton variant="text" animation="wave" sx={{ height: "30px", width: "50%" }} />
              </Grid>
              <Grid item container xs justifyContent="flex-end" spacing={1}>
                <Grid item xs="auto">
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ width: "35px", height: "30px", textAlign: "right" }}
                  />
                </Grid>
                <Grid item xs="auto">
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ width: "35px", height: "30px", textAlign: "right" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
        );
      })}
    </List>
  );
}

export default ElusInformationsSkeleton;
