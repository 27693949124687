import type { CommuneAssocieeDeleguee } from "models";
import { Endpoints } from "constants/endpoints";
import { collectivitesApiClient } from "./apiClients";

/**
 * Récupère toutes les communes associées et déléguées
 * @returns Array<CommuneAssocieeDeleguee>
 */
async function getAll(): Promise<Array<CommuneAssocieeDeleguee>> {
  const url = `/${Endpoints.COMMUNES_ASSOCIEES_DELEGUEES}`;
  return collectivitesApiClient.get(url);
}

/**
 * Récupère une commune associée ou déléguée en fonction de son code INSEE
 * @param codeInsee code INSEE
 * @returns une commune
 */
async function getById(codeInsee: string): Promise<CommuneAssocieeDeleguee> {
  const url = `/${Endpoints.COMMUNES_ASSOCIEES_DELEGUEES}/${codeInsee}`;
  return collectivitesApiClient.get(url);
}

/**
 * Récupère une commune associée ou déléguée en fonction de son code INSEE
 * @param codeInsee code INSEE
 * @returns une commune
 */
async function getByChefLieuId(codeInsee: string): Promise<Array<CommuneAssocieeDeleguee>> {
  const url = `/${Endpoints.COMMUNES}/${codeInsee}/${Endpoints.COMMUNES_ASSOCIEES_DELEGUEES}`;
  return collectivitesApiClient.get(url);
}

export const communesAssocieesDelegueesService = {
  getAll,
  getById,
  getByChefLieuId,
};
