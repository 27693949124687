import { Grid, Typography, useMediaQuery, useTheme, type TypographyProps } from "@mui/material";
import { useEffect, type PropsWithChildren, type ReactElement, useState } from "react";
import BackButton from "./BackButton";
import type Competence from "constants/Competence";
import CompetenceChip from "./Typography/CompetenceChip";

interface PageTitleProps extends PropsWithChildren {
  noBackButton?: boolean;
  title: string | ReactElement;
  subtitle?: string | ReactElement;
  icon?: string | ReactElement;
  typographyProps?: TypographyProps;
  competences?: Array<Competence> | Competence;
}

function PageTitle({
  noBackButton = false,
  children,
  title,
  subtitle,
  icon,
  typographyProps,
  competences: originalCompetences,
}: Readonly<PageTitleProps>): ReactElement {
  const [competences, setCompetences] = useState<Array<Competence>>([]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (originalCompetences != null) {
      setCompetences(
        Array.isArray(originalCompetences) ? originalCompetences : [originalCompetences]
      );
    } else {
      setCompetences([]);
    }
  }, [originalCompetences]);

  return (
    <Grid
      item
      container
      sx={{
        p: 2,
        background: "white",
        position: "sticky",
        top: { xs: "54px", sm: "62px" },
        zIndex: 10,
      }}
      xs={12}
      alignItems="center">
      {!noBackButton && (
        <Grid item>
          <BackButton sx={{ ml: -2 }} />
        </Grid>
      )}
      {icon != null && (
        <Grid item sx={{ paddingRight: 1 }}>
          <Typography>{icon}</Typography>
        </Grid>
      )}
      <Grid item xs>
        <Typography variant={isSmallScreen ? "h3" : "h2"} {...typographyProps}>
          {title}
        </Typography>
      </Grid>
      {(subtitle != null || competences != null) && (
        <Grid container item xs={12} spacing={1}>
          <Grid item>
            <Typography variant="caption" sx={{ ml: 3.5 }} fontSize={{ xs: ".9rem", md: "1rem" }}>
              {subtitle}
            </Typography>
          </Grid>
          {competences.map((competence) => (
            <Grid item key={competence}>
              <CompetenceChip competence={competence} />
            </Grid>
          ))}
        </Grid>
      )}
      {children}
    </Grid>
  );
}

export default PageTitle;
