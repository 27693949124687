import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { useParams } from "react-router-dom";
import { communesService, communesAssocieesDelegueesService } from "services";
import { TypeCommuneAssocieeDeleguee } from "models";
import type { CommuneAssocieeDeleguee, Commune } from "models";
import LoadingScreen from "components/Template/LoadingScreen";
import { Container, Grid, Typography } from "@mui/material";
import PageTitle from "components/PageTitle";
import CommuneLieesSubtitle from "components/CommuneLieesSubtitle";
import InformationBox from "components/InformationBox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { dateUtil } from "@sdeapps/react-core";
import { useErrorHandler, withPageErrorBoundary } from "utils/errorHandling";
import { CommuneIcon } from "icons";
import PopulationSpan from "components/Span/PopulationSpan";

function CommuneAssocieeDelegueePage(): ReactNode {
  const { id } = useParams();
  const [commune, setCommune] = useState<CommuneAssocieeDeleguee>();
  const [chefLieu, setChefLieu] = useState<Commune>();

  const { catchErrors } = useErrorHandler();

  useEffect(() => {
    async function getCommune(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const _commune = await communesAssocieesDelegueesService.getById(id!);
      const _chefLieu = await communesService.getById(_commune.codeChefLieu);
      document.title = _commune.libelle;
      setCommune(_commune);
      setChefLieu(_chefLieu);
    }

    setCommune(undefined);
    setChefLieu(undefined);
    void catchErrors(getCommune);
  }, [catchErrors, id]);

  if (commune == null || chefLieu == null) {
    // TODO SKELETON
    return <LoadingScreen />;
  }

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        <PageTitle
          title={commune.libelle}
          icon={<CommuneIcon />}
          subtitle="Commune associée ou déléguée"
        />
        <Grid item container xs={12}>
          <CommuneLieesSubtitle commune={chefLieu} />
        </Grid>

        <Grid item container xs={12}>
          <InformationBox
            hasBackground
            icon={VisibilityIcon}
            articleTitle="Informations générales"
            subTitle={`Code INSEE : ${commune.id}`}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <Typography>Canton : {chefLieu?.canton?.libelle ?? ""}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Arrondissement : {chefLieu?.arrondissement?.libelle ?? ""}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Date de fusion :{" "}
                  {(commune?.dateFin != null &&
                    dateUtil.format(
                      dateUtil.fromFormatToDate(commune?.dateFin, "yyyy-MM-dd"),
                      "dd/MM/yyyy"
                    )) ??
                    ""}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Type :
                  {commune.type === TypeCommuneAssocieeDeleguee.DELEGUEE
                    ? " commune déléguée"
                    : " commune associée"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Population totale au dernier recensement :{" "}
                  <PopulationSpan
                    population={commune.population.actuelle.populationTotale}
                    annee={commune.population.actuelle.anneeMiseEnVigueur}
                  />
                </Typography>
              </Grid>
            </Grid>
          </InformationBox>
        </Grid>
      </Grid>
    </Container>
  );
}

const CommuneAssocieeDelegueePageWithErrorBoundary = withPageErrorBoundary(
  CommuneAssocieeDelegueePage
);

export default CommuneAssocieeDelegueePageWithErrorBoundary;
