import { useEffect, useState } from "react";
import { useErrorHandler } from "utils/errorHandling";
import { communesAssocieesDelegueesService, communesService, transfertsService } from "services";
import type {
  ChefLieuWithCADsPerimetreAffectation,
  CommuneAssocieeDelegueeWithPerimetreAffectation,
  Commune,
  CommuneAssocieeDeleguee,
  Transfert,
} from "models";

function mapCadWithAffectation(
  idChefLieu: string,
  cad: CommuneAssocieeDeleguee,
  transferts: Array<Transfert>
): CommuneAssocieeDelegueeWithPerimetreAffectation {
  // Etant donné que les affectations sont répliquées dans chaque transfert,
  // l'utilisation d'un reduce avec un Set permet d'obtenir tous les Id de communes associées déléguées
  // pour le chef lieu et le périmètre courant.
  const transfertsChefLieu = transferts
    .filter((t) => t.idCommune === idChefLieu)
    .reduce((acc, { affectationsCommunesAssocieesDeleguees }) => {
      if (
        affectationsCommunesAssocieesDeleguees != null &&
        affectationsCommunesAssocieesDeleguees.length > 0
      ) {
        for (const affectation of affectationsCommunesAssocieesDeleguees) {
          acc.add(affectation);
        }
      }
      return acc;
    }, new Set<string>());

  return {
    ...cad,
    // La commune associée déléguée est affectée si elle existe dans les affectations des transferts du périmètres
    isAffectee: transfertsChefLieu.has(cad.id),
  };
}

function mapCommuneWithCadsAffectation(
  communeChefLieu: Commune,
  communesAssocieesDeleguees: Array<CommuneAssocieeDeleguee>,
  transferts: Array<Transfert>
): ChefLieuWithCADsPerimetreAffectation {
  return {
    ...communeChefLieu,
    cads: communesAssocieesDeleguees
      // Filtre des communes associées déléguées par le chef lieux courant
      .filter((cad) => cad.codeChefLieu === communeChefLieu.id)
      .map<CommuneAssocieeDelegueeWithPerimetreAffectation>((cad) =>
        mapCadWithAffectation(communeChefLieu.id, cad, transferts)
      ),
  };
}

interface UseChefLieuxEtCommunesAssocieesDelegueesResult {
  chefLieuxEtCADs: Array<ChefLieuWithCADsPerimetreAffectation>;
  isLoadingCADs: boolean;
}
export function useChefLieuxEtCommunesAssocieesDeleguees(
  idPerimetre: string | undefined
): UseChefLieuxEtCommunesAssocieesDelegueesResult {
  const [chefLieuxEtCADs, setChefLieuxEtCADs] = useState<
    Array<ChefLieuWithCADsPerimetreAffectation>
  >([]);
  const { catchErrors, isLoading: isLoadingCADs } = useErrorHandler();
  useEffect(() => {
    async function getCommunesAndCADs(): Promise<void> {
      if (idPerimetre != null) {
        const [_communesPerimetre, _communesAssocieesDeleguees, _transferts] = await Promise.all([
          communesService.getByPerimetre(idPerimetre),
          communesAssocieesDelegueesService.getAll(),
          transfertsService.getByPerimetre(idPerimetre),
        ]);

        // Id unique des communes chef lieu via un Set pour gérer l'unicité des valeurs
        const _chefLieuxIds = [
          ..._communesAssocieesDeleguees.reduce((acc, cad) => {
            acc.add(cad.codeChefLieu);
            return acc;
          }, new Set<string>()),
        ];

        const _chefLieuxEtCADs = _communesPerimetre
          // Filtre pour ne retourner que les périmètres concernés par des chef lieux / communes associées déléguées
          .filter((c) => _chefLieuxIds.includes(c.id))
          .map((c) => mapCommuneWithCadsAffectation(c, _communesAssocieesDeleguees, _transferts))
          // Filtre des entrées sans communes associées déléguées
          .filter((c) => c.cads.length > 0);

        setChefLieuxEtCADs(_chefLieuxEtCADs);
      }
    }
    // Reset de l'état avant requête et traitement des données afin de partir sur un état neuf.
    setChefLieuxEtCADs([]);
    void catchErrors(getCommunesAndCADs);
  }, [catchErrors, idPerimetre]);

  return {
    chefLieuxEtCADs,
    isLoadingCADs,
  };
}
