import { useEffect } from "react";
import { type ReactNode } from "react";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import { elusService } from "services";
import { useErrorHandler, withComponentErrorBoundary } from "utils/errorHandling";
import type { PersonneInfosPrivees, PatchData, Personne } from "models";
import LoadingScreen from "components/Template/LoadingScreen";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "components/LoadingButton";
import { dateUtil } from "@sdeapps/react-core";
import ToastMessages from "constants/ToastMessages";
import { type SubmitHandler, useForm } from "react-hook-form";
import ControlledTextField from "components/inputs/ControlledTextField";
import { enqueueSnackbar } from "notistack";

function toPatchData(eluInfosPrivees: PersonneInfosPrivees): Array<PatchData> {
  const patches: Array<PatchData> = [];

  Object.keys(eluInfosPrivees).forEach((k) => {
    const key = k as keyof PersonneInfosPrivees;
    if (k !== "id") {
      patches.push({
        op: "replace",
        path: `/${k}`,
        value: eluInfosPrivees[key],
      });
    }
  });

  return patches;
}

const nbsp = "\u00A0"; // non-breakable space
const phoneHelperText = `Numéro de téléphone invalide : Les formats acceptés sont XX${nbsp}XX${nbsp}XX${nbsp}XX${nbsp}XX, XX.XX.XX.XX.XX, +XX${nbsp}X${nbsp}XX${nbsp}XX${nbsp}XX${nbsp}XX`;
const phoneRegex = /^((\+\d{2}[./\s]?(\d{1,2}[./\s]?))|(\d{1,2}[./\s]?))(\d{2}[./\s]?){4}$/;
const phoneRules = {
  pattern: {
    value: phoneRegex,
    message: phoneHelperText,
  },
};
const eMailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const emailRules = {
  pattern: {
    value: eMailRegex,
    message: "Adresse e-mail invalide",
  },
};

interface EluContactInformationsPriveesModificationProps {
  elu: Personne;
  setIsModifying: (m: boolean) => void;
}

function EluContactInformationsPriveesModification({
  elu,
  setIsModifying,
}: Readonly<EluContactInformationsPriveesModificationProps>): ReactNode {
  const { catchErrors, isLoading } = useErrorHandler();
  const { catchErrors: catchPatchErrors, isLoading: isSending } = useErrorHandler({
    dontThrow: true,
    defaultIsLoading: false,
    default: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR_RETRY,
      });
    },
  });

  const { handleSubmit, control, reset } = useForm<PersonneInfosPrivees>({
    shouldFocusError: false,
  });

  const sendData: SubmitHandler<PersonneInfosPrivees> = async function (
    _eluInfosPrivees: PersonneInfosPrivees
  ): Promise<void> {
    await catchPatchErrors(async () => {
      await elusService.patchElu(_eluInfosPrivees.id, toPatchData(_eluInfosPrivees));
      setIsModifying(false);
    });
  };

  useEffect(() => {
    async function getElu(): Promise<void> {
      const _eluInfosPrivees = await elusService.getPersonneInfosPriveesById(elu.id);
      reset(_eluInfosPrivees);
    }

    void catchErrors(getElu);
  }, [catchErrors, elu, reset]);

  if (isLoading) {
    // TODO SKELETON
    return <LoadingScreen />;
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <Grid container item xs={12} component="form" onSubmit={handleSubmit(sendData)}>
      <Grid item xs={12} md={6} lg={4}>
        <List>
          <ListItem>
            <ListItemIcon>
              <CallOutlinedIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography fontWeight="bold">Téléphone</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ControlledTextField
                    control={control}
                    name="telephonePersonnel"
                    label="Téléphone personnel"
                    rules={phoneRules}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ControlledTextField
                    control={control}
                    name="telephonePortable"
                    label="Téléphone portable"
                    rules={phoneRules}
                  />
                </Grid>
              </Grid>
            </ListItemText>
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <List>
          <ListItem>
            <ListItemIcon>
              <EmailOutlinedIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography fontWeight="bold">Adresse</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ControlledTextField control={control} name="rue" label="Rue" />
                </Grid>
                <Grid item xs={12}>
                  <ControlledTextField control={control} name="codePostal" label="Code postal" />
                </Grid>
                <Grid item xs={12}>
                  <ControlledTextField control={control} name="commune" label="Commune" />
                </Grid>
              </Grid>
            </ListItemText>
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} lg={4}>
        <List>
          <ListItem>
            <ListItemIcon>
              <AlternateEmailOutlinedIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography fontWeight="bold">E-mail</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <ControlledTextField
                control={control}
                name="emailPrincipal"
                label="E-mail"
                defaultHelperText="Attention! Cet e-mail sert d'identifiant pour l'extranet des élus"
                rules={emailRules}
              />
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <ControlledTextField
                control={control}
                name="emailSecondaire"
                label="E-mail Secondaire"
                rules={emailRules}
              />
            </ListItemText>
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <List>
          <ListItem>
            <Typography>
              Né{elu.sexe === "F" && "e"} le{" "}
              <Typography component="span" fontWeight={600}>
                {dateUtil.format(elu.dateNaissance, "dd/MM/yyyy")}
              </Typography>{" "}
              (
              {Math.floor(
                dateUtil.differenceInMinutes(undefined, elu.dateNaissance) / 60 / 24 / 365
              )}{" "}
              ans)
            </Typography>
          </ListItem>
        </List>
      </Grid>
      <Grid container item xs={12} md={6} lg={8} justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Button
            variant="text"
            color="error"
            onClick={() => {
              setIsModifying(false);
            }}>
            Annuler
          </Button>
        </Grid>
        <Grid item>
          <LoadingButton variant="contained" startIcon={<SaveIcon />} loading={isSending}>
            Enregistrer
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

const EluContactInformationsPriveesModificationWithErrorBoundary = withComponentErrorBoundary(
  EluContactInformationsPriveesModification
);

export default EluContactInformationsPriveesModificationWithErrorBoundary;
