import { createSvgIcon } from "@mui/material";

export const AssainissementIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="5" fill="#E1BBAB" />
    <path
      d="M9.55417 8.07044V7.23034H11.2344V11.4309H9.55417V10.5908H5.35365C4.90803 10.5908 4.48066 10.4137 4.16556 10.0986C3.85046 9.78353 3.67344 9.35617 3.67344 8.91055V6.18021H2.83333V4.5H7.03385V6.18021H6.19375V8.07044H9.55417Z"
      fill="white"
    />
    <path
      d="M14.1679 10.6958V11.5359H12.4877L12.4877 7.33537L14.1679 7.33537V8.17548L18.3684 8.17548C18.8141 8.17548 19.2414 8.3525 19.5565 8.6676C19.8716 8.9827 20.0486 9.41007 20.0486 9.85569V12.586H20.8888V14.2662L16.6882 14.2662V12.586L17.5283 12.586V10.6958L14.1679 10.6958Z"
      fill="white"
    />
    <path
      d="M14.6449 16.0848C14.6449 17.3652 13.4607 18.4031 12 18.4031C10.5393 18.4031 9.35509 17.3652 9.35509 16.0848C9.35509 14.8044 10.0764 14.208 12 12C12.9618 13.2144 14.6449 14.8044 14.6449 16.0848Z"
      fill="white"
    />
    <path
      d="M13.8711 16.5204C13.7271 16.8917 13.2436 17.4067 12.5652 17.6942"
      stroke="#E1BBAB"
      strokeWidth="0.330612"
      strokeLinecap="round"
    />
    <ellipse cx="13.999" cy="15.8549" rx="0.204845" ry="0.188921" fill="#E1BBAB" />
  </svg>,
  "AssainissementIcon"
);
