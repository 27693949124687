import { useState, useEffect } from "react";
import type { ReactNode } from "react";
import { Autocomplete, TextField, Typography, createFilterOptions } from "@mui/material";
import { useController } from "react-hook-form";
import type { FieldValues, FieldPath } from "react-hook-form";
import { stringUtil } from "@sdeapps/react-core";
import { epcisUtil } from "utils";
import type { ControlledInput } from "utils";
import { useErrorHandler, withComponentErrorBoundary } from "utils/errorHandling";
import InputSkeleton from "components/InputSkeleton";
import { epcisService } from "services";
import type { Epci } from "models";

interface EpciAutoCompleteProps<T extends FieldValues, Name extends FieldPath<T>>
  extends ControlledInput<T, Name> {
  disabled?: boolean;
}

function EpciAutoComplete<T extends FieldValues, Name extends FieldPath<T>>({
  name,
  control,
  rules,
  disabled = false,
}: Readonly<EpciAutoCompleteProps<T, Name>>): ReactNode {
  const filterOptions = createFilterOptions({
    trim: true,
    stringify: (option: Epci) => stringUtil.normalize(epcisUtil.ToFullName(option.libelle)),
  });
  const [epcis, setEpcis] = useState<Array<Epci>>([]);

  const {
    field: { onChange, ref, value },
    fieldState: { error },
  } = useController({ name, control, rules });

  const { catchErrors, isLoading } = useErrorHandler();

  useEffect(() => {
    async function getEpcis(): Promise<void> {
      const _epcis = await epcisService.getAll();

      _epcis.sort((a, b) =>
        stringUtil
          .normalize(epcisUtil.ToFullName(a.libelle))
          .localeCompare(stringUtil.normalize(epcisUtil.ToFullName(b.libelle)))
      );
      setEpcis(_epcis);
    }

    void catchErrors(getEpcis);
  }, [catchErrors]);

  if (isLoading) {
    return <InputSkeleton />;
  }

  return (
    <Autocomplete
      value={epcis.find((e) => e.id === value) ?? null}
      fullWidth
      autoHighlight
      openOnFocus
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={epcis}
      filterOptions={filterOptions}
      disabled={disabled}
      onChange={(_, newValue) => {
        onChange(newValue?.id);
      }}
      isOptionEqualToValue={(v1: Epci, v2: Epci): boolean => v1.id === v2.id}
      getOptionLabel={(epci: Epci): string => epci.libelle}
      renderOption={(props, option) => (
        <Typography component="li" {...props} key={option.id}>
          {epcisUtil.ToFullName(option.libelle)}
        </Typography>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="EPCI *"
          placeholder="Nom de l'EPCI"
          inputRef={ref}
          error={error != null}
          helperText={error?.message}
        />
      )}
    />
  );
}

const EpciAutoCompleteWithErrorBoundary = withComponentErrorBoundary(EpciAutoComplete);

export default EpciAutoCompleteWithErrorBoundary;
