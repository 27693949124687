import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Container, Grid, Typography } from "@mui/material";
import InformationBox from "components/InformationBox";
import PageTitle from "components/PageTitle";
import PerimetreForm from "./components/PerimetreForm";
import { Authorization } from "@sdeapps/react-core";
import { ApplicationRole } from "constants/ApplicationRole";
import { withPageErrorBoundary, useErrorHandler } from "utils/errorHandling";
import { PerimetreIcon } from "icons";
import type { Perimetre } from "models";
import { useParams } from "react-router-dom";
import { perimetresService } from "services";
import LoadingScreen from "components/Template/LoadingScreen";

const PAGE_SUBTITLE = "Modification du Périmètre";

function ModifyPerimetre(): ReactNode {
  const { id: idPerimetre } = useParams();
  const [perimetre, setPerimetre] = useState<Perimetre>();

  const { catchErrors: catchErrorsPerimetre, isLoading: isLoadingPerimetre } = useErrorHandler();

  useEffect(() => {
    async function getPerimetre(): Promise<void> {
      if (idPerimetre != null) {
        const _perimetre = await perimetresService.getById(idPerimetre);
        document.title = `${PAGE_SUBTITLE} ${_perimetre.libelle}`;
        setPerimetre(_perimetre);
      }
    }
    void catchErrorsPerimetre(getPerimetre);
  }, [catchErrorsPerimetre, idPerimetre]);

  return (
    <Container>
      <Grid container spacing={2}>
        <PageTitle
          title={perimetre?.libelle ?? "Modifier le Périmètre"}
          icon={<PerimetreIcon />}
          subtitle={`Périmètre : ${PAGE_SUBTITLE}`}
          competences={perimetre?.competence}
        />
        <Authorization
          roles={ApplicationRole.ADMINISTRATOR}
          fallback={
            <Typography>
              Vous n'avez pas les droits nécessaires pour accéder à cette page
            </Typography>
          }>
          <InformationBox articleTitle={PAGE_SUBTITLE}>
            {isLoadingPerimetre || perimetre == null ? (
              <LoadingScreen />
            ) : (
              <PerimetreForm perimetre={perimetre} />
            )}
          </InformationBox>
        </Authorization>
      </Grid>
    </Container>
  );
}

const ModifyPerimetreWithErrorBoundary = withPageErrorBoundary(ModifyPerimetre);

export default ModifyPerimetreWithErrorBoundary;
