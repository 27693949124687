import { createContext } from "react";

export interface DisplayDatesContextData {
  isDisplayingDates: boolean;
  setIsDisplayingDates: (b: boolean) => void;
}

const data: DisplayDatesContextData = {
  isDisplayingDates: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsDisplayingDates: () => {},
};

const DisplayDatesContext = createContext(data);

export default DisplayDatesContext;
