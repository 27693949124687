import { Skeleton, type SxProps, Typography, type TypographyProps } from "@mui/material";
import { type ReactElement } from "react";

interface TypographySkeletonProps extends TypographyProps {
  sx?: SxProps;
}

function TypographySkeleton({
  sx,
  ...typographyProps
}: Readonly<TypographySkeletonProps>): ReactElement {
  return (
    <Typography component="span" {...typographyProps}>
      <Skeleton sx={sx} />
    </Typography>
  );
}

export default TypographySkeleton;
