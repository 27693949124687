import type { ReactElement } from "react";
import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
  OutlinedInput,
} from "@mui/material";
import type { GridProps } from "@mui/material";
import { useSearch } from "providers";
import { CommuneIcon, CollectiviteIcon, PerimetreIcon } from "icons";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonIcon from "@mui/icons-material/Person";
import type { CollectiviteSearchItem } from "models";
import type { SvgIconComponent } from "@mui/icons-material";
import TuneIcon from "@mui/icons-material/Tune";

const availableCategories: Array<{
  label: string;
  categories: CollectiviteSearchItem["type"] | Array<CollectiviteSearchItem["type"]>;
  Icon: SvgIconComponent;
}> = [
  { label: "Communes", categories: ["COMMUNE", "COMMUNEASSDEG"], Icon: CommuneIcon },
  { label: "EPCI", categories: "EPCI", Icon: CollectiviteIcon },
  { label: "Périmètres SDEA", categories: "PERIMETRE", Icon: PerimetreIcon },
  { label: "Territoires", categories: "TERRITOIRE", Icon: AccountBalanceIcon },
  { label: "Élus", categories: "ELU", Icon: PersonIcon },
];

function getInputDisplayValue(searchCategories: Array<CollectiviteSearchItem["type"]>): string {
  let inputDisplayValue = "";

  availableCategories.forEach((cat) => {
    let compareCategory: "COMMUNE" | "COMMUNEASSDEG" | "EPCI" | "TERRITOIRE" | "PERIMETRE" | "ELU";
    if (Array.isArray(cat.categories)) {
      compareCategory = cat.categories[0];
    } else {
      compareCategory = cat.categories;
    }

    if (searchCategories.includes(compareCategory)) {
      if (inputDisplayValue.length > 1) {
        inputDisplayValue += ", ";
      }
      inputDisplayValue += cat.label;
    }
  });

  return inputDisplayValue;
}

interface SearchCategoriesProps extends GridProps {
  size?: "small" | "medium";
  onFocus?: VoidFunction;
}

export function SearchCategories({
  sx,
  size = "medium",
  onFocus,
  ...props
}: Readonly<SearchCategoriesProps>): ReactElement {
  const { searchCategories, toggleSearchCategories } = useSearch();

  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid sx={sx} {...props}>
      <FormControl fullWidth size={size}>
        <InputLabel variant="outlined">{isDownMd ? <TuneIcon /> : "Catégories"}</InputLabel>
        <Select
          multiple
          sx={{ background: "white" }}
          value={searchCategories}
          input={<OutlinedInput label="Catégories" />}
          onFocus={onFocus}
          renderValue={() => getInputDisplayValue(searchCategories)}>
          {availableCategories.map(({ label, categories, Icon }) => (
            <MenuItem
              key={label}
              onClick={() => {
                toggleSearchCategories(categories);
              }}>
              <Checkbox
                checked={searchCategories.includes(
                  Array.isArray(categories) ? categories[0] : categories
                )}
              />
              <Icon sx={{ mr: 1 }} />
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
}
