import { useEffect, useState } from "react";
import type { SyntheticEvent, ReactNode } from "react";
import { Grid, Tab } from "@mui/material";
import InformationBox from "components/InformationBox";
import LoadingScreen from "components/Template/LoadingScreen";
import type Competence from "constants/Competence";
import { PerimetreIcon } from "icons";
import type { Perimetre } from "models";
import PerimetreByCompetenceList from "./PerimetreByCompetenceList";
import { TabPanel } from "@mui/lab";
import CompetenceTabs from "components/CompetenceTabs";
import CompetenceTab from "components/CompetenceTab";
import competenceMap, { competencesAssociationList } from "constants/CompetenceMap";

function getPerimetresByCompetences(
  perimetres: Array<Perimetre>
): Map<Competence, Array<Perimetre> | null> {
  const _perimetresByCompetence = new Map<Competence, Array<Perimetre> | null>();

  competencesAssociationList.forEach(({ competence }) => {
    const compPerimetres = perimetres.filter((perimetre) => perimetre.competence === competence);

    _perimetresByCompetence.set(competence, compPerimetres.length === 0 ? null : compPerimetres);
  });

  return _perimetresByCompetence;
}
interface TerritoirePerimetresSdeaInformationsProps {
  perimetres: Array<Perimetre>;
}

function TerritoirePerimetresSdeaInformations({
  perimetres,
}: Readonly<TerritoirePerimetresSdeaInformationsProps>): ReactNode {
  const [perimetresByCompetence, setPerimetresByCompetence] =
    useState<Map<Competence, Array<Perimetre> | null>>();
  const [panels, setPanels] = useState<Array<ReactNode>>([]);
  const [tabs, setTabs] = useState<Array<ReactNode>>([]);

  const [tabValue, setTabValue] = useState<string>("all");
  function handleTabValueChange(_: SyntheticEvent, newValue: Competence): void {
    setTabValue(newValue);
  }

  useEffect(() => {
    const _perimetresByCompetence = getPerimetresByCompetences(perimetres);
    setPerimetresByCompetence(_perimetresByCompetence);

    const _panels: Array<ReactNode> = [];
    const _tabs: Array<ReactNode> = [];

    _perimetresByCompetence.forEach((perimetres, comp) => {
      if (perimetres != null) {
        _panels.push(
          <TabPanel key={comp} value={comp} sx={{ width: "100%" }}>
            <PerimetreByCompetenceList perimetres={perimetres} />
          </TabPanel>
        );
        const cA = competenceMap.get(comp);
        if (cA != null) {
          _tabs.push(<CompetenceTab competenceAssociation={cA} value={comp} key={comp} />);
        }
      }
    });

    setPanels(_panels);
    setTabs(_tabs);
  }, [perimetres]);

  if (perimetresByCompetence == null) {
    // TODO changer pour un skeleton un jour, pas prioritaire
    return <LoadingScreen />;
  }

  return (
    <InformationBox icon={PerimetreIcon} articleTitle="Périmètres SDEA">
      <Grid container>
        <CompetenceTabs
          onChange={handleTabValueChange}
          value={tabValue}
          panels={[
            <TabPanel key="all" value="all" sx={{ width: "100%" }}>
              <PerimetreByCompetenceList perimetres={perimetres} />
            </TabPanel>,
            ...panels,
          ]}
          tabs={[<Tab iconPosition="start" key="all" label="Tout" value="all" />, ...tabs]}
        />
      </Grid>
    </InformationBox>
  );
}

export default TerritoirePerimetresSdeaInformations;
